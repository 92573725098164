// mixin
@mixin themeColorOpacity($rgbColor, $opacity) {
	background-color: rgba($rgbColor, $opacity);
}
/*********************
BREAKPOINTS
*********************/

@mixin desktop {
	@media screen and (max-width: 1600px) {
		@content;
	}
}

@mixin ipad {
	@media screen and (max-width: 1199px) {
		@content;
	}
}

@mixin mini-tablet {
	@media screen and (max-width: 992px) {
		@content;
	}
}

@mixin mobile {
	@media screen and (max-width: 640px) {
		@content;
	}
}

@mixin mini-desktop-height {
	@media screen and (max-height: 640px) {
		@content;
	}
}
@mixin mini-desktop-height {
	@media screen and (max-height: 767px) {
		@content;
	}
} 
@mixin min-tablet {
	@media screen and (max-width: 768px) {
		@content;
	}
} 
@mixin mini-mobile {
	@media screen and (max-width: 420px) {
		@content;
	}
}
