.reportRow {
	.reportCol {
		.reportWrap {
			box-shadow: 0px 10px 8px -8px rgba(0, 0, 0, 0.2);
			padding: 0;
			border-radius: 8px;
			border-left: 3px solid $primary_color;
		
			.ant-card-head {
				min-height: 1px;
				padding: 0;
				border-bottom: 0;
		
				.ant-card-head-wrapper {
					display: inline-block;
		
					.ant-card-head-title {
						font-size: 16px;
						letter-spacing: 0.02em;
						padding: 6px 20px;
						background-color: $primary_color;
						display: inline-block;
						width: auto;
						min-width: 180px;
						color: $white_color;
						margin: 0px;
						border-radius: 4px 0 50px 0;
					}
				}
			}
		
			.ant-card-body {
				padding: 0px 25px 10px 25px;
				display: flex;
				justify-content: flex-start;
				flex-wrap: wrap;
		
				&:before,
				&:after {
					display: none;
				}
		
				.reportInner {
					border: 1px solid #ddd;
					border-radius: 4px;
					cursor: pointer;
					overflow: hidden;
					width: calc(33.33% - 20px);
					margin: 5px 10px;
					display: flex;
		
					&:hover {
						border: 1px solid $primary_color;
					}
		
					.reportIcon {
						display: flex;
						align-items: center;
						justify-content: center;
						background-color: #f3f3f3;
						font-size: 20px;
						color: $primary_color;
						width: auto;
						padding: 13px;
					}
		
					p {
						margin: 0;
						padding: 10px 10px;
						font-size: 16px;
						display: flex;
						align-items: center;
					}
				}
			}
		}
		&:nth-child(1) {
			.reportWrap {
				border-left: 3px solid #FF8400;

				.ant-card-head {
					.ant-card-head-wrapper {
						.ant-card-head-title {
							background-color: #FF8400;
						}
					}
				}

				.ant-card-body {
					.reportInner {
						&:hover {
							border: 1px solid #FF8400;
						}

						.reportIcon {
							color: #FF8400;
						}
					}
				}
			}
		}
		&:nth-child(2) {
			.reportWrap {
				border-left: 3px solid $purple_500;

				.ant-card-head {
					.ant-card-head-wrapper {
						.ant-card-head-title {
							background-color: $purple_500;
						}
					}
				}

				.ant-card-body {
					.reportInner {
						&:hover {
							border: 1px solid $purple_500;
						}

						.reportIcon {
							color: $purple_500;
						}
					}
				}
			}
		}
		&:nth-child(3) {
			.reportWrap {
				border-left: 3px solid $green_500;

				.ant-card-head {
					.ant-card-head-wrapper {
						.ant-card-head-title {
							background-color: $green_500;
						}
					}
				}

				.ant-card-body {
					.reportInner {
						&:hover {
							border: 1px solid $green_500;
						}

						.reportIcon {
							color: $green_500;
						}
					}
				}
			}
		}
		&:nth-child(4) {
			.reportWrap {
				border-left: 3px solid $yellow_500;

				.ant-card-head {
					.ant-card-head-wrapper {
						.ant-card-head-title {
							background-color: $yellow_500;
						}
					}
				}

				.ant-card-body {
					.reportInner {
						&:hover {
							border: 1px solid $yellow_500;
						}

						.reportIcon {
							color: $yellow_500;
						}
					}
				}
			}
		}
		&:nth-child(5) {
			.reportWrap {
				border-left: 3px solid $blue_500;

				.ant-card-head {
					.ant-card-head-wrapper {
						.ant-card-head-title {
							background-color: $blue_500;
						}
					}
				}

				.ant-card-body {
					.reportInner {
						&:hover {
							border: 1px solid $blue_500;
						}

						.reportIcon {
							color: $blue_500;
						}
					}
				}
			}
		}
		&:nth-child(6) {
			.reportWrap {
				border-left: 3px solid #c0392b;

				.ant-card-head {
					.ant-card-head-wrapper {
						.ant-card-head-title {
							background-color: #c0392b;
						}
					}
				}

				.ant-card-body {
					.reportInner {
						&:hover {
							border: 1px solid #c0392b;
						}

						.reportIcon {
							color: #c0392b;
						}
					}
				}
			}
		}
		&:nth-child(7) {
			.reportWrap {
				border-left: 3px solid #654E92;

				.ant-card-head {
					.ant-card-head-wrapper {
						.ant-card-head-title {
							background-color: #654E92;
						}
					}
				}

				.ant-card-body {
					.reportInner {
						&:hover {
							border: 1px solid #654E92;
						}

						.reportIcon {
							color: #654E92;
						}
					}
				}
			}
		}
		&:nth-child(8) {
			.reportWrap {
				border-left: 3px solid #143F6B;

				.ant-card-head {
					.ant-card-head-wrapper {
						.ant-card-head-title {
							background-color: #143F6B;
						}
					}
				}

				.ant-card-body {
					.reportInner {
						&:hover {
							border: 1px solid #143F6B;
						}

						.reportIcon {
							color: #143F6B;
						}
					}
				}
			}
		}
		&:nth-child(9) {
			.reportWrap {
				border-left: 3px solid #898121;

				.ant-card-head {
					.ant-card-head-wrapper {
						.ant-card-head-title {
							background-color: #898121;
						}
					}
				}

				.ant-card-body {
					.reportInner {
						&:hover {
							border: 1px solid #898121;
						}

						.reportIcon {
							color: #898121;
						}
					}
				}
			}
		}
		&:nth-child(10) {
			.reportWrap {
				border-left: 3px solid #FF5858;

				.ant-card-head {
					.ant-card-head-wrapper {
						.ant-card-head-title {
							background-color: #FF5858;
						}
					}
				}

				.ant-card-body {
					.reportInner {
						&:hover {
							border: 1px solid #FF5858;
						}

						.reportIcon {
							color: #FF5858;
						}
					}
				}
			}
		}
		
	}
}

.ant-page-header-content {
	.ag-theme-alpine {
		.loc_short_name_color_cg {
			color: $primary_color;
			cursor: pointer;
		}
	}
}

.ag-floating-bottom-container {
	.ag-row-pinned {
		.loc_short_name_color_cg[col-id="loc_short_name"] {
			pointer-events: none;
			color: #616161;
		}
	}
}