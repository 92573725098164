.login__page__wrapper {
	height: 100vh;
	overflow: hidden;

	.login__page__title__wrapper {
		position: relative;	
		border-radius: 40px;

		.login__page_img_wrapper {
			width: 100%;
			height: 100%;
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center;
			border-radius: 40px;
		}
	}
	.login__page__form {
		position: absolute;
		top: 0;
		right: 0;
		background-color: #ffffff;
		max-width: 540px;
		width: 100%;
		padding: 38px 48px 50px;
		border-bottom-right-radius: 40px;
		border-top-right-radius: 40px;
		box-shadow: 0px 0px 21px 0px rgba(0, 0, 0, 0.22);
		height: 100%;
		overflow-y: auto;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		.logo_wrapper {
			text-align: center;
			padding: 0 30px;
		}
		.login_title {
			text-transform: uppercase;
			letter-spacing: 2px;
			line-height: normal;
			color: $primary_color;
			position: relative;
			margin: 60px 0;
			text-align: center;
		}
		.ant-form-item {
			margin-bottom: 35px;
		}
		.login_recapcha {
			margin-top: 30px;
		}
		.ant-btn {
			margin-top: 30px;
		}
		.ant-space-item {
			.ant-form-item {
				margin-bottom: 0;
			}
			.ant-input {
				padding: 0 6px;
				text-align: center;
			}
		}
		.back_reset_link {
			width: 100%;
			justify-content: center;
			margin-top: 30px;

			.ant-divider-vertical {
				border-color: $input_label_color;
				top: -0.26em;
			}
			.resend_otp_btn {
				color: $primary_color;
			}
			a {
				color: $input_label_color;
				font-size: 19px;
			}
			.resend_otp_btn {
				margin: 0;
				padding: 0;
				border: 0;
				&:hover {
					border: 0;
					transform: none;
				}
				&:disabled {
					color: $input_border;
				}
			}
			@include desktop {
				a {
					font-size: 16px;
				}
			}
			@include mini-tablet{
				margin-top: 15px ;
				margin-bottom: 25px;
			}
		}
		.reset_last_sec {
			position: relative;

			.otp_timeout {
				width: 30px;
				height: 30px;
				background-color: $primary_color;
				color: $white_color;
				text-align: center;
				border-radius: 50%;
				line-height: 32px;
				position: absolute;
				top: 16px;
				left: 50%;
				transform: translateX(-50%);
				& + .back_reset_link {
					padding-top: 20px;
				}
			}
		}

		form.ant-form{
			width: 100%;	
		}
	}
	.login__page__form__section {
		// box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.2);
		width: 30%;
		// min-width: 450px;
		max-width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
	}
	@media screen and (max-width: 500px) {
		flex-direction: column;
		.login__page__form__section {
			width: 100%;
			min-width: 100%;
		}
	}
	@include desktop {
		.logo_wrapper {
			img {
				max-width: 190px;
			}
		}
		.login__page__form {
			padding: 25px 30px;
			.login_title {
				margin: 35px 0;
				font-size: 20px;
				&:before {
					left: -30px;
					width: 8px;
					height: 54px;
				}
			}
			.ant-form-item {
				margin-bottom: 25px;
			}
			.ant-btn{margin-top: 10px;}
			@include ipad{
				max-width: 50%;
			}
		}
	}

	@include ipad {
		.login__page__form__section {
			width: 70%;
		}
			
	}

	@include mini-tablet {
		// height: auto;
		flex-direction: column;
		.login__page__title__wrapper {
			background-color: var(--main-color);
			width: 100%;
			.login__page__form {
				position: relative;
				top: auto;
				right: auto;
				transform: none;
				max-width: 90%;
				margin: -120px auto 50px;
				border-radius: 10px;
				height: auto;
				display: block;
			}
			.login__page_img_wrapper {
				height: 42vh;
				border-radius: 0px;
			}
			.otpInput {
				input[type="text"], input[type="tel"], input[type="password"]{
					height: 45px;
				}
			}
		}
	}
}
.remember_forgot_wrap {
	// display: flex;
	justify-content: flex-end;
	flex-wrap: wrap;
	align-items: center;
	.forgot_pass_link {
		float: right;
		font-size: $sixteen;
		color: $primary_color;
		&:hover {
			color: $primary_color_7;
		}
	}
	.ant-checkbox-wrapper {
		display: inline-flex;
		align-items: center;
	}
	@include desktop {
		.forgot_pass_link {
			font-size: 16px;
		}
	}
	@include mobile {
		justify-content: center;
		.ant-checkbox-wrapper {
			margin-bottom: 15px;
		}
	}
}

.otpInput {
	margin: 0 -14px;
	> div {
		width: 16.66%;
		padding: 0 14px;
	}
	input[type="text"], input[type="tel"], input[type="password"]{
		width: 100% !important;
		box-shadow: none;
		//height: 65px;
		border-radius: 8px;
		border: 1px solid $input_border;
		font-size: 19px;
		color: $black_color;
		outline: 0;
		transition: all 0.5s ease;
		height: 50px;
		&:hover,
		&:focus {
			border-color: $primary_color;
		}
	}
	@include desktop {
		margin: 0 -10px;
		> div {
			padding: 0 10px;
		}
		input[type="text"] {
			height: 42px;
		}
	}
}
.login__page__wrapper .login__page__form .ant-form-item + .ant-btn {
	margin-top: 20px;
}


.login__page__wrapper {
	.login__page__form{
		.back_resendotp_link{
			margin-top: 0;
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 0;
			button{
				margin-top: 0px;
				padding: 0;
			}
		}
	}
	.verifayOtp__main {
		.ant-form-item{
			margin-bottom: 15px;
		}
	}	
}

.main_corporate{
	align-items: center;
	background-image: url("../../images/corporate_bg_img.jpg");
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	@include mini-tablet {
		flex-direction: column;
		background-image:none;
		background-color: $primary_color;
		min-height: 100vh;
		align-items: center;
		justify-content: center;
		.ant-col-12{
			display: block;
    		flex: 0 0 100%;
    		max-width: 100%;
		}
	}
	.svg_img{
		height: 100vh;
		position: relative;
		svg{
			position: absolute;
			min-height: 100vh;
			fill: $primary_color_8;
			object-fit: cover;
			object-position: bottom;
			max-height: 100%;
			text-align: left;
			width: 100%;
			right: 0;
			@include mini-tablet{
				display: none;
			}
			@include mini-desktop-height{
				right: inherit;
				left: 0;
			}
		}
		.courier_boy{
			position: absolute;
			left: -51px;
			height: auto;
			width: calc(100% - 10vw);
			top: 50%;
			border: 12px solid rgba(var(--main-color), 1);
			border-radius: 50%;
			padding: 28px;
			background-color: #ececee;
			transform: translate(0, -50%);
			// max-height: 57vh;
			@include mini-tablet{
				position: fixed;
				width: 100%;
				left: 0;
				transform: translate(0, 0%);
				border: 0;
				padding: 0;
				border-radius: 0;
				top: 0;
				height: 530px;
				object-fit: cover;
			}
		}
		@include mini-tablet{
			height: auto;
		}
	}
	.form_corporate{
		@include mini-tablet{
			padding:0px 48px 20px;
			margin-top: 0px;
			width: 100%;
			z-index: 999;
		}
		.login__page__wrapper {
			height: 100vh;
			display: flex;
			overflow: hidden;
			background-color: transparent;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			.login__page__title__wrapper{
				margin: 30px 0;
				width: 60%;
				@include mini-tablet{
					width: 60%;
				}
				@include ipad{
					width: 90%;
				}
			}
			@include mini-tablet{
				height: auto;
				border-radius: 10px;
				background-color: #ffffff;
				box-shadow: 0px 0px 21px 0px rgba(0, 0, 0, 0.22);
			}
		}
		.logo_corporater{
			text-align: center;
			margin-bottom: 30px;
			
		}
		.corporate_login_form{
			margin: 0 auto;
			@include mini-tablet{
				width: 100%;
			}
			.captcha{
				display: flex;
				align-items: center;
				margin-bottom: 30px;
				.ant-col{
					padding-left: 0 !important;
					&:nth-child(2){
						.ant-form-item{
							margin-bottom: 0;
						}
					}
				}
				.ant-input{
					height: 40px;
					font-size: 14px;
					text-align: center;
					letter-spacing: 2px;
					@include ipad{
						height: 40px;
						font-size: 12px;
					}
				}
				.ant-input-disabled{
					background-color: $primary_color_6;
					color: $white_color;
					font-style: italic;
				}
				.ant-form-item-with-help {
					.ant-form-item-explain{
						min-height: 0;
						position: absolute;
						top: 100%;
						.ant-form-item-explain-error{
							font-size: 12px;
							@include ipad{
								font-size: 10px;
							}
						}
					}
				}
			}
		}
		.ver_mac_info{
			display: flex;
			justify-content: center;
			align-items: center;
			position: fixed;
			bottom: 0;
			background-color: $primary_color_8;
			min-width: 8%;
			right: 0;
			border-top-left-radius: 20px;
			padding: 3px 10px;
			svg{
				height: 13px;
				margin-left: 10px;
   				display: inline-block;
				fill: $white_color;
    			width: auto;
			}
			h3{
				font-size: $fifteen;
				margin-bottom: 0;
				display: inline-block;
				color: $white_color;
			}
			@include mini-tablet{
				width: 100%;
				border-top-left-radius: 0px;
				position: relative;
			}
		}
	}	
}
.macInfo{
.ant-tooltip-arrow{
		.ant-tooltip-arrow-content{
				--antd-arrow-background-color:$primary_color_8;
				background-color: $primary_color_8;
			}
		}
		.ant-tooltip-inner{
			color: $white_color;
			background-color: $primary_color_8;
			padding: 6px 5px;
			h3{
				font-size: 14px;
				color: $white_color;
				margin-bottom: 0;
			}
		}
	} 
.before__login__page__wrapper{
	display: flex;
	padding: 5%;
	background-color: $primary_color;
	.login__page__title__wrapper{
		width: 100%;
	}
	@include mini-tablet{
		padding: 0;
	}
}
.forgot_otp_ver_mac_info{
	display: flex;
	justify-content: flex-end;
	position: absolute;
	bottom: 0%;
	background-color: $primary_color_8;
	min-width: 80%;
	right: 0;
	border-top-left-radius: 20px;
	padding: 3px 10px;
	h3{
		font-size: $thirteen;
		margin-bottom: 0;
		display: inline-block;
		color: $white_color;
	}
	@include mini-tablet{
		width: 100%;
		border-top-left-radius: 0px;
	}
}	
.change_profile{
	.otpInput{
		margin: 0 17px;
		>div{
			padding: 0 3px;
		}
	}
	.change_profile_otp{
		label{
			margin-left: 17px;
		}
	}
	.change_profile_otp_button{
		display: flex;
		align-items: flex-start;
		flex-direction: column;
		.ant-btn,.resend_otp_btn{
			padding: 4px 0;
		}
	}
}
.success_link_show {
	.ant-space-item{
		font-size: 14px;
		letter-spacing: 0;
		text-transform: capitalize;
		font-weight: 300;
		color: $success_color;
	}
}