.dashboard {
  min-height: calc(100% - 34px);
  position: relative;
}
.dashboard-control-wrapper {
  padding-left: 290px;
}

.dashboard-sidebar {
  &.app-sidebar-primary {
    width: auto;
    max-width: 170px;
    background: #fff;
    box-shadow: 0px 0px 20px 0px rgba(76, 87, 125, 0.02);
    border-radius: 8px;
    position: fixed;
    z-index: 105;
    left: 30px;

    .app-sidebar-nav {
      padding: 1.25rem 0.85rem;

      .nav {
        padding: 0;

        .nav-item {
          display: flex;
          flex-direction: column;
          align-items: stretch;
          justify-content: center;
          padding-top: 1rem !important;
          padding-bottom: 1rem !important;
          padding-right: 0.25rem !important;
          padding-left: 0.25rem !important;
          border-bottom: 1px solid #f1f1f1;
          cursor: pointer;

          &:hover {
            svg {
              transform: scale(1.2);
              transition: 0.2s;
            }            
          }

          svg {
            fill: #B5B5C3;
            width: 30px;
            transition: 0.2s;
            .cls-1,
            path {
              fill: #B5B5C3;
            }
          }

          .head_right_icon {
            text-align: center;

            h4 {
              font-size: 0.95rem;
              font-weight: 500;
              color: #A1A5B7;
              margin: 0;
              line-height: 0.8715;
            }

            em {
              font-size: 0.80rem;
              font-weight: 500;
              color: #A1A5B7;
            }
          }

          &.active {
            svg {
              fill: $primary_color;

              .cls-1,
              path {
                fill: $primary_color;
              }
            }

            h4,
            em {
              color: $primary_color;
            }
          }

          &:last-child {
            border-bottom: 0;
          }
        }
      }
    }
  }
}

.dashboard-main-con {
  margin-top: 20px;

  .app-content {
    .ant-col-5 {
      flex: 20%;
      max-width: 20%;

      &:nth-child(1) {
        .card {
          svg {
            fill: #ff8282;
            width: 50px;

            .cls-1,
            path {
              fill: #ff8282;
            }
          }
        }
      }

      &:nth-child(2) {
        .card {
          svg {
            fill: #5cbb5c;
            width: 50px;

            .cls-1,
            path {
              fill: #5cbb5c;
            }
          }
        }
      }

      &:nth-child(3) {
        .card {
          svg {
            fill: #ac8fdb;
            width: 50px;

            .cls-1,
            path {
              fill: #ac8fdb;
            }
          }
        }
      }

      &:nth-child(4) {
        .card {
          svg {
            fill: #ebad4b;
            width: 50px;

            .cls-1,
            path {
              fill: #ebad4b;
            }
          }
        }
      }

      &:nth-child(5) {
        .card {
          svg {
            fill: #4dc9ff;
            width: 50px;

            .cls-1,
            path {
              fill: #4dc9ff;
            }
          }
        }
      }
    }

    .card {
      box-shadow: 0px 0px 20px 0px rgba(76, 87, 125, 0.02);
      background-color: #ffffff;
      border-radius: 1rem;

      .card-body {
        padding: 2rem 2.25rem;
        align-items: center;
        justify-content: flex-start;

        svg {
          fill: #b89a73;
          width: 50px;

          .cls-1,
          path {
            fill: #b89a73;
          }
        }

        .head_text_bottom {
          padding-left: 15px;

          h4 {
            font-size: 1.1rem;
            font-weight: 600;
            color: #3F4254;
            margin: 0;
            line-height: 0.8715;
          }

          em {
            font-size: 1.075rem;
            font-weight: 500;
            color: #B5B5C3;
          }
        }
      }
    }
  }
}

.dashboard-control-wrapper-deg-2 {
  .dashboard-sidebar.app-sidebar-primary {
    width: 280px;
    max-width: 280px;

    .app-sidebar-nav {

      .nav {
        .nav-item {
          flex-direction: row;
          justify-content: flex-start;

          .head_right_icon {
            padding-left: 15px;
            text-align: left;

            h4 {
              color: #3F4254;
            }
          }

          svg {
            width: 40px;
          }

          &:nth-child(1) {
            svg {
              fill: #ff8282;

              .cls-1,
              path {
                fill: #ff8282;
              }
            }
          }

          &:nth-child(2) {
            svg {
              fill: #5cbb5c;

              .cls-1,
              path {
                fill: #5cbb5c;
              }
            }
          }

          &:nth-child(3) {
            svg {
              fill: #ac8fdb;

              .cls-1,
              path {
                fill: #ac8fdb;
              }
            }
          }

          &:nth-child(4) {
            svg {
              fill: #ebad4b;

              .cls-1,
              path {
                fill: #ebad4b;
              }
            }
          }

          &:nth-child(5) {
            padding-bottom: 0 !important;

            svg {
              fill: #4dc9ff;

              .cls-1,
              path {
                fill: #4dc9ff;
              }
            }
          }
        }
      }
    }
  }
}