.billingSearch {
  .customgriddesignrow {
    .ant-col {
      &:nth-child(1) {
        .searchCardBox {
          border: 1px solid #68a1d6;

          .ant-card-head {
            border-bottom: 1px solid #68a1d6;
          }

          .ant-card-actions {
            border-top: 1px solid #68a1d6;

            .ant-btn-primary {
              background-color: #68a1d6;
              border-color: #68a1d6;
            }
          }
        }
      }
    }

    .ant-col {
      &:nth-child(2) {
        .searchCardBox {
          border: 1px solid #c48ac6;

          .ant-card-head {
            border-bottom: 1px solid #c48ac6;
          }

          .ant-card-actions {
            border-top: 1px solid #c48ac6;

            .ant-btn-primary {
              background-color: #c48ac6;
              border-color: #c48ac6;
            }
          }
        }
      }
    }

    .ant-col {
      &:nth-child(3) {
        .searchCardBox {
          border: 1px solid #9E6F21;

          .ant-card-head {
            border-bottom: 1px solid #9E6F21;
          }

          .ant-card-actions {
            border-top: 1px solid #9E6F21;

            .ant-btn-primary {
              background-color: #9E6F21;
              border-color: #9E6F21;
            }
          }
        }
      }
    }

    .ant-col {
      &:nth-child(4) {
        .searchCardBox {
          border: 1px solid $red_400;

          .ant-card-head {
            border-bottom: 1px solid $red_400;
          }

          .ant-card-actions {
            border-top: 1px solid $red_400;

            .ant-btn-primary {
              background-color: $red_400;
              border-color: $red_400;
            }
          }
        }
      }
    }
  }

  .searchCardBox {
    border-radius: 5px;
    border: 1px solid $black_300;
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 0;
    overflow: hidden;

    .ant-card-cover {
      width: 100%;
      margin: 0;

      svg {
        height: 100%;
        max-height: 250px;
        object-fit: contain;
        width: 100%;
      }
    }

    .ant-card-head {
      width: 100%;
      margin: 0;

      .ant-card-head-title {
        text-align: center;
        font-weight: 600;
        padding: 14px 0 0;
        letter-spacing: 1px;
      }
    }

    .ant-card-body {
      width: 100%;
      height: 100%;
      padding: 10px 20px;

      .ant-form-item {
        margin-bottom: 15px;

        .ant-form-item-label {
          padding: 0 0 2px
        }
      }
    }

    .ant-card-actions {
      width: 100%;
      position: sticky;
      bottom: 0;

      li {
        padding: 0;
        margin: 0 0 10px;
      }
    }
  }
}

.monthyBookingDesign {
  .ant-page-header-content {
    height: auto;
  }
}

.grid_wrapper_custom_height {
  height: calc(100% - 200px);
}

.help_text_Billing_list {
  li {
    line-height: 1.1;

    &::before {
      top: 3px;
    }
  }
}

.monthyBookingDesignoverView {
  .ant-page-header-content {
    height: auto;

    .grid_wrapper_custom_height {
      >div {
        min-height: 450px;

        .ag-root-wrapper.ag-layout-normal {
          min-height: 450px;
        }
      }

      .ant-row {
        min-height: auto;
      }
    }
  }
}

.listing_box_upper {
  border-bottom: 1px solid $black_400;
  margin-bottom: 13px;
  margin-top: 10px;
}

.Export_drpdwn_menu {
  padding-top: 5px;

  .ant-dropdown-menu-item {
    text-align: center;
  }
}

.HObillingSearch {
  padding: 0 0px;

  .customgriddesignrow {
    .ant-col {
      &:nth-child(1) {
        .searchCardBox {
          border: 1px solid #68a1d6;

          .ant-card-head {
            border-bottom: 1px solid #68a1d6;
          }

          .ant-card-actions {
            border-top: 1px solid #68a1d6;

            .ant-btn-primary {
              background-color: #68a1d6;
              border-color: #68a1d6;
            }
          }
        }
      }
    }

    .ant-col {
      &:nth-child(2) {
        .searchCardBox {
          border: 1px solid #c48ac6;

          .ant-card-head {
            border-bottom: 1px solid #c48ac6;
          }

          .ant-card-actions {
            border-top: 1px solid #c48ac6;

            .ant-btn-primary {
              background-color: #c48ac6;
              border-color: #c48ac6;
            }
          }
        }
      }
    }

    .ant-col {
      &:nth-child(3) {
        .searchCardBox {
          border: 1px solid #9E6F21;

          .ant-card-head {
            border-bottom: 1px solid #9E6F21;
          }

          .ant-card-actions {
            border-top: 1px solid #9E6F21;

            .ant-btn-primary {
              background-color: #9E6F21;
              border-color: #9E6F21;
            }
          }
        }
      }
    }

    .ant-col {
      &:nth-child(4) {
        .searchCardBox {
          border: 1px solid #ff8650;

          .ant-card-head {
            border-bottom: 1px solid #ff8650;
          }

          .ant-card-actions {
            border-top: 1px solid #ff8650;

            .ant-btn-primary {
              background-color: #ff8650;
              border-color: #ff8650;
            }
          }
        }
      }
    }

    .ant-col {
      &:nth-child(5) {
        .searchCardBox {
          border: 1px solid #0e4d1a;

          .ant-card-head {
            border-bottom: 1px solid #0e4d1a;
          }

          .ant-card-actions {
            border-top: 1px solid #0e4d1a;

            .ant-btn-primary {
              background-color: #0e4d1a;
              border-color: #0e4d1a;
            }
          }
        }
      }
    }

    .ant-col {
      &:nth-child(6) {
        .searchCardBox {
          border: 1px solid #9b6ef3;

          .ant-card-head {
            border-bottom: 1px solid #9b6ef3;
          }

          .ant-card-actions {
            border-top: 1px solid #9b6ef3;

            .ant-btn-primary {
              background-color: #9b6ef3;
              border-color: #9b6ef3;
            }
          }
        }
      }
    }
  }

  .searchCardBox {
    border-radius: 5px;
    border: 1px solid $black_300;
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 0;
    overflow: hidden;

    .ant-card-cover {
      width: 100%;
      margin: 0;

      svg {
        height: 100%;
        max-height: 250px;
        object-fit: contain;
        width: 100%;
      }
    }

    .ant-card-head {
      width: 100%;
      margin: 0;

      .ant-card-head-title {
        text-align: center;
        font-weight: 600;
        padding: 14px 0 0;
        letter-spacing: 1px;
      }
    }

    .ant-card-body {
      width: 100%;
      height: 100%;
      padding: 10px 20px;

      .boxText {
        text-align: center;
        font-size: 1rem;
      }
    }

    .ant-card-actions {
      width: 100%;
      position: sticky;
      bottom: 0;

      li {
        padding: 0;
        margin: 0 0 10px;
      }
    }
  }
}

.listCountNewItemCenter {
  .ant-form-item-row {
    align-items: center;
  }
}

.listCountNew.d-flex.justify-content-end.align-items-center.listCountNewItemCenter.monthyBookingDesignoverView {
  .ant-form-item {
    margin-bottom: 0
  }
}

.BusinessSummaryTable {
  tbody {
    tr.primaryColor2 {
      background-color: $primary_color_2 !important;
    }

    tr.primaryColor4 {
      background-color: $primary_color_4 !important;
    }

    tr:nth-child(even) {
      background-color: $black_200;
    }

  }
}
.barter_nots{
  padding: 0 0 0 10px;
  li {
    list-style-position: inside;
  }
}

.paymentMainDesign {
  .ant-page-header-content {
    padding: 30px;

    .searchCardBox {
      .ant-card-body {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex-direction: inherit;

        .boxText {
          text-align: center;
          font-size: 1.2rem;
          font-weight: 800;
        }
      }

      .ant-card-cover {
        svg {
          max-height: 290px;
        }
      }
    }

    .EstimationbillingSearch {
      .searchCardBox{
        .ant-card-cover {
          svg{
            max-height: 275px !important;
          }
        }
      }
    }

    .HObillingSearch {
      padding: 0 0px;

      .customgriddesignrow {
        .ant-col {
          &:nth-child(1) {
            .searchCardBox {
              border: 1px solid #68a1d6;

              .ant-card-head {
                border-bottom: 1px solid #68a1d6;
              }

              .ant-card-actions {
                border-top: 1px solid #68a1d6;

                .ant-btn-primary {
                  background-color: #68a1d6;
                  border-color: #68a1d6;
                }
              }
            }
          }
        }

        .ant-col {
          &:nth-child(2) {
            .searchCardBox {
              border: 1px solid #e80597;

              .ant-card-head {
                border-bottom: 1px solid #e80597;
              }

              .ant-card-actions {
                border-top: 1px solid #e80597;

                .ant-btn-primary {
                  background-color: #e80597;
                  border-color: #e80597;

                  &:disabled {
                    background-color: #dfdfdf;
                    border-color: #dfdfdf;
                  }
                }
              }
            }
          }
        }

        .ant-col {
          &:nth-child(3) {
            .searchCardBox {
              border: 1px solid #ff725e;

              .ant-card-head {
                border-bottom: 1px solid #ff725e;
              }

              .ant-card-actions {
                border-top: 1px solid #ff725e;

                .ant-btn-primary {
                  background-color: #ff725e;
                  border-color: #ff725e;
                }
              }
            }
          }
        }

        .ant-col {
          &:nth-child(4) {
            .searchCardBox {
              border: 1px solid #9b6ef3;

              .ant-card-head {
                border-bottom: 1px solid #9b6ef3;
              }

              .ant-card-actions {
                border-top: 1px solid #9b6ef3;

                .ant-btn-primary {
                  background-color: #9b6ef3;
                  border-color: #9b6ef3;
                }
              }
            }
          }
        }

        .ant-col {
          &:nth-child(5) {
            .searchCardBox {
              border: 1px solid #0e4d1a;

              .ant-card-head {
                border-bottom: 1px solid #0e4d1a;
              }

              .ant-card-actions {
                border-top: 1px solid #0e4d1a;

                .ant-btn-primary {
                  background-color: #0e4d1a;
                  border-color: #0e4d1a;
                }
              }
            }
          }
        }

        .ant-col {
          &:nth-child(6) {
            .searchCardBox {
              border: 1px solid #ff8650;

              .ant-card-head {
                border-bottom: 1px solid #ff8650;
              }

              .ant-card-actions {
                border-top: 1px solid #ff8650;

                .ant-btn-primary {
                  background-color: #ff8650;
                  border-color: #ff8650;
                }
              }
            }
          }
        }
      }

      .searchCardBox {
        border-radius: 5px;
        border: 1px solid $black_300;
        position: relative;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 0;
        overflow: hidden;

        .ant-card-cover {
          width: 100%;
          margin: 0;

          svg {
            height: 100%;
            max-height: 390px;
            object-fit: contain;
            width: 100%;
            padding: 10px;
          }
        }

        .ant-card-head {
          width: 100%;
          margin: 0;

          .ant-card-head-title {
            text-align: center;
            font-weight: 600;
            padding: 14px 0 0;
            letter-spacing: 1px;
          }
        }

        .ant-card-body {
          width: 100%;
          height: 100%;
          padding: 10px 20px;

          .boxText {
            text-align: center;
            font-size: 1rem;
          }
        }

        .ant-card-actions {
          width: 100%;
          position: sticky;
          bottom: 0;

          li {
            padding: 0;
            margin: 0 0 10px;
          }
        }
      }
    }
  }
}

.corporateMainDesign {
  .ant-page-header-content {
    .HObillingSearch {

      .customgriddesignrow {
        .ant-col {
          &:nth-child(1) {
            .searchCardBox {
              border: 1px solid #68a1d6;

              .ant-card-head {
                border-bottom: 1px solid #68a1d6;
              }

              .ant-card-actions {
                border-top: 1px solid #68a1d6;

                .ant-btn-primary {
                  background-color: #68a1d6;
                  border-color: #68a1d6;
                }
              }
            }
          }
        }

        .ant-col {
          &:nth-child(2) {
            .searchCardBox {
              border: 1px solid #ff725e;

              .ant-card-head {
                border-bottom: 1px solid #ff725e;
              }

              .ant-card-actions {
                border-top: 1px solid #ff725e;

                .ant-btn-primary {
                  background-color: #ff725e;
                  border-color: #ff725e;
                }
              }
            }
          }
        }

        .ant-col {
          &:nth-child(3) {
            .searchCardBox {
              border: 1px solid #9b6ef3;

              .ant-card-head {
                border-bottom: 1px solid #9b6ef3;
              }

              .ant-card-actions {
                border-top: 1px solid #9b6ef3;

                .ant-btn-primary {
                  background-color: #9b6ef3;
                  border-color: #9b6ef3;
                }
              }
            }
          }
        }

        .ant-col {
          &:nth-child(4) {
            .searchCardBox {
              border: 1px solid #0e4d1a;

              .ant-card-head {
                border-bottom: 1px solid #0e4d1a;
              }

              .ant-card-actions {
                border-top: 1px solid #0e4d1a;

                .ant-btn-primary {
                  background-color: #0e4d1a;
                  border-color: #0e4d1a;
                }
              }
            }
          }
        }

        .ant-col {
          &:nth-child(5) {
            .searchCardBox {
              border: 1px solid #4c875d;

              .ant-card-head {
                border-bottom: 1px solid #4c875d;
              }

              .ant-card-actions {
                border-top: 1px solid #4c875d;

                .ant-btn-primary {
                  background-color: #4c875d;
                  border-color: #4c875d;
                }
              }
            }
          }
        }

        .ant-col {
          &:nth-child(6) {
            .searchCardBox {
              border: 1px solid #e80597;

              .ant-card-head {
                border-bottom: 1px solid #e80597;
              }

              .ant-card-actions {
                border-top: 1px solid #e80597;

                .ant-btn-primary {
                  background-color: #e80597;
                  border-color: #e80597;

                  &:disabled {
                    background-color: #dfdfdf;
                    border-color: #dfdfdf;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
.contractsMainDesign {
  .ant-page-header-content {
    .HObillingSearch {

      .customgriddesignrow {
        .ant-col {
          &:nth-child(1) {
            .searchCardBox {
              border: 1px solid #68a1d6;

              .ant-card-head {
                border-bottom: 1px solid #68a1d6;
              }

              .ant-card-actions {
                border-top: 1px solid #68a1d6;

                .ant-btn-primary {
                  background-color: #68a1d6;
                  border-color: #68a1d6;
                }
              }
            }
          }
        }
        .ant-col {
          &:nth-child(2) {
            .searchCardBox {
              border: 1px solid #9277ed;

              .ant-card-head {
                border-bottom: 1px solid #9277ed;
              }

              .ant-card-actions {
                border-top: 1px solid #9277ed;

                .ant-btn-primary {
                  background-color: #9277ed;
                  border-color: #9277ed;
                }
              }
            }
          }
        }
        .ant-col {
          &:nth-child(3) {
            .searchCardBox {
              border: 1px solid #fc79aa;

              .ant-card-head {
                border-bottom: 1px solid #fc79aa;
              }

              .ant-card-actions {
                border-top: 1px solid #fc79aa;

                .ant-btn-primary {
                  background-color: #fc79aa;
                  border-color: #fc79aa;
                }
              }
            }
          }
        }      
        .ant-col {
          &:nth-child(4) {
            .searchCardBox {
              border: 1px solid #ff725e;

              .ant-card-head {
                border-bottom: 1px solid #ff725e;
              }

              .ant-card-actions {
                border-top: 1px solid #ff725e;

                .ant-btn-primary {
                  background-color: #ff725e;
                  border-color: #ff725e;
                }
              }
            }
          }
        }
        .ant-col {
          &:nth-child(5) {
            .searchCardBox {
              border: 1px solid #ff8650;

              .ant-card-head {
                border-bottom: 1px solid #ff8650;
              }

              .ant-card-actions {
                border-top: 1px solid #ff8650;

                .ant-btn-primary {
                  background-color: #ff8650;
                  border-color: #ff8650;
                }
              }
            }
          }
        }

        .ant-col {
          &:nth-child(6) {
            .searchCardBox {
              border: 1px solid #e80597;

              .ant-card-head {
                border-bottom: 1px solid #e80597;
              }

              .ant-card-actions {
                border-top: 1px solid #e80597;

                .ant-btn-primary {
                  background-color: #e80597;
                  border-color: #e80597;

                  &:disabled {
                    background-color: #dfdfdf;
                    border-color: #dfdfdf;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.PaymentAddForm {
  .PaymentAddFormSwitch {
    margin: -7px 0 0 0;

    .ant-form-item-label {
      margin-bottom: 0;
      margin-left: 0;
    }
  }

  .insurance_table {
    .insu_table table {
      table-layout: fixed;
    }
  }

  .ant-col.custom_checkbox_design {
    display: flex;
    align-items: center;

    label~span {
      padding-left: 10px;
      font-size: 16px;
    }
  }

  .singlefileupload .upload-list-inline {
    flex-direction: row;
    align-items: center;
    gap: 15px;
  }

  .kycFileUpload .ant-upload-list-picture .ant-upload-list-picture-container {
    width: auto;
    max-width: 250px;
  }
  .TopForm {
    .ant-radio-checked {
      .ant-radio-inner{
        position: relative;
            &::after{
              width: 10px;
              height: 10px;
              margin-top: 0;
              margin-left: 0;
              transform: translate(-50%, -50%) !important;
            }
          }
      }
  }
  .searchBtn {
    margin-top: 7px;
    .ant-btn-primary{
      padding: 6px 20px;
      width: 100%;
      min-width: inherit;
      &[disabled]{
        .anticon {
          color: #a2a2a2;
        }
      }
      .anticon {
        color: $white_color;
      }
    }
  }
}

.DeliveryRun .ant-form-item textarea.ant-input {
  height: 60px;
}

.details_table_payment {
  .ant-row.ant-row-no-wrap {
    .ant-col-5 {
      flex: 0 0 20%;
      max-width: 20%;
    }
  }
}

.PaymentAddForm .insurance_table .insu_table table .ant-input {
  padding: 0 7px;
  text-align: right;
  height: 33px;

  &.text-left {
    text-align: left;
  }
}

.settlement_table {
  .ant-form-item-explain-error {
    text-align: right;
  }

  .insu_table {
    .ant-checkbox-wrapper {
      display: inline-block !important;
      margin: -4px auto 0px;

      .ant-checkbox {
        display: inline-block;
      }
    }
    table {
      tr{
        td{
          text-align: center;
          .ant-switch {
            margin: 0 auto;
          }
        }
      }
    }
  }

  .insu_table table .ant-input {
    padding: 0 7px;
    height: 33px;
  }
}

.tooltip_design_form {
  padding: 15px !important;
  margin: 0;
  h3{
    color: $white_color;
  }
  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-bottom: 4px;

    &:last-child {
      border-top: 1px solid #ffffff;
      margin-top: 10px;
      padding-top: 10px;
    }

    span {
      padding-right: 10px;
    }
  }
}
.Payment_Deduction_Note{
  .ant-popover-inner-content{
    word-break: break-word;
  }
}
.paymenthistoryNewDesign{
  .ant-form{
  width: 100%;
}
  .ant-form-item-label{
    margin-top: 10px;
  }
  .ant-form-item-control{
    text-align: left;
  }
  .custom_page_btn .ant-btn{
    font-size: 15px !important;
    padding: 10px 8px 7px !important;
  }
}
.paymentReceiptDesign{
  .ant-form-item-control-input{
    min-width: 240px;
    max-width: 240px;
  }
}