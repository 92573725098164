.insurance_table {
  margin-top: 15px;
  .insurance_head {
    text-align: right;
    margin-bottom: 20px;
  }
  .insu_table {
    overflow-x: auto;
    table {
      border: 1px solid $input_border;
      width: 100%;
      tbody {
        vertical-align: top;
      }
      thead {
        td {
          background-color: $black_200;
          text-align: center;
        }
      }
      tr {
        td {
          border-bottom: 1px solid $input_border;
          border-left: 1px solid $input_border;
          // text-align: center;
          padding: 10px 10px;
          vertical-align: middle;
          span{
            vertical-align: middle;
            word-break: break-all;
            @include ipad{
              font-size: 12px !important;
            }
          }
          .ant-badge{
            margin-left: 5px;
          }
        }
        // td:first-child {
        // 	width: 15%;
        // }
        // td:nth-child(2) {
        // 	width: 15%;
        // }
        // td:nth-child(3) {
        // 	width: 20%;
        // }
        // td:nth-child(4) {
        // 	width: 20%;
        // }
        // td:nth-child(5) {
        // 	width: 20%;
        // }
        // td:nth-child(6) {
        // 	width: 10%;
        // 	text-align: center;
        // 	vertical-align: middle;
        // }
      }
      .ant-input,
      .ant-select:not(.ant-select-multiple) .ant-select-selector,
      .ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
      .ant-input-number {
        height: 34px;
      }
      .ant-input .ant-select-selection-placeholder,
      .ant-select:not(.ant-select-multiple)
        .ant-select-selector
        .ant-select-selection-placeholder,
      .ant-select-single:not(.ant-select-customize-input)
        .ant-select-selector
        .ant-select-selection-placeholder,
      .ant-input-number .ant-select-selection-placeholder,
      .ant-select-single .ant-select-selector .ant-select-selection-item,
      .ant-select-single
        .ant-select-selector
        .ant-select-selection-placeholder {
        line-height: 34px;
      }
      .ant-btn.del_insu {
        color: $danger_color;
        font-size: 18px;
        border: 0;
        &:hover {
          border: 0;
          transform: scale(1);
          background-color: transparent;
        }
      }
      .ant-btn.save_insu {
        color: $edit_icon_color;
        font-size: 18px;
        border: 0;
        &:hover {
          border: 0;
          transform: scale(1);
          background-color: transparent;
        }
      }
    }
    .ant-form-item .ant-form-item-control .ant-form-item-explain-error {
      font-size: 11px;
    }

    .ant-checkbox-group {
      margin-top: 0;
    }
    .custom_checkbox {
      .ant-row.ant-form-item {
        width: auto;
        display: inline-block;
        margin: 0;
      }
      label.ant-checkbox-wrapper {
        padding: 5px 5px;
        margin: 5px 5px;
        span {
          font-size: 14px;
        }
      }
      @include ipad{
        .ant-checkbox + span {
          padding-right: 4px;
          padding-left: 4px;
        }
      }
      @include mini-tablet{
        .ant-checkbox + span {
          padding-right: 0px;
          padding-left: 0px;
        }
      }
    }
  }
  .table_align_top{
    table {
      tr {
        td {
          vertical-align: top;
        }
      }
    }
  }
  .preferenceRight__Switch {
    min-width: 40px;
    height: 18px;
    margin-top: 0;
    .ant-switch-handle {
      left: calc(100% - 3px - 36px);
      top: 1px;
      width: 16px;
      height: 16px;
    }
  }
  .preferenceRight__Switch.ant-switch-checked {
    .ant-switch-handle {
      left: calc(100% - 3px - 14px);
    }
  }

  .Plus_icon_add {
    background: transparent;
    min-width: auto;
    border-color: $primary_color;
    color: $primary_color;
    border-radius: 50%;
    padding: 0;
    width: 25px;
    height: 25px;
    font-size: 14px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    &:hover {
      transform: scale(0.9);
      border-color: $primary_color;
      color: $primary_color;
    }
  }
}

.info_text_add {
  color: $black_500;
  font-size: 13px;
}
.info_text_add svg.svg-inline--fa.fa-plus.fa-w-14 {
  border: 1px solid  $primary_color;
  color:  $primary_color;
  border-radius: 50%;
  padding: 1px;
  width: 15px;
  height: 15px;
}
.ant-space-item button.ant-btn.ant-btn-primary {
  padding: 8px 5px;
  min-width: auto;
  border-radius: 8px;
  display: flex;
    align-items: center;
    justify-content: center;
  span.anticon.anticon-plus{
    line-height: 0.5;
    svg{
      fill: #fff;
    }
  }
}
.ant-space-item {
  .ant-form-item{
    margin-bottom: 0px;
  }
  button.ant-btn.ant-btn-primary[disabled]{
    span.anticon.anticon-plus{
    svg{
      fill: rgba(0, 0, 0, 0.25);
    }
  }
  }
}
.Info__info_text_add{
  span{
    width: 100%;
    display: inline-block;
    color: $primary_color;
    margin-bottom: 10px;
    button.ant-btn.ant-btn-primary {
      padding: 6px 20px;
      min-width: auto;
      border-radius: 8px;
      background-color: rgba(var(--main-color), 1);
      border-color: rgba(var(--main-color), 1);
      span{
        color: #fff;
        margin: 0;
      }
  }
  }
  
}

.ant-space.tooltipText.btn.btn-primary {
  width: 100%;
.ant-space-item:first-child {
    width: 100%;
  }
}
.service_Area_form{
  .ant-switch {
    min-width: 25px;
    height: 16px;
}
.ant-switch .ant-switch-handle {
  width: 8px;
  height: 8px;
  left: 3px;
  top: 4px;
}
.ant-switch.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 15px - -3px);
}
}
#products_help{
  margin-bottom: 10px;
}

.custom-tabs-service-area{
  .Info__info_text_add {
    margin-top: 19px;
}
  .ant-tabs-tab{
    padding: 10px 18px 7px;
    line-height: 10px;
    margin: 0 5px;
  }
  .product_item_row{
    width: 100%;
    .ant-col{
      margin-right: 15px;
      margin-bottom: 0 !important;
      color: #757575;
    }
    .custom-tabs-service-area-main{
      color: #424242;
    }
  }
}

.text-link-look{color: $primary_color !important; cursor: pointer;}
.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child{left: 0px;}
.custom-tabs-service-area{
  .ant-tabs-content-holder{
    padding: 0;
  }
  .ant-tabs-tabpane{
    padding: 15px;
  }
}
.settlement_table_bottom{
  .insu_table{
    overflow: auto;
    max-height: 550px;
  }
}