.ant-btn-primary {
	background-color: $primary_color;
	border-color: $primary_color;
	transition: all 0.5s ease;
	border-radius: 5px;
    padding: 10px 20px;
    font-size: 16px;
    height: auto;
    line-height: 16px;
    min-width: 100px;

	&:hover,
	&:focus {
		background-color: $primary_color;
		border-color: $primary_color;
		transform: scale(0.94);
		color: $white_color;
	}
	&[disabled] {
		&:hover,
		&:focus {
			transform: scale(1);
		}
	}
	&.borderBtn {
		background-color: transparent;
		color: $primary_color;
	}
}
.ant-btn {
	&.ant-btn-lg {
		font-size: 16px;
		height: auto;
		text-transform: uppercase;
		text-shadow: none;
		padding: 15px 20px;
		line-height: normal;
		border-radius: 5px;
	}
}
.ant-input,
.ant-select:not(.ant-select-multiple) .ant-select-selector,
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
.ant-input-number {
	padding: 0 20px;
	height: 35px;
	border-radius: 8px;
	border: 1px solid $input_border;
	font-size: $thirteen;
	color: $black_color;
	outline: 0;
	background-color: $ag_alter_color;

	&.readOnlyField {
		border-color: $primary_color;
		@include themeColorOpacity($primary, 0.1);
	}

	&:hover {
		border-color: $primary_color;
	}
	&:focus,
	.ant-input-focused {
		border-color: $primary_color;
		box-shadow: none;
	}

	.ant-select-selection-search-input {
		height: 35px;
	}
	.ant-select-selection-placeholder {
		line-height: 35px;
	}
}
.ant-input,
.ant-input[disabled] {
	&.redText {
		color: $danger_color;
	}
	&.blueText {
		color: #3868e3;
	}
	&.greenText {
		color: #52c41a;
	}
	&.orangeText {
		color: #ffc26c;
	}
}

.ant-input-number-input {
	height: 40px;
	padding: 0;
}
.ant-input-number {
	// overflow: hidden;
	width: 100%;
}
.ant-input-number-handler-wrap {
	border-radius: 0 8px 8px 0;
}
.ant-input-number-focused {
	box-shadow: none;
}
.ant-form-item-has-error .ant-input-number,
.ant-form-item-has-error .ant-picker {
	background-color: $ag_alter_color;
}
.ant-input-password .ant-input {
	height: 33px;
	padding: 0 9px !important;
}
.ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
	line-height: 35px;
}
.ant-form-item {
	.ant-form-item-control {
		position: relative;
		.ant-form-item-explain-error {
			// position: absolute;
			// top: 100%;
			font-size: 14px;
			min-height: 1px;
		}
	}
}
.ant-input-affix-wrapper {
	border-radius: 5px;
	padding: 0px 11px;
	height: 35px;
	input{
		height: 32px;
	}
	&:hover,
	&:focus {
		border-color: $primary_color;
		box-shadow: none;
	}
	
}
.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover{
	border-color: $primary_color;
	box-shadow: none;
}
.anticon {
	color: $icon_gray_color;

	&.anticon-eye {
		color: $primary_color;
	}

	&.ant-input-password-icon {
		font-size: 20px;
		&:hover {
			color: $primary_color;
		}
	}
}
.ant-checkbox{
	top: 0;
}
.ant-checkbox-inner {
	width: 31px;
	height: 31px;
	border-radius: 4px;
	border: 2px solid $icon_gray_color;

	&:after {
		width: 7px;
		height: 15px;
		left: 27%;
		top: 48%;

	}
	@include desktop {
		width: 25px;
		height: 25px;
		&:after {
			width: 6px;
			height: 12px;
			left: 27%;
		}
	}
}
.ant-checkbox-checked {
	.ant-checkbox-inner {
		background-color: $primary_color;
		border-color: $primary_color;
	}
	&:after {
		border: 1px solid $primary_color;
	}
}
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
	border-color: $primary_color;
}
.ant-form-item{
	margin-bottom: 20px;
	.ant-form-item-label {
		line-height:1;
		& > label {
			height: auto;
			font-size: $fourteen;
			color: $input_label_color;
		} 
	}
}
.ant-checkbox-wrapper {
	span {
		font-size: 17px;
		color: $black_color;
	}
	@include desktop {
		span {
			font-size: 16px;
		}
	}
}
.ant-breadcrumb .anticon:hover {
	color: $primary_color;
}
textarea.ant-input {
	padding-top: 10px;
	resize: none;
}

@include desktop {
	.ant-select-single .ant-select-selector .ant-select-selection-item,
	.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
		line-height: 35px;
	}
	.ant-input-password .ant-input {
		height: 33px;
	}
}

.widget_card_checkbox {
    padding: 20px;
}
.widget_card_checkbox .widget_card_desc {
    display: flex;
}
.widget_card_checkbox .widget_card_desc__title {
    flex: 0.8;
    flex-direction: column;
}
.widget_card_checkbox .widget_card_desc__tooltip {
    flex: 0.2;
    flex-direction: column;
}
.widget_card_checkbox .ant-card-cover {
    padding: 5px;
    border-radius: 5px;
}
.widget_card_checkbox .ant-card-body {
    padding: 20px 5px;
}
.currencyFormat_box {
	input[type="text"],
	span {
		line-height: 42px;
		padding: 0 20px;
		height: 42px;
		width: 100%;
		border-radius: 8px;
		border: 1px solid $input_border;
		font-size: 16px;
		color: $black_color;
		outline: 0;
		background-color: $ag_alter_color;
		display: inline-block;
	}
	span {
		color: rgba(0, 0, 0, 0.25);
		cursor: not-allowed;
	}
	&.readOnlyField {
		span {
			border-color: $primary_color;
			@include themeColorOpacity($primary, 0.1);
		}
	}

	&:hover {
		input[type="text"] {
			border-color: $primary_color;
		}
	}
	&:focus,
	.ant-input-focused {
		input[type="text"] {
			border-color: $primary_color;
			box-shadow: none;
		}
	}
}
.tooltipText .ant-form-item-control-input .ant-input + span.anticon,
.tooltipText .ant-form-item-control-input .ant-input-number + span.anticon,
.tooltipText .ant-form-item-control-input .ant-picker + span.anticon {
    position: absolute;
    right: 6px;
    top: 50%;
    transform: translateY(-50%);
}
.tooltipText .ant-input,
.tooltipText .ant-input-number,
.tooltipText .ant-picker {
    padding: 0 30px 0 20px;
}
.tooltipText.tooltipRightSpace .ant-form-item-control-input .ant-input-number + span.anticon {
	right: 30px;
}
.tooltipText.tooltipRightSpace .ant-form-item-control-input .ant-picker + span.anticon {
	right: 10px;
}

.ant-checkbox-indeterminate .ant-checkbox-inner::after{
	width: 12px;
    height: 12px;
    background-color:  $primary_color;
}

.ant-form-item-control-input-content{
	.ant-input-affix-wrapper{

		&:hover{
			border-color: $primary_color;
			box-shadow: none;
		}
	}
	.ant-input-affix-wrapper-focused{
		border-color: $primary_color;
		box-shadow: none;
	}
}

.captcha__var_input{
	.ant-form-item-label{width: 100%;}
}
.ant-radio-inner{
    width: 20px;
    height: 20px;
	border-width: 2px;
	border-color: $icon_gray_color;
	&::after{
		background-color: $primary_color;
	}
}
.ant-radio-checked {
	.ant-radio-inner {
    	border-color: $primary_color;		
	}
}
.ant-radio-wrapper:hover .ant-radio, .ant-radio:hover .ant-radio-inner, .ant-radio-input:focus + .ant-radio-inner{
	border-color: $primary_color;		
}