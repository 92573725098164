.ant-input, .ant-select:not(.ant-select-multiple) .ant-select-selector, .ant-select-single:not(.ant-select-customize-input) .ant-select-selector, .ant-input-number, .ant-picker{
	background-color: $white_color;
}

  .upload_verify_sec {
    &.borderUpload {
      padding: 0;
      border: 1px solid #ddd;
      margin: 10px 0 30px;
      // .ant-col:first-child {
      // 	padding-left: 0 !important;
      // }
      .upload_left_sec {
        border-right: 1px solid #ddd;
        background-color: $white_color;
        ul {
          padding: 0;
          margin: 0;
          list-style-type: none;
          li {
            border-bottom: 1px solid #ddd;
            padding: 5px 5px 5px 25px;
            position: relative;
            &:last-child {
              border-bottom: 0;
            }
            &:before {
              content: '';
              width: 6px;
              height: 6px;
              display: inline-block;
              background-color: #7c7c7c;
              position: absolute;
              top: 12px;
              left: 10px;
              border-radius: 50%;
            }
          }
        }
      }
      .upload_right_sec {
        display: flex;
        justify-content: center;
        .ant-upload.ant-upload-select-picture-card {
          background-color: transparent;
            border: 0;
        }
      }
    }
  }

.upload_verify_sec.upload_verify_sec_sm {
  .upload_left_sec ul li{
    padding: 21px 5px 13px 25px;
    &:before {
      top: 28px;
    }
  }
  .ant-upload-list-picture-card-container{
    margin-bottom: 0px;
  }
}
.ant-form{
.custom_lable{
  height: auto;
  line-height: normal;
  font-size: 14px;
  color: #444444;
  &::before {
    display: inline-block;
    margin-right: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*';
}
}
.custom_lable_optional{
  &::before {
    content: none;
  }
  ~.kycFileUpload.singlefileupload{
    margin:5px 0px 30px !important
  }
}
}

.green{
  color: green;
}
.red{
  color: red;
}
.Header_main_icon {
  .nav-item {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    padding-top: 0rem !important;
    padding-bottom: 0rem !important;
    padding-right: 0rem !important;
    padding-left: 0rem !important;
    border-bottom: 1px solid #f1f1f1;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;

    &:hover {
      svg {
        transform: scale(1.2);
        transition: 0.2s;
      }            
    }

    svg {
      fill: #B5B5C3;
      width: 40px;
      transition: 0.2s;
      margin-right: 10px;
      .cls-1,
      path {
        fill: #B5B5C3;
      }
    }

    .head_right_icon {
      text-align: left;

      h4 {
        font-size: 0.99rem;
        font-weight: 500;
        color: #3F4254;
        margin: 0;
        line-height: 0.8715;
      }

      em {
        font-size: 0.80rem;
        font-weight: 500;
        color: #A1A5B7;
      }
    }

    &.active {
      svg {
        fill: $primary_color;

        .cls-1,
        path {
          fill: $primary_color;
        }
      }

      h4,
      em {
        color: $primary_color;
      }
    }

    &:last-child {
      border-bottom: 0;
    }
    &.REPORT{
      svg {
        fill: #26648E;
        width: 35px;
        height: auto;
        .cls-1,
        path {
          fill: #26648E;
        }
      }
    }
    &.DRS{
      svg {
        fill: #ac8fdb;
        width: 30px;
        .cls-1,
        path {
          fill: #ac8fdb;
        }
      }
    }
    &.Outward{
      svg {
        fill: #4dc9ff;

        .cls-1,
        path {
          fill: #4dc9ff;
        }
      }
    }
    &.Bag{
      svg {
        fill: #45C7A7;

        .cls-1,
        path {
          fill: #45C7A7;
        }
      }
    }
    &.Inward{
      svg {
        fill: #ebad4b;

        .cls-1,
        path {
          fill: #ebad4b;
        }
      }
    }
    &.AccountBookingPopup{
      svg {
        fill: #5cbb5c;

        .cls-1,
        path {
          fill: #5cbb5c;
        }
      }
    }
    &.ForwardBookingPopup{
      svg {
        fill: #303df7ad;
        height: 40px;
        
        .cls-1,
        path {
          fill: #303df7ad;
        }
      }
    }
    &.CashBookingPopup{
      svg {
        fill: #ff8282;

        .cls-1,
        path {
          fill: #ff8282;
        }
      }
    }
  }

}
.ant-select-item-option-active:not(.ant-select-item-option-disabled){
  background-color: $primary_color_5;
  color: $white_color;
}
.ant-input[disabled], .ant-btn-primary[disabled], .ant-btn-primary[disabled]:hover, .ant-btn-primary[disabled]:focus, .ant-btn-primary[disabled]:active{
  color: rgba(0, 0, 0, 0.50);
}