$black: #000;
$black_900: #212121;
$black_800: #424242;
$black_700: #616161;
$black_600: #757575;
$black_500: #9e9e9e;
$black_400: #bdbdbd;
$black_300: #e0e0e0;
$black_200: #eeeeee;
$black_100: #f5f5f5;
$black_50: #fafafa;

$blue: #1890ff;
$blue_900: #002766;
$blue_800: #003a8c;
$blue_700: #0050b3;
$blue_600: #096dd9;
$blue_500: #1890ff;
$blue_400: #40a9ff;
$blue_300: #69c0ff;
$blue_200: #91d5ff;
$blue_100: #bae7ff;
$blue_50: #e6f7ff;

$red: #f5222d;
$red_900: #5c0011;
$red_800: #820014;
$red_700: #a8071a;
$red_600: #cf1322;
$red_500: #f5222d;
$red_400: #ff4d4f;
$red_300: #ff7875;
$red_200: #ffa39e;
$red_100: #ffccc7;
$red_50: #fff1f0;

$orange: #fa8c16;
$orange_900: #612500;
$orange_800: #873800;
$orange_700: #ad4e00;
$orange_600: #d46b08;
$orange_500: #fa8c16;
$orange_400: #ffa940;
$orange_300: #ffc069;
$orange_200: #ffd591;
$orange_100: #ffe7ba;
$orange_50: #fff7e6;

$yellow: #faad14;
$yellow_lite: #FFCC00;
$yellow_900: #613400;
$yellow_800: #874d00;
$yellow_700: #ad6800;
$yellow_600: #d48806;
$yellow_500: #faad14;
$yellow_400: #ffc53d;
$yellow_300: #ffd666;
$yellow_200: #ffe58f;
$yellow_100: #fff1b8;
$yellow_50: #fffbe6;

$green: #52c41a;
$green_900: #092b00;
$green_800: #135200;
$green_700: #237804;
$green_600: #389e0d;
$green_500: #52c41a;
$green_400: #73d13d;
$green_300: #95de64;
$green_200: #d9f7be;
$green_100: #d9f7be;
$green_50: #f6ffed;

$purple: #722ed1;
$purple_900: #120338;
$purple_800: #22075e;
$purple_700: #391085;
$purple_600: #531dab;
$purple_500: #722ed1;
$purple_400: #9254de;
$purple_300: #b37feb;
$purple_200: #d3adf7;
$purple_100: #efdbff;
$purple_50: #f9f0ff;

$pink: #eb2f96;
$pink_900: #520339;
$pink_800: #780650;
$pink_700: #9e1068;
$pink_600: #c41d7f;
$pink_500: #eb2f96;
$pink_400: #f759ab;
$pink_300: #ff85c0;
$pink_200: #ffadd2;
$pink_100: #ffd6e7;
$pink_50: #fff0f6;

:root {
	--main-color1: 80, 205, 186;
}
