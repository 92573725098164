.react-tagsinput {
  padding: 0 20px;
  height: 35px;
  border-radius: 8px;
  border: 1px solid #d0d0d0;
  font-size: 13px;
  color: #141414;
  outline: 0;
  background-color: transparent;

  &>span {
    padding: 15px 0;
  }
}

.react-tagsinput--focused {
  border-color: $primary_color;
}

.react-tagsinput-tag {
  background-color: $primary_color;
  border-radius: 2px;
  border: 1px solid $primary_color_4;
  color: $white_color;
  display: inline-block;
  font-family: sans-serif;
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 0px;
  margin-top: 5px;
  margin-right: 5px;
  padding: 2px;
}

.react-tagsinput-remove {
  cursor: pointer;
  font-weight: bold;
}

.react-tagsinput-tag a::before {
  content: " ×";
  color: $white_color;
}

.react-tagsinput-input {
  background: transparent;
  border: 0;
  color: #141414;
  font-size: 13px;
  margin-bottom: 0;
  margin-top: 0;
  outline: none;
  padding: 0;
  width: auto;
  line-height: 35px;
}

.tag_pincode_select {
  .react-tagsinput-input {
    min-width: 300px;
  }
}

.react-tagsinput-input::placeholder {
  opacity: 0.5;
}

.clearAllIcon {
  height: 17px;
  width: 17px;
  font-size: 14px;
  color: $danger_color;
}