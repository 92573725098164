.tableAreaSec {
  .ant-page-header-content {
    background-color: #fff;
    padding: 12px;
    margin-top: 13px;
    border-radius: 10px;
    box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.14);

    .listCountNew {
      text-align: right;
      margin-bottom: 12px;

      .ant-form {
        .ant-form-item {
          margin-bottom: 0 !important;
        }
      }

      .ant-badge-dot {
        width: 10px;
        min-width: 10px;
        height: 10px;
      }

      .ant-btn {
        padding: 9px 8px 6px;
        height: auto;
        line-height: 16px;
        border-radius: 5px;
        font-size: 12px;
        border-color: $input_border;
        margin-left: 15px;

        svg {
          margin-right: 0px;
        }

        &:hover {
          background-color: $primary_color;
          color: $white_color;
        }
      }

      .ant-select {
        margin-left: 15px;
      }

      .ant-select-selector {
        height: auto;
        border-radius: 5px !important;
        text-align: left;
        font-size: 12px;
        background-color: $white_color;

        .ant-select-selection-placeholder {
          line-height: 30px;
        }
      }

      .ant-select-selection-item {
        line-height: 30px;
      }
      &.labelCollistCountNewItemCenter{
        align-items: center;
        .ant-form{
          .ant-select {
            margin-left: 0;
          }
        }
      }
    }
  }
}

.ant-modal {
  padding-bottom: 0;

  .ant-input[disabled] {
    color: rgba(0, 0, 0, 0.25);
    background-color: #f5f5f5;
    border-color: #d9d9d9;
    box-shadow: none;
    cursor: not-allowed;
    opacity: 1;
  }

  .ant-modal-header {
    text-align: center;
    border-radius: 0;
    border-bottom: 2px solid $black_300;
    padding: 10px;

    .ant-modal-title {
      font-size: $sixteen;
      padding-right: 10px;
      color: $black;
    }
  }

  .ant-modal-content {
    border-radius: 20px;
    overflow: hidden;
    max-height: 90vh;

    .ant-modal-body {
      padding: 25px 30px 10px;
      max-height: calc(90vh - 182px);
      overflow-y: auto;

      .ant-form-item {
        margin-bottom: 20px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .ant-modal-close-x {
    svg {
      color: $primary_color;

      &:hover {
        color: $primary_color;
      }
    }
  }

  .ant-modal-footer {
    border-top: 0px solid $black_300;
    padding: 20px;
    text-align: center;
    background-color: $popup_footer_bg;

    button {
      padding: 10px 20px;
      height: auto;
      border-radius: 5px;
      font-size: 16px;
      line-height: normal;
      min-width: 100px;
      margin-left: 0;
    }

    .cancelBtn {
      border-color: $primary_color;
      color: $primary_color;
      @include themeColorOpacity($primary, 0.1);
    }
  }

  .ant-input,
  .ant-select-selector,
  .ant-select:not(.ant-select-customize-input) .ant-select-selector,
  .ant-input-number {
    background-color: $ag_alter_color;
  }

  .ant-modal-close-x {
    height: 66px;
    line-height: 55px;
    font-size: $seventeen;

    svg {
      transition: all 0.3s ease;

      &:hover {
        transform: rotate(90deg);
      }
    }
  }

  .ant-select-selector .ant-select-selection-search {
    left: 20px;
  }

  .uploadFile_sec {
    display: flex;
    align-items: center;
  }

  .uploadFile_sec svg {
    width: 40%;
  }

  .uploadFile_sec p {
    width: 60%;
    padding: 0 8px;
  }

  .ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
    border-color: $primary_color;
  }
}

.addModal,
.editModal {
  .ant-drawer-header-title {
    .ant-drawer-title {
      .ant-tag {
        margin-left: 10px;
        padding: 4px;
      }

      .ant-tag-red {
        svg {
          fill: #cf1322;
        }
      }

      .ant-tag-geekblue {
        svg {
          fill: #1d39c4;
        }
      }

      .ant-tag-success {
        svg {
          fill: #52c41a;
        }
      }

      .ant-tag-orange {
        svg {
          fill: #d46b08;
        }
      }

      .ant-tag-warning {
        svg {
          fill: #faad14;
        }
      }

      .ant-tag-volcano {
        svg {
          fill: #d4380d;
        }
      }

      .ant-tag-cyan {
        svg {
          fill: #08979c;
        }
      }
    }
  }

  .ant-drawer-body {
    .contractViewTemp {
      margin-top: 25px;
      border-bottom: 1px solid $primary_color;
    }

    .details_table {
      .contractRatePolicy {
        color: $primary_color;
        cursor: pointer;
        border-right: 1px solid;
        margin-right: 10px;
        padding-right: 10px;

        // border-bottom: 1px solid $primary_color;
        &:last-child {
          border: 0;
        }
      }
    }
  }

  .packetentry {
    table {

      thead,
      tbody {
        tr {
          th {
            font-size: 12px;
            padding: 10px 5px;
            color: #000;
          }

          td {
            padding: 5px 5px;
            font-size: 11px;
          }
        }
      }
    }
  }

  .ant-modal-header {
    border-top: 4px solid $primary_color;
  }

  .accoutPartyInput {
    border: 1px solid $black_400;
    margin-left: 15px;
    max-width: calc(33.33% - 30px) !important;
    padding: 20px 0px;
    border-radius: 5px;

    .checksAccountParty {
      display: flex;
      justify-content: space-between;
    }
  }

  .ant-form>.ant-row {
    &>.ant-col {
      margin-bottom: 25px;
      // &:last-child {
      // 	margin-bottom: 0;
      // }
    }
  }

  // .ant-select-dropdown-placement-bottomLeft {
  //   top: 40px !important;
  // }

  .ant-transfer {
    .ant-transfer-list {

      .ant-checkbox-inner,
      .ant-checkbox-input {
        width: 20px;
        height: 20px;
      }

      .ant-checkbox-wrapper {
        font-size: 0px;

        // margin-top: 5px;
        .ant-checkbox-checked {
          .ant-checkbox-inner {
            &:after {
              width: 7px;
              height: 12px;
              left: 20%;
              top: 45%;
            }
          }
        }
      }

      .ant-table-thead {
        .ant-checkbox-wrapper {
          margin-top: 5px;
        }
      }

      .ant-pagination {
        .ant-pagination-item-active {
          border-color: $primary_color;

          a {
            color: $primary_color;
          }
        }

        .ant-pagination-item {
          &:hover {
            a {
              color: $primary_color;
            }
          }
        }
      }
    }

    .ant-transfer-operation {
      .ant-btn-primary {
        min-width: 0px;

        svg {
          fill: $white_color;
          font-size: 16px;
        }
      }

      button[disabled] {
        svg {
          fill: $black_700;
          font-size: 16px;
        }
      }
    }
  }

  .moreInfoBtn {
    svg {
      color: $white_color;
    }

    span {
      color: $white_color;
    }

    .ant-btn-primary {
      border-radius: 5px;
      padding: 8px 20px;
      font-size: 15px;
    }
  }

  .searchBtn {
    .ant-btn-primary {
      min-width: 50px;
      font-size: 18px;
      padding: 4px 20px;
    }

    .ant-btn-primary[disabled] {
      svg {
        fill: rgba(0, 0, 0, 0.25);
      }
    }
  }

  .moreInfoContent {
    margin-top: 19px;
  }

  .theme_color {
    color: $edit_icon_color;
  }
}

.withoutFormFields {
  &>.ant-col {
    margin-bottom: 25px;
  }

  .ant-col {
    label {
      padding: 0 0 8px 0;
    }
  }
}

.deleteModal {
  .ant-modal-header {
    border-top: 6px solid $danger_color;
  }

  .ant-form>.ant-row {
    &>.ant-col {
      margin-bottom: 25px;
    }
  }

  .ant-modal-body {
    h3 {
      color: $input_label_color;

      span {
        color: $black_color;

        svg {
          color: $danger_color;
          margin-right: 10px;
          font-size: 22px;
        }
      }
    }
  }

  .ant-modal-footer {
    .cancelBtn {
      border-color: $danger_color;
      color: $danger_color;
      @include themeColorOpacity($danger_color, 0.1);
    }

    .ant-form-item {
      margin-bottom: 0;
    }
  }

  .ant-modal-close-x {
    svg {
      color: $danger_color;

      &:hover {
        color: $danger_color;
      }
    }
  }
}

.tabModal .ant-modal-content .ant-modal-body {
  padding: 0;

  .ant-tabs-content-holder {
    padding: 20px 0;
  }
}

.ant-btn.disabledIcon,
.disabledIcon {
  color: rgba(0, 0, 0, 0.25);
  font-size: 14px;
  padding: 0 !important;
  width: 25px;
  height: 25px;
}

.ant-btn.editIcon,
.editIcon {
  color: $edit_icon_color;
  font-size: 14px;
  padding: 0 !important;
  width: 25px;
  height: 25px;
}

.ant-btn.quotationIcon,
.quotationIcon {
  color: $green_600;
  font-size: 14px;
  padding: 0 !important;
  width: 25px;
  height: 25px;
}

.ant-btn.hoapproveIcon,
.hoapproveIcon {
  color: $blue_700;
  font-size: 14px;
  padding: 0 !important;
  width: 25px;
  height: 25px;
}

.ant-btn.deleteIcon,
.deleteIcon {
  color: $danger_color;
  font-size: 14px;
  padding: 0 !important;
  width: 25px;
  height: 25px;
  // @include themeColorOpacity($danger_color, 0.1);
}

.ant-btn.reSend,
.reSend {
  color: $primary_color;
  font-size: 16px;
  width: auto;
  height: auto;
}

.deleteIcon.ant-tooltip-disabled-compatible-wrapper {
  line-height: 15px;
  padding: 3.5px 5px !important;

  button.ant-btn {
    padding: 0 !important;
    line-height: 15px;
    font-size: inherit;
  }
}

.ant-btn.widgetIcon,
.widgetIcon {
  color: #2d38df;
  font-size: 14px;
  padding: 0 !important;
  width: 25px;
  height: 25px;
}

.ant-btn.scopeIcon,
.scopeIcon {
  color: #52c41a;
  font-size: 14px;
  padding: 0 !important;
  width: 25px;
  height: 25px;
  .cls-1{
    fill: #52c41a;
  }
  &.scopeIconConfig{
    width: 22px;
    height: 22px;
  }
}

.ant-btn .offloadIcon,
.offloadIcon svg {
  fill: $orange_600;
}

.ant-btn.ResetPasswordIcon,
.ResetPasswordIcon {
  color: #ffc26c;
  font-size: 14px;
  padding: 0 !important;
  width: 25px;
  height: 25px;
}

.ant-btn.rollbackIcon,
.rollbackIcon {
  color: #57ea0f;
  font-size: 14px;
  padding: 0 !important;
  width: 25px;
  height: 25px;
}

.ant-btn.viewIcon,
.viewIcon {
  color: #2d38df;
  font-size: 14px;
  padding: 0 !important;
  width: 25px;
  height: 25px;
}

.ant-btn.draftIcon,
.draftIcon {
  color: $purple_400;
  font-size: 14px;
  padding: 0 !important;
  width: 25px;
  height: 25px;
}

.ant-btn.moreIcon,
.moreIcon {
  color: #085e7d;
  font-size: 14px;
  padding: 0 !important;
  width: 25px;
  height: 25px;
}

.ant-btn.copyIcon,
.copyIcon {
  color: #e45826;
  font-size: 14px;
  padding: 0 !important;
  width: 25px;
  height: 25px;
}

.ant-btn.transferIcon,
.transferIcon {
  color: #d72ddf;
  font-size: 14px;
  padding: 0 !important;
  width: 25px;
  height: 25px;
}

.ant-btn.resignIcon,
.resignIcon {
  color: #d72ddf;
  font-size: 14px;
  padding: 0 !important;
  width: 25px;
  height: 25px;
}

.ant-btn.recordIcon,
.recordIcon,
.ant-btn.reopenIcon,
.reopenIcon {
  color: #52c41a;
  font-size: 14px;
  padding: 0 !important;
  width: 25px;
  height: 25px;
}

.ant-btn.skyBlueIcon,
.skyBlueIcon {
  color: #1ac1c4;
  font-size: 14px;
  padding: 0 !important;
  width: 25px;
  height: 25px;
}
.ant-btn.maroonIcon,
.maroonIcon {
  color: maroon;
  font-size: 14px;
  padding: 0 !important;
  width: 25px;
  height: 25px;
}

.statusModal.ant-modal {
  .ant-modal-header {
    text-align: center;
    border-radius: 0;
    border-bottom: 2px solid $black_300;
    padding: 16px;
    border-top: 6px solid $yellow_500;

    .ant-modal-title {
      font-size: 22px;
      padding-right: 30px;
      color: $black;
    }
  }

  .warning_color {
    color: $yellow_500;
  }

  .ant-modal-close-x {
    svg {
      color: $yellow_500;

      &:hover {
        color: $yellow_500;
      }
    }
  }

  .ant-modal-footer {
    border-top: 0px solid $black_300;
    padding: 20px;
    text-align: center;
    background-color: $popup_footer_bg;

    button {
      padding: 10px 20px;
      height: auto;
      border-radius: 5px;
      font-size: 16px;
      line-height: normal;
      min-width: 100px;
      margin-left: 0;
    }

    .cancelBtn {
      border-color: $yellow_500;
      color: $yellow_500;
      @include themeColorOpacity($yellow_500, 0.1);
    }

    .ant-btn-primary {
      padding: 10px 14px;
      height: auto;
      line-height: 16px;
      border-radius: 5px;
      font-size: 14px;
      border-color: $yellow_500;
      margin-left: 15px;
      background-color: $yellow_500;

      &[disabled] {
        color: rgba(0, 0, 0, 0.25);
        border-color: #d9d9d9;
        background: #f5f5f5;

        &:hover {
          color: rgba(0, 0, 0, 0.25);
          border-color: #d9d9d9;
          background: #f5f5f5;
        }
      }

      svg {
        margin-right: 10px;
      }

      &:hover {
        background-color: $yellow_500;
        color: $white_color;
      }
    }

    .statusModal_danger_bg {
      background-color: $danger_color;
      border-color: $danger_color;

      &:hover {
        background-color: $danger_color;
        border-color: $danger_color;
      }
    }
  }

  .ant-modal-body {
    h3 {
      color: $input_label_color;

      span {
        color: $black_color;

        svg {
          color: $yellow_500;
          margin-right: 10px;
          font-size: 22px;
        }
      }
    }
  }
}

.ant-input-group-addon:first-child {
  border-radius: 8px 0 0 8px;
}

.details_table {
  background: transparent;

  .ant-row.ant-row-no-wrap {
    border: 1px solid $black_400;
    border-top: 0;
    padding: 10px 10px;

    &:first-child {
      border-top: 1px solid $black_400;
    }

    label {
      display: block;
      // margin-right: 5px;
      //color: $black;
      color: $black_600;

      span {
        &.danger_color {
          color: $danger_color;
          font-size: $fifteen;
        }
      }
    }

    span {
      color: $black_800;
      word-break: break-word;

      // line-height: initial;
      &.anticon {
        max-width: inherit;
        padding: 0;
      }

      &.ag-paging-description {
        max-width: inherit;
        padding: 0;
      }

      &.ag-paging-page-summary-panel {
        max-width: inherit;
        padding: 0;
      }
    }

    .moreInfoBtn {
      svg {
        color: $white_color;
      }

      span {
        color: $white_color;
      }
    }
  }

  .custom-tabs-zone.custom-tabs-zone-view {
    .rate__types__label {
      .ant-col {
        margin-right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px 0;

        label {
          line-height: 14px;
        }

        span {
          padding: 0 5px;
          max-width: 180px;
        }

        &:last-child {
          min-width: 180px;

          span {
            padding: 0 5px;
            max-width: 180px;
          }
        }
      }
    }

    .rate__types__label_last_nr {
      .ant-col {
        border: 0px;
        width: 100%;
        padding-bottom: 0;
      }

      ~.ant-divider.ant-divider-horizontal {
        display: none;
      }
    }
  }

  .ant-row.ant-row-no-wrap {
    .ant-btn-text.details_table_custom_link {
      span {
        font-size: 11px;
        color: $primary_color;
      }
    }
  }

  .editIcon {
    span {
      color: $edit_icon_color !important;
      font-size: 14px;
      //padding: 0 !important;
      width: 25px;
      height: 25px;
    }
  }
}

.rate_card_view {
  .rateCard {
    border-left: 1px solid $black_400;
    border-right: 1px solid $black_400;
    padding: 15px;
  }

  .rateCardBottom {
    border-bottom: 1px solid $black_400;
  }
}

.tabModal .ant-tabs-nav {
  .ant-tabs-nav-wrap {
    overflow: visible;
  }

  .ant-tabs-tab {
    padding: 15px 30px;

    .ant-tabs-tab-btn {
      color: $input_label_color;
    }

    &.ant-tabs-tab-active {
      @include themeColorOpacity($primary, 0.1);
      position: relative;

      .ant-tabs-tab-btn {
        color: $primary_color;
      }

      &:after {
        content: "";
        display: inline-block;
        width: 0;
        height: 0;
        border-top: 5px solid transparent;
        border-bottom: 5px solid transparent;
        border-left: 5px solid $primary_color;
        position: absolute;
        right: -5px;
      }
    }
  }

  .ant-tabs-ink-bar {
    background: $primary_color;
  }
}

.scope_radio {
  margin-bottom: 20px;
  display: flex;
  border: 1px solid $input_border;
  border-radius: 8px;
  overflow: hidden;

  label {
    padding: 14px 0;
    display: flex;
    align-items: center;
  }

  >label {
    padding-left: 20px;
    min-width: 250px;
    background-color: $black_200;
  }

  .scope_inner_radio {
    padding-left: 20px;

    .ant-checkbox-wrapper {
      display: inline-flex;
      margin: 0;
      margin-right: 10px;

      span {
        color: $input_label_color;
      }
    }
  }

  @include ipad {
    flex-wrap: wrap;

    label {
      padding: 8px 0;
    }

    >label {
      min-width: 100%;
      padding-left: 20px;
    }
  }
}

// .ant-drawer-close {
//   position: absolute;
//   top: 0;
//   right: 0;
//   z-index: 10;
// }

.mainMenuDrawer.ant-drawer .ant-drawer-header {
  border-top: 0;
  border-bottom: 0;
}

.ant-modal .ant-input,
.ant-modal .ant-select-selector,
.ant-modal .ant-select:not(.ant-select-customize-input) .ant-select-selector,
.ant-modal .ant-input-number {
  background-color: transparent;
}

.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background: #f5f5f5;
}

.ant-form-item-control-input-content {
  .ant-input-number-input {
    height: 32px;
  }

  .ant-input-number-disabled {
    background: #f5f5f5;
  }
}

.custom_checkbox {
  label.ant-form-item-required {
    height: auto;
    line-height: normal;
    font-size: 18px;
    color: #444444;
    margin-right: 15px;

    &::before {
      display: inline-block;
      margin-right: 4px;
      color: #ff4d4f;
      font-size: 14px;
      font-family: SimSun, sans-serif;
      line-height: 1;
      content: "*";
    }
  }

  .ant-checkbox-inner {
    background: transparent;
    width: auto;
    height: auto;
    border: 0;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    &::after {
      content: none;
    }
  }

  label.ant-checkbox-wrapper {
    padding: 8px 9px;
    border-radius: 20px;
    color: #7c7c7c;
    border: 1px solid #d0d0d0;
    font-size: 14px;
    width: auto;
    height: auto;
    line-height: normal;
    margin-right: 15px;
  }

  .ant-checkbox-wrapper-checked {
    background-color: $primary_color;
    border-color: $primary_color !important;

    span {
      color: $white_color;
    }
  }
}

.ant-drawer.ant-drawer-right.ant-drawer-open {
  .ant-drawer-content-wrapper {
    .ant-select {
      display: flex;
    }

    .ant-select-multiple {
      display: block;
    }

    @include ipad {
      width: 90% !important;
    }
  }
}

.custom_checkbox {
  .ant-checkbox-group {
    margin-top: 20px;
  }
}

.action-column button {
  margin: 0 5px;
}

.tooltipPassword {
  span.anticon.anticon-info-circle {
    position: absolute;
    left: 100px;
    top: -29px;
  }
}

.ant-tooltip-inner ul {
  padding-left: 16px;
}

.proxyLogin {
  .ant-form>.ant-row {
    &>.ant-col {
      margin-bottom: 25px;
    }
  }

  .ant-modal-body {
    h3 {
      display: flex;
      align-items: center;
      color: $input_label_color;

      .proxy-notes {
        width: 100%;
        font-size: 12px;
        padding-left: 5px;
        text-align: center;
        display: inline-block;
        color: $input_label_color;
      }

      span {
        color: $black_color;

        svg {
          color: $danger_color;
          margin-right: 10px;
          font-size: 22px;
        }
      }
    }
  }
}

.formatAddress {
  white-space: pre-line;
}

.footer p {
  margin-bottom: 0px;
}

.Filter__Dropdown {
  background: $white_color;
  padding: 20px;
  box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.14);
  border-radius: 5px;
  max-width: 450px;
}

.upload_right_sec__in span.ant-upload-list-item-actions a {
  display: none;
}

.form__input__textbox .Pdf__view {
  height: 2em;
  width: 2.75em;
  text-align: center;
  margin: 0 auto;
}

.ant-btn.remarkIcon,
.remarkIcon {
  color: #0050b3;
  font-size: 14px;
  padding: 0 !important;
  width: 25px;
  height: 25px;
}

.string-value {
  word-break: break-all;
}

.kycFileUpload {
  border: solid #e0e0e0;
  border-width: 1px 1px 3px;
  padding: 10px;
  border-bottom: 3px solid $primary_color_4;
  margin: 0 0 10px 0 !important;

  .ant-col {
    margin-bottom: 0 !important;
  }

  .upload_left_sec {
    ul {
      margin-bottom: 0;
    }
  }

  .ant-upload-list-picture {
    .ant-upload-list-picture-container {
      display: inline-block;
      width: 100%;
      margin-right: 10px;

      .ant-upload-list-item-thumbnail {
        width: auto;
        height: auto;
      }

      .ant-upload-list-item {
        img {
          width: auto;
          max-width: 120px;
          height: 55px;
          object-fit: contain;
        }
      }
    }
  }
}

.kycFileUpload {
  padding: 20px;
  object-fit: contain;

  .capture_images {
    height: 80px;
    line-height: 1.2;
    margin-right: 10px;
    min-width: 145px;
    background: $black_50;

    svg {
      width: 50px;
      height: 20px;
    }

    span {
      display: block;
      font-size: 14px;
      margin-top: 4px;
    }
  }

  .upload-list-inline {
    .upload_file {
      height: 80px;
      line-height: 1.2;
      min-width: 145px;
      background: $black_50;

      svg {
        width: 50px;
        height: 20px;
      }

      span {
        display: block;
        font-size: 14px;
        margin-top: 4px;
      }
    }
  }
}

.singlefileupload {
  .upload-list-inline {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-items: flex-end;
  }
}

.kycFileUpload.singlefileupload {
  margin: 20px 0px !important;
  justify-content: space-between;
}

.kycFileUpload.kycFileUpload-half {
  margin: 2px 0 0 0px !important;
  border-radius: 5px;
  padding: 10px;

  .upload_left_sec {
    ul {
      padding-left: 20px;
    }
  }

  .ant-upload-list {
    width: 100%;
  }
}

.textarea_custom_min_height {
  textarea {
    min-height: 104px;
  }
}

.addModal .ant-form>.kycFileUpload.singlefileupload .ant-col {
  margin-bottom: 0;

  .ant-upload-list {
    max-width: 100%;
  }
}

.preferenceRight__Switch {
  min-width: 56px;
  height: 26px;
  margin-top: 15px;

  .ant-switch-handle {
    left: calc(100% - 18px - 36px);
    top: 1px;
    width: 24px;
    height: 23px;
  }
}

.preferenceRight__Switch.ant-switch-checked {
  .ant-switch-handle {
    left: calc(100% - 15px - 10px);
  }
}

.ant-btn.email_var_link,
.email_var_link {
  color: #764af1;
  font-size: 14px;
  padding: 0 !important;
  width: 25px;
  height: 25px;
}

.ant-btn.resend_ver_link,
.resend_ver_link {
  color: #05595b;
  font-size: 14px;
  padding: 0 !important;
  width: 25px;
  height: 25px;
}

.linkIcon.session,
.session {
  color: #92ba92;
  font-size: 16px;
  padding: 0 !important;
  width: 22px;
  height: 22px;
}

.linkIcon_fontAwesome.devices,
.devices {
  color: #36ae7c;
  font-size: 14px;
  padding: 0 !important;
  width: 25px;
}

.ant-popover-placement-bottom,
.ant-popover-placement-bottomRight {
  padding-top: 0;

  .ant-popover-content {
    .filterForm {
      .advance_filter {
        margin-bottom: 10px;
        display: flex;

        .ant-col-3 {
          text-align: center;
        }

        .advance_filter_heading {
          h3 {
            margin: 0;
          }
        }
      }
    }

    .filterPopover {
      min-width: 375px;
      max-width: 400px;
      display: inherit;

      .ant-col {
        white-space: pre-wrap;
        white-space: -moz-pre-wrap;
        white-space: -pre-wrap;
        white-space: -o-pre-wrap;
        word-wrap: break-word;
      }
    }
  }

  .ant-btn-primary {
    background-color: rgba(var(--main-color), 1);
    border-color: rgba(var(--main-color), 1);
    transition: all 0.5s ease;
    border-radius: 5px;
    padding: 0;
    font-size: 12px;
    height: 25px;
    line-height: 0px;
    min-width: 25px;
  }

  .ant-btn-primary[disabled],
  .ant-btn-primary[disabled]:hover,
  .ant-btn-primary[disabled]:focus,
  .ant-btn-primary[disabled]:active {
    color: rgba(0, 0, 0, 0.25);
    border-color: #d9d9d9;
    background: #f5f5f5;
    text-shadow: none;
    box-shadow: none;
  }
}

// .listing_popupover {
//   .ant-popover-arrow {
//     top: -23.686292px;
//     right: 3px;
//     height: 30px;
//   }
// }

.danger span {
  color: red;
}

.gray span {
  color: gray;
}

div#map {
  height: 300px;
}

div#pac-card {
  margin-bottom: 25px;
}

.ant-popover,
.ant-popover-placement-topLeft {
  max-width: 50%;

  .ant-row {
    width: 100%;
    word-break: break-all;
  }
}

.header-dropdown {
  .ant-form-item {
    margin-bottom: 0;

    .ant-select-clear {
      background-color: transparent;

      svg {
        color: $white_color;
      }
    }

    .ant-select-arrow {
      span[aria-label="down"] {
        svg {
          color: $white_color;
        }
      }

      span[aria-label="search"] {
        svg {
          color: $primary_color;
        }
      }
    }

    .ant-select-single {
      .ant-select-selector {
        .ant-select-selection-item {
          line-height: 30px;
        }
      }
    }

    .ant-select:not(.ant-select-multiple) .ant-select-selector,
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
    .ant-input-number {
      height: 30px;
      font-size: 12px;
      min-width: 180px;
      max-width: 230px;
    }

    .ant-select:not(.ant-select-multiple) .ant-select-selector .ant-select-selection-placeholder,
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-placeholder {
      line-height: 2.3;
    }

    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
      height: 30px;
    }

    .ant-select {
      .ant-select-selector {
        background-color: rgba($black, 0.2);
        border: 1px solid $white_color;
        color: #fff;
        line-height: 30px;
        padding: 0 10px;
        border-radius: 5px;

        .ant-select-selection-placeholder {
          color: $white_color;
        }
      }
    }

    .ant-select-focused {
      .ant-select-selector {
        background-color: $white_color;
        color: $primary_color;

        .ant-select-selection-placeholder {
          color: $black_400;
        }
      }
    }

    .ant-select-open {
      .ant-select-clear {
        background-color: transparent;

        svg {
          color: $primary_color;
        }
      }
    }
  }
}

.ant-input,
.ant-select .ant-select-selector,
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
.ant-input-number,
.ant-select:not(.ant-select-customize-input) .ant-select-selector,
.ant-picker {
  padding: 0 20px;
  height: 35px;
  border-radius: 5px;
  border: 1px solid #d0d0d0;
  font-size: 13px;
  color: #141414;
  outline: 0;
  background-color: transparent;
}

.ant-picker .ant-picker-input>input {
  font-size: 13px;
  line-height: 20px;
}

.Switch__with_label {
  display: flex;
  // align-items: center;
  justify-content: flex-start;
  flex-direction: column;

  >.ant-col {
    width: auto;
    flex: 0 0 auto;
    max-width: initial;
    // padding: 0px 15px 0 0px;
  }

  .ant-form-item-control-input {
    min-height: auto;
  }
}

.form__input__textbox__custom {
  span {
    margin-right: 10px;
    display: inline-block;
  }
}

.custom-tabs {
  overflow: hidden;

  // display: inline-block;
  .ant-tabs-nav {
    margin-bottom: 0px;
  }

  .ant-tabs-nav::before {
    border: 0px;
  }

  .ant-tabs-content-holder {
    padding: 15px;
    border: 2px solid $primary_color_4;

    .ant-tabs-tabpane {
      padding-bottom: 20px;
    }
  }

  .ant-tabs-tab {
    font-size: 15px;
    padding: 13px 20px;
    color: $primary_color_4;
    line-height: 1;
    border-top-right-radius: 30px;
    border: 2px solid $primary_color_4;
    background-color: #fff;
    // margin: 0 0 0 -60px !important;
    z-index: 1;
    border-bottom: 0px;
    margin: 0 10px;
    border-top-left-radius: 5px;

    &:first-child {
      margin-left: 0 !important;
      z-index: 5;
    }

    &:nth-of-type(2n) {
      z-index: 4;
    }

    &:nth-of-type(3n) {
      z-index: 3;
    }

    &:nth-of-type(4n) {
      z-index: 2;
    }

    &:nth-last-child(2) {
      margin-right: 0px;
    }

    &.ant-tabs-tab-active {
      background-color: $primary_color;
      z-index: 9;

      .ant-tabs-tab-btn {
        color: $white_color;
      }
    }

    &.ant-tabs-tab-disabled {
      background-color: $scroll_light_color;

      .ant-tabs-tab-btn {
        color: $black_400;
      }

      &:hover:not(.ant-tabs-tab-active) {
        .ant-tabs-tab-btn {
          color: $black_400;
        }
      }
    }

    .ant-tabs-tab-btn {
      color: $input_label_color;
    }

    &:hover:not(.ant-tabs-tab-active) {
      .ant-tabs-tab-btn {
        color: $primary_color;
      }
    }
  }

  .ant-tabs-ink-bar {
    background-color: transparent;
  }

  .ant-space-item {
    .ant-form-item-label {
      label {
        font-size: 13px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;
        display: inline-block;
      }
    }
  }

  .add_more_btn_rate_types {
    width: 27px;
    height: 27px;
    border-radius: 50%;
    border-color: $primary_color;
    background-color: $primary_color;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 4px;

    span {
      color: $white_color;
      font-size: 17px;
    }
  }

  .close_more_btn_rate_types {
    width: 27px;
    height: 27px;
    border-radius: 50%;
    border-color: $danger_color;
    top: 3px;

    span {
      color: $danger_color;
      font-size: 15px;
    }

    &:hover {
      background-color: $danger_color;

      span {
        color: $white_color;
      }
    }
  }

  .additional_rate_label {
    white-space: nowrap;
    position: relative;
    font-size: 16px;

    ~.additional_rate_checkbox {
      width: auto;
      display: inline-block;
      margin-bottom: 0;
      margin-left: 15px;
      margin-top: -1px;

      .ant-checkbox-wrapper-disabled {
        .ant-checkbox-inner::after {
          border-color: $white_color;
          top: 41%;
        }
      }
    }
  }

  .custom__tabs__Item {
    .ant-space-item {
      &:nth-child(1) {
        width: 220px;
      }

      &:nth-child(2) {
        width: 180px;
      }
    }
  }

  .ant-input-number {
    input {
      height: 35px;
    }
  }

  .custom__tabs__item__col {
    .ant-space-item {
      width: 15.8%;

      .ant-form-item-control-input-content {
        span.ant-input-affix-wrapper {
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px;
          padding-right: 0;
        }

        .ant-btn.ant-btn-default.resetBtn {
          padding: 3px 5px 0 0;
          border: none;

          svg {
            path {
              fill: $input_label_color;
            }
          }
        }
      }

      &:nth-child(2) {
        display: none;
      }

      &:last-child {
        width: auto;
      }
    }
  }

  .custom__tabs__item__col_with_additional {
    .ant-space-item {
      &:nth-child(2) {
        display: block;
      }

      &:last-child {
        width: 27px;
      }
    }
  }

  .rate__types__label {
    .ant-col {
      width: 15.8%;
      margin: 0 4px;

      label {
        color: #444444;
        font-size: 13px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;
        display: inline-block;
      }

      .danger {
        color: $danger_color;
      }

      .link {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }

  .rate__types__label_last_nr {
    .ant-col {
      border: 1px solid #ccc;
      border-top: 0;
      text-align: center;
      padding: 10px 0;
      max-width: calc(100% - 12px);
    }
  }

  .ant-space-align-baseline {
    align-items: flex-start;
  }

  .ant-input-affix-wrapper {
    padding: 0 11px 0 20px;

    input.ant-input {
      font-size: 13px;
    }
  }
}

.custom-tabs-draft {
  .custom__tabs__item__col {
    .ant-space-item {
      &:last-child {
        width: 15.8%;
      }
    }
  }
}

.custom-tabs-zone {
  .ant-tabs-tabpane {
    width: inherit;
    overflow-x: auto;
    overflow-y: hidden;
    padding-bottom: 20px;

    .rate__types__label {
      flex-flow: inherit;

      .ant-col {
        min-width: 180px;
        flex: 0;
        margin: 0 4px;

        &:first-child {
          min-width: 170px;
        }

        &:last-child {
          min-width: 27px;
        }
      }
    }

    .custom__tabs__item__col {
      .ant-space-item {
        min-width: 180px;
        flex: 0;

        &:first-child {
          min-width: 170px;
        }

        // &:last-child {
        //   min-width: 27px;
        // }
      }
    }
  }
}

.small_paddning_remove {
  input {
    padding: 0 5px;
    text-align: center;
  }
}

.details_table {
  .custom-tabs {
    .rate__types__label {
      .ant-col {
        border: 1px solid #ccc;
        text-align: center;
        border-bottom: 0;
        border-right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px 0 5px;

        &:last-child {
          border-right: 1px solid #ccc;
        }

        label {
          white-space: nowrap;
        }

        span.sec_label_cl {
          font-size: 13px;
          padding-top: 5px;
          color: rgba($black, 0.5);
        }
      }

      &:last-child,
      &:nth-last-child(3),
      &:nth-last-child(5) {
        .ant-col {
          border-bottom: 1px solid #ccc;
        }
      }
    }
  }

  .custom-tabs-zone-view.custom-tabs {
    .rate__types__label {
      .ant-col {
        margin: 0px;
      }
    }

    .rate__types__label_last_nr {
      .ant-col {
        border: 0px;
        width: 100%;
        padding-bottom: 0;
      }

      ~.ant-divider.ant-divider-horizontal {
        display: none;
      }
    }
  }
}

.tag_pincode_select {
  .react-tagsinput {
    height: auto !important;
    min-height: 35px;
  }

  .react-tagsinput .ant-select-selection-search {
    left: 0;
  }

  .ant-select-multiple .ant-select-selection-placeholder {
    left: 22px;
    top: 56%;
  }

  .ant-select-multiple .ant-select-selection-search {
    -webkit-margin-start: 0;
    margin-inline-start: 0;
  }
}

.addModal {
  .service_Area_form {
    .ant-checkbox-group {
      margin-top: 0;
    }

    .remove_call_margin {
      .ant-col {
        margin-bottom: 10px;
      }
    }
  }

  .packetentryadd {
    padding: 8px 20px;
    border-radius: 5px;
  }

  .packetentry {
    table {

      thead,
      tbody {
        tr {
          th {
            font-size: 12px;
            padding: 10px 5px;
            color: #000;
          }

          td {
            padding: 5px 5px;
            font-size: 11px;
          }
        }
      }
    }
  }

  .viewRateCard {
    display: inline-block;
    margin-top: 30px;
    color: $primary_color;
    cursor: pointer;
    transition: 0.5s all ease-in-out;

    &:hover {
      transform: scale(0.9);
    }
  }
}

.upload_right_sec__in {
  .ant-form-item-has-error {
    .ant-form-item-has-error-span-cus {
      display: none;
    }
  }

  .ant-form-item {
    margin-bottom: 5px;
  }
}

.rateCardStep1 {
  .ant-select {
    display: flex;
  }
}

.ant-select-multiple .ant-select-selection-placeholder {
  left: 21px;
}

.ant-select-multiple .ant-select-selection-search {
  margin-inline-start: 0;
}

.ant-page-header.userInfo_Modal {
  display: flex;
  align-items: center;
  justify-content: center;

  .form__input__textbox_left {
    text-align: left !important;
  }

  @include mini-desktop-height {
    display: inherit;
  }

  .ant-page-header-content {
    width: 600px;
    margin: 0 auto;
    //background: #fff;
    //padding: 50px 25px;
    border-radius: 10px;
    max-width: 100%;
    height: auto;

    @include mini-desktop-height {
      padding-top: 0px;
    }

    //box-shadow: 0 0.3rem 0.8rem rgba(0, 0, 0, 0.12);

    .ant-row {
      margin-bottom: 20px;
      margin-left: 0 !important;
      margin-right: 0 !important;
      align-items: center;

      @include mini-desktop-height {
        margin-bottom: 5px;
      }

      &:last-child {
        margin-bottom: 0;
      }

      // @include ipad{
      //   font-size: 12px;
      // }
      .form__input__textbox_inner {
        border: 1px solid #e5e5e5;
        padding: 10px 10px;
        border-radius: 5px;
        position: relative;
        min-height: 44px;

        @include mini-desktop-height {
          padding: 7px 10px;
          min-height: 37px;
        }

        .userInfo_Icon {
          border-radius: 5px 5px 5px 20px;
          width: 37px;
          height: 100%;
          position: absolute;
          right: 0;
          top: 0;
          border: 0;
          box-shadow: none;

          @include mini-desktop-height {
            width: 30px;
          }

          &:hover {
            background-color: $white_color;
            color: $primary_color;
            border-color: $primary_color;
            border: 1px solid;
          }
        }

        .ant-space-item {
          display: inline-block;
          width: auto;
        }
      }
    }
  }

  .avtar_row {
    padding-bottom: 20px;

    .ant-avatar {
      min-height: 150px;
      min-width: 150px;
      position: relative;
      background-color: $primary_color;
      border: 5px solid $primary_color;

      img {
        max-width: 100%;
        max-height: 100%;
        // width: auto;
        // height: auto;
      }

      @include mini-desktop-height {
        min-height: 120px;
        min-width: 120px;
      }

      @include ipad {
        min-height: 100px;
        min-width: 100px;
      }
    }

    .form__input__textbox {
      text-align: right;
      margin: 0 auto;
    }

    button {
      position: absolute;
      right: 15px;
      bottom: 8px;

      @include mini-desktop-height {
        right: 18px;
        bottom: -4px;
      }

      @include ipad {
        right: 37%;
      }

      @include mini-tablet {
        right: 35%;
      }
    }
  }

  .userInfo_Icon {
    color: $white_color;
    background-color: $primary_color;
    border-radius: 50%;
    font-size: 14px;
    padding: 0 !important;
    width: 35px;
    height: 35px;

    @include ipad {
      width: 20px;
      height: 20px;
      font-size: 10px;
    }

    svg {
      @include ipad {
        margin: 4px;
      }
    }

    &:hover {
      background-color: $white_color;
      color: $primary_color;
      border-color: $primary_color;
    }
  }
}

.small-check {
  align-items: center;

  span {
    font-size: 14px;
  }

  .ant-checkbox-inner {
    width: 20px;
    height: 20px;
    border-radius: 3px;

    &:after {
      width: 7px;
      height: 12px;
      left: 16%;
    }
  }
}

.company-view-table {
  overflow-x: auto;

  table {
    width: 100%;
    text-align: center;

    th {
      background-color: $black_100;
      border: 1px solid $table_border;
      padding: 10px;
      font-weight: 300;
      color: $black;
      font-size: 14px;
      letter-spacing: 0.5px;
    }

    tr,
    td {
      border-bottom: 1px solid $table_border;
      border: 1px solid $table_border;
      padding: 10px;
      font-size: 12px;
      color: $black_700;
      word-break: break-word;
    }
  }

  .listCountNew {
    text-align: right;
    margin-bottom: 12px;

    .ant-btn {
      padding: 7px 9px;
      height: auto;
      line-height: 16px;
      border-radius: 5px;
      font-size: 12px;
      border-color: #d0d0d0;
      margin-left: 15px;
    }
  }
  &.company-view-table-int{
    table {
      tr,
      td {
        min-width: 150px;
      }
    }
  }
}

.addModalGreetings .ant-modal-content .ant-modal-body .ant-form-item {
  margin-bottom: 0;
}

.change_profile_otp {
  margin-bottom: 20px !important;

  .ant-form-item-explain-error {
    margin-left: 22px;
  }
}

.UserProfileDesign {
  @include mini-tablet {
    max-width: 470px;
  }

  .ant-popover-title {
    text-align: center;
    border: 0;
    font-size: 18px;
    padding: 7px 0px;
  }

  .ant-popover-inner {
    padding: 10px 0 30px 0;
    border-radius: 10px 10px 10px 10px;

    @include mini-desktop-height {
      padding-bottom: 10px;
    }
  }
}

.form__input__textbox {
  label {
    color: #000;
  }

  span {
    color: #424242;
  }

  .form__input__textbox_textToText {
    padding: 5px 0;

    label {
      color: #000000;
      /* width: 120px; */
      display: inline-block;
    }
  }
}

.addModal {
  .ant-form {
    .slab__rate__types__label {
      .ant-col {
        margin-bottom: 15px;

        &:nth-child(2) {
          text-align: center;
        }
      }
    }
  }
}

.addModal {
  .ant-form {
    .custom__tabs__item__col_Slab {
      .ant-col {
        margin-bottom: 5px;

        .Switch__with_label {
          margin: 0 auto;
        }

        .add_more_btn_rate_types,
        .close_more_btn_rate_types {
          top: 1px;
        }
      }
    }
  }
}

.zform_block_wrapper {
  .ant-col-xs-5 {
    display: block;
    flex: 0 0 20%;
    max-width: 20%;
  }

  >.ant-col {
    margin-bottom: 30px;
  }

  .zform_block {
    padding: 10px;
    border-radius: 5px;
    // margin-bottom: 30px;
    color: $white_color;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    // flex-wrap: wrap;
    flex-direction: column;

    // box-shadow: 0px 4px 0px 0px rgba(89, 166, 254, 0.48);
    label {
      margin: 0;
      font-size: 14px;
      font-weight: 600;
      text-transform: uppercase;
      word-break: break-word;
      text-align: center;
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box !important;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      white-space: normal;
    }

    span:not(.small) {
      font-size: 12px;
      margin: 4px 0;
      // text-overflow: ellipsis;
      // overflow: hidden;
      // white-space: nowrap;
      width: 100%;
      display: inline-block;
      text-align: center;
      font-weight: 400;
    }

    span.small {
      font-size: 12px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      width: 100%;
      text-align: center;
    }

    &.blue_block {
      background: -moz-linear-gradient(118deg,
          rgb(77, 159, 254) 0%,
          rgb(153, 197, 248) 100%);
      background: -webkit-linear-gradient(118deg,
          rgb(77, 159, 254) 0%,
          rgb(153, 197, 248) 100%);
      background: -ms-linear-gradient(118deg,
          rgb(77, 159, 254) 0%,
          rgb(153, 197, 248) 100%);
      background: linear-gradient(118deg,
          rgb(77, 159, 254) 0%,
          rgb(153, 197, 248) 100%);
    }

    &.green_block {
      background: -moz-linear-gradient(118deg,
          rgb(42, 200, 169) 0%,
          rgb(78, 228, 197) 100%);
      background: -webkit-linear-gradient(118deg,
          rgb(42, 200, 169) 0%,
          rgb(78, 228, 197) 100%);
      background: -ms-linear-gradient(118deg,
          rgb(42, 200, 169) 0%,
          rgb(78, 228, 197) 100%);
      background: linear-gradient(118deg,
          rgb(42, 200, 169) 0%,
          rgb(78, 228, 197) 100%);
    }

    &.orange_block {
      background: -moz-linear-gradient(118deg,
          rgb(238, 172, 72) 0%,
          rgb(244, 196, 123) 100%);
      background: -webkit-linear-gradient(118deg,
          rgb(238, 172, 72) 0%,
          rgb(244, 196, 123) 100%);
      background: -ms-linear-gradient(118deg,
          rgb(238, 172, 72) 0%,
          rgb(244, 196, 123) 100%);
      background: linear-gradient(118deg,
          rgb(238, 172, 72) 0%,
          rgb(244, 196, 123) 100%);
    }

    &.pink_block {
      background-image: -moz-linear-gradient(118deg,
          rgb(246, 110, 123) 0%,
          rgb(250, 159, 142) 100%);
      background-image: -webkit-linear-gradient(118deg,
          rgb(246, 110, 123) 0%,
          rgb(250, 159, 142) 100%);
      background-image: -ms-linear-gradient(118deg,
          rgb(246, 110, 123) 0%,
          rgb(250, 159, 142) 100%);
      background: linear-gradient(118deg,
          rgb(246, 110, 123) 0%,
          rgb(250, 159, 142) 100%);
    }

    &.purple_block {
      background-image: -moz-linear-gradient(118deg,
          rgb(137, 116, 189) 0%,
          rgb(170, 151, 216) 100%);
      background-image: -webkit-linear-gradient(118deg,
          rgb(137, 116, 189) 0%,
          rgb(170, 151, 216) 100%);
      background-image: -ms-linear-gradient(118deg,
          rgb(137, 116, 189) 0%,
          rgb(170, 151, 216) 100%);
      background: linear-gradient(118deg,
          rgb(137, 116, 189) 0%,
          rgb(170, 151, 216) 100%);
    }

    &.cursor_pointer {
      cursor: pointer;
    }

    .cursor_pointer {
      cursor: pointer;
    }
  }
}

.kycFileUploadView {
  margin-bottom: 20px !important;

  label {
    width: 100%;
    display: inline-block;
    margin-bottom: 5px;
  }

  .ant-image {
    margin-right: 10px;
    border: 1px solid #c2c2c2;

    img {
      height: 70px;
      object-fit: cover;
    }
  }
}

.more_info_sec {
  .ant-avatar {
    background-color: $primary_color;
    padding: 2px;
    margin-bottom: 15px;

    img {
      border-radius: 50%;
      object-fit: cover;
    }
  }

  h2 {
    color: $black;
    font-size: $fifteen;
    margin-bottom: 0;
  }

  h3 {
    color: $black_800;
    font-size: $fifteen;
    margin-bottom: 0;
  }

  h4 {
    color: $black_700;
    font-size: $fifteen;
  }

  h5 {
    color: $black_600;
    font-size: $fifteen;
  }
}

.moreInfoCollapse {
  width: 100%;

  .ant-collapse-item {
    .ant-collapse-header {
      padding: 0;
    }

    .ant-collapse-content {
      padding-top: 20px;
      width: 100%;

      .ant-collapse-content-box {
        padding: 0;

        .ant-row.more_info_sec.more_info_sec_table {
          border: 1px solid #ededed;
          border-bottom: 0;
          padding: 0;

          .ant-col-12 {
            border-bottom: 1px solid #ededed;
            border-collapse: collapse;
            padding: 8px !important;

            label {
              color: $black_600;
            }

            span {
              color: $black_800;
            }
          }
        }
      }
    }

    .ant-collapse-header-text {
      width: 96%;
    }
  }
}

.more_info_sec~.ant-divider-horizontal {
  margin: 15px 0;
}

.LocationsProfileDesign {
  .ant-drawer-body {
    padding-left: 0;
    padding-right: 0px;
  }

  .ant-drawer-footer {
    margin-top: 15px;
  }

  .ant-drawer-header {
    padding: 0px;
  }

  .ant-popover-inner-content {
    padding: 20px 15px;
  }

  .ant-page-header {
    &.LocationsProfileInfo_Modal {
      .ant-page-header-content {
        height: 100%;
        width: 100%;
      }

      .more_info_sec.pr-0 {
        padding-right: 0;
      }

      .more_info_sec {
        padding: 0 20px;

        p {
          span {
            svg {
              color: $primary_color;
            }
          }
        }

        svg[data-icon="arrow-right"] {
          color: #a2a2a2;
        }

        .ant-tag-error,
        .ant-tag-success {
          margin: 0 0 0 10px;
        }

        .ant-collapse-expand-icon {
          margin-left: -6px;
        }
      }

      .avtar_row {
        padding-bottom: 5px;

        .ant-avatar-image {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        button {
          @include mini-desktop-height {
            right: 3px;
            bottom: -1px;
          }
        }
      }
    }
  }

  .position-re {
    position: relative;
  }

  .profileDesignicon_sp {
    position: absolute;
    right: 30px;
    top: 0;
  }
}

.profileDesignicon.viewIconDesignicon {
  background-color: #2d38df;

  &:hover {
    background-color: $white_color;
    color: #2d38df;
    border-color: #2d38df;
  }
}

.profileDesignicon {
  color: $white_color;
  background-color: $primary_color;
  border-radius: 50%;
  font-size: 13px;
  padding: 0 !important;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;

  @include ipad {
    width: 20px;
    height: 20px;
    font-size: 10px;
  }

  svg {
    @include ipad {
      margin: 4px;
    }
  }

  &:hover {
    background-color: $white_color;
    color: $primary_color;
    border-color: $primary_color;
  }
}

.list_link_color {
  color: $primary_color !important;
  cursor: pointer;
  line-height: 26px !important;
}

.profileDesignicon {
  color: $white_color;
  background-color: $primary_color;
  border-radius: 50%;
  font-size: 13px;
  padding: 0 !important;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;

  @include ipad {
    width: 20px;
    height: 20px;
    font-size: 10px;
  }

  svg {
    @include ipad {
      margin: 4px;
    }
  }

  &:hover {
    background-color: $white_color;
    color: $primary_color;
    border-color: $primary_color;
  }
}

.remarksNotes {
  ul {
    padding: 10px 0 0 12px;

    li {
      color: $input_label_color;
      margin-bottom: 5px;
      font-size: $thirteen;
      list-style: square;
    }

    ul {
      padding-top: 0;

      li {
        list-style: circle;
      }
    }
  }
}

.kyc-icon-new {
  span {
    color: #d72ddf;

    svg {
      width: 1.2em;
      height: 1.2em;
      top: 1px;
      position: relative;
    }
  }
}

a.ant-btn.btnLink {
  padding-top: 7px !important;
}

.ag-theme-alpine input[class^="ag-"]:not([type]):invalid,
.ag-theme-alpine input[class^="ag-"][type="text"]:invalid,
.ag-theme-alpine input[class^="ag-"][type="number"]:invalid,
.ag-theme-alpine input[class^="ag-"][type="tel"]:invalid,
.ag-theme-alpine input[class^="ag-"][type="date"]:invalid,
.ag-theme-alpine input[class^="ag-"][type="datetime-local"]:invalid,
.ag-theme-alpine textarea[class^="ag-"]:invalid {
  border-width: 1px;
  border-style: solid;
  border-color: #e02525;
  border-color: var(--ag-input-border-color-invalid,
      var(--ag-invalid-color, #e02525));
}

.moreInfoPopover {
  width: 370px;
}

.ant-drawer-body {
  flex-grow: 1;
  padding: 24px;
  overflow: auto;
  font-size: 14px;
  line-height: 1.5715;
  word-wrap: break-word;

  @include desktop {
    .details_table {
      font-size: 12px;
    }
  }

  @mixin ipad {
    .details_table {
      font-size: 11px;
    }
  }
}

.custom_checkbox_design {
  .ant-checkbox-group-item {
    align-items: center;
    margin-top: 7px;
    width: 100%;

    span {
      font-size: 14px;
      color: $black_700;
    }

    .ant-checkbox {
      .ant-checkbox-inner {
        width: 20px;
        height: 20px;

        &::after {
          left: 17%;
          top: 43%;
        }
      }
    }
  }
}

.ant-radio-wrapper {
  span {
    color: $black_700;
  }
}

.product_item_call {
  position: relative;

  .ant-col.ant-form-item-control {
    position: inherit;
  }

  .ant-form-item-explain-error {
    position: absolute;
    left: -9%;
    margin-top: 5px;
  }
}

.ant-form>.twoform>.ant-row {
  &>.ant-col {
    margin-bottom: 25px;
    // &:last-child {
    // 	margin-bottom: 0;
    // }
  }
}

.ant-form {
  .twoform {
    .rightForm {
      border-left: 1px solid rgba(0, 0, 0, 0.06);
      width: 100%;
      flex: 0 0 20%;
      padding: 0px 5px 0 10px;

      // .ant-col {
      //   margin-bottom: 0px;
      // }
    }
  }
}

.upload_right_sec_remove_border {
  .ant-upload.ant-upload-select.ant-upload-select-picture-card {
    border: 0;
    background: 0;
    height: auto;
    text-align: left;

    .ant-upload {
      justify-content: start;
    }
  }
}

.bookingSettingsModal #setting_form>.ant-row>.ant-col {
  margin-bottom: 10px;
}

.bookingSettingsModal {
  .bookingSetting {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 3rem !important;
    margin-bottom: 0 !important;

    label {
      min-width: 130px;
    }
  }

  .ant-divider-horizontal {
    margin: 15px 0;
  }
}

.fr_family_table {
  .ant-form-item {
    margin-bottom: 0;
  }
}

.add_more_trans_icon_add {
  background: transparent;
  min-width: auto;
  border-color: $primary_color;
  color: $primary_color;
  border-radius: 50%;
  padding: 0;
  width: 25px;
  height: 25px;
  font-size: 14px;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &:hover, &:focus {
    transform: scale(0.9);
    border-color: $primary_color;
    color: $primary_color;
  }
}

.ewayDetails {
  .ant-col.ant-form-item-label {
    width: 100%;
    text-align: left;

    label {
      margin-bottom: 5px;
    }
  }

  .ant-form-item-control {
    min-width: 100%;
  }
}

#deleteStockTransactionForm,
#confirmBagTagForm {
  .ant-radio-group {
    label.ant-radio-wrapper.ant-radio-wrapper-in-form-item {
      width: 100%;
      margin-top: 7px;
    }
  }
}

.statusModalPaddingBottomadd {
  .ant-modal-content {
    .ant-modal-body {
      padding: 25px 30px 30px;
    }
  }
}

.cashBookingForm {
  .customgriddesign {
    flex: 0 0 80%;
    width: 100%;
  }

  .ant-page-header-content {
    height: auto;
    padding: 15px 15px;
    position: relative;

    .ant-form>.twoform>.ant-row {
      &>.ant-col {
        margin-bottom: 5px;
        // &:last-child {
        // 	margin-bottom: 0;
        // }
      }
    }
  }

  .cashBookingBackToList {
    .ant-btn {
      padding: 7px 9px;
      height: auto;
      line-height: 16px;
      border-radius: 5px;
      font-size: 12px;
      border-color: $input_border;
      margin-right: 15px;

      &:hover {
        background-color: $primary_color;
        color: $white_color;
      }
    }
  }

  .CashBookingBtnCenter {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0px;

    .saveBtnsaving {
      background-color: $primary_color;
      border-color: $primary_color;
      transition: all 0.5s ease;
      border-radius: 5px;
      padding: 10px 20px;
      font-size: 16px;
      height: auto;
      line-height: 16px;
      min-width: 100px;
      margin-right: 15px;

      &[disabled] {
        color: rgba(0, 0, 0, 0.25);
        border-color: #d9d9d9;
        background: #f5f5f5;
        text-shadow: none;
        box-shadow: none;
      }

      &:focus,
      :hover {
        @include themeColorOpacity($primary, 0.1);
        border-color: $primary_color;
        color: $primary_color;
      }
    }

    .cancelBtn {
      border-color: $primary_color;
      color: $primary_color;
      @include themeColorOpacity($primary, 0.1);
      padding: 10px 20px;
      height: auto;
      border-radius: 5px;
      font-size: 16px;
      line-height: normal;
      min-width: 100px;
      margin-left: 0;
    }
  }

  .upload_right_sec_remove_border {
    .capture_images {
      span {
        margin-left: 8px;
      }

      svg {
        color: #a2a2a2;
      }
    }
  }

  .ant-form-item {
    margin-bottom: 3px;

    .ant-form-item-label {
      padding: 0;
      z-index: 9;
      margin-bottom: -9px;
      margin-left: 5px;

      label {
        background-color: #fff;
        padding: 3px;
        font-size: 11px;
      }
    }

    .ant-input,
    .ant-select .ant-select-selection-search-input,
    .ant-select-selector,
    .ant-picker,
    .ant-input-number,
    .ant-input-number-input {
      font-size: 11px;
      height: 30px;
    }

    .ant-input-number-focused {
      background-color: $primary_color_10;
      border-color: $primary_color;
    }

    .ant-input {
      &:focus {
        background-color: $primary_color_10;
      }
    }

    .ant-input-number.ant-input-number-in-form-item.text-right {
      padding: 0;
      border: 0;

      input {
        padding: 0 30px 0 20px;
        border: 1px solid #d0d0d0;
        border-radius: 5px;

        &:focus {
          background-color: $primary_color_10;
          border-color: $primary_color;
        }
      }
    }

    .ant-select-focused {
      .ant-select-selector {
        background-color: $primary_color_10 !important;
      }
    }

    .ant-select-selection-item,
    .ant-select:not(.ant-select-multiple) .ant-select-selector .ant-select-selection-placeholder,
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-placeholder {
      line-height: 30px;
    }

    .fontBig {
      font-size: 14px;
      font-weight: 800;

      &[disabled] {
        color: rgba(0, 0, 0, 0.53);
      }

      .ant-input-number-input {
        font-size: 14px;
        font-weight: 800;
      }
    }

    .ant-form-item-explain {
      display: none;
    }
  }

  .disabledNew {
    .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
      background: hsl(0, 0%, 100%);
    }

    .ant-input[disabled] {
      background: #ffffff;
    }

    .text-primary-color {
      span {
        color: $primary_color !important;
      }
    }

    span {
      color: rgba(0, 0, 0, 0.25);
    }
  }

  .upload_right_sec {
    .ant-form-item {
      margin-bottom: 0px;

      &:focus-visible {
        outline: none;

        .ant-form-item-control {
          outline: none;
        }

        button {
          border-color: $primary_color;
        }
      }

      .ant-upload.ant-upload-select {

        // margin-top: 9px;
        span {
          display: inline-block;
        }

        .ant-btn {
          padding: 4px 3px;
          font-size: 11px;
          margin-right: 5px;
          align-items: center;
          display: inline-flex;
        }
      }

      .capture_images {
        padding: 4px 3px;
        font-size: 11px;
      }

      .ant-form-item-control-input-content {
        .ant-form-item-control-input-content {
          display: flex;
          align-items: center;
          margin-top: 8px;
          width: 100%;
        }
      }
    }

    span {
      // display: inline-block;
      overflow: hidden;
      line-height: 1;
    }
  }

  .ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    margin-right: 1px;
  }

  .customgriddesign {
    .customgriddesignrow {
      &>.ant-col.col-sm {
        flex-basis: 10%;
        width: 10%;
      }
      &>.ant-col {
        flex-basis: 20%;
        width: 20%;        
      }
      &>.customgriddesigncolBig.col-lg {
        flex-basis: 30%;
        width: 30%;
      }
      &>.customgriddesigncolBig {
        flex-basis: 40%;
        width: 40%;
      }
    }
  }

  .info_lable_booking {
    margin-top: -2px;
    padding-left: 24px !important;

    span {
      color: $primary_color;
    }

    lable {
      font-size: 14px;
    }
  }

  .bookingenter {
    .ant-table-tbody {
      tr {
        td {
          padding: 5px 10px;
          font-size: 11px;
        }
      }
    }

    .ant-table-thead {
      tr {
        th {
          padding: 5px 10px;
          font-size: 11px;
        }
      }
    }
  }

  .grid_wrapper,
  .ant-page-header.ant-page-header-ghost {
    min-height: 213px;
  }

  .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
  .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover,
  .ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number,
  .ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number:hover,
  .ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector {
    @include themeColorOpacity($danger_color, 0.1);
  }

  .ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number input {
    border-color: $danger_dark;
  }

  .ag-overlay-wrapper {
    padding-top: 70px;
  }

  .ant-input-number-in-form-item.text-right {
    input {
      text-align: right;
    }
  }

  .rightForm_bottom {
    border-left: 1px solid rgba(0, 0, 0, 0.06);
    width: 100%;
    flex: 0 0 20%;
    padding: 0px 5px 0 10px;
  }

  .ant-input-number-handler-wrap {
    background-color: transparent;
  }

  .ant-upload-list-item {
    width: 93%;
    margin-top: 0;
    padding: 0;
    height: 56px;
  }

  .DeliveryRun {
    .ant-form-item .ant-form-item-explain {
      display: block;

      .ant-form-item-explain-error {
        margin-top: 2px;
      }
    }
  }
  &.editModal{
    .ant-form-item{
      .ant-form-item-explain{
        display: block;
      }
    }
  }
}

.AccountBookingForm.cashBookingForm .grid_wrapper,
.AccountBookingForm.cashBookingForm .ant-page-header.ant-page-header-ghost {
  min-height: 301px;
}

.fullscreen__spinner_Op {
  position: absolute;
  background: rgba(255, 255, 255, 0.75);
  z-index: 1;
  height: calc(100% - 80px);
  width: calc(100% - 40px);
}

.fullscreen__spinner_updateBookingRate {
  left: 0;
  width: 100%;
  height: 100%;

  .spin_logo {
    display: none;
  }
}

.SenderInfo:before {
  content: "Consignor info";
  margin-top: -11px;
  margin-left: 0;
  background: #fff;
  padding: 0 12px;
}
.Shipper:before {
  content: "Shipper";
  margin-top: -11px;
  margin-left: 0;
  background: #fff;
  padding: 0 12px;
}

.inscanSection:before {
  content: "Inscan Section";
  margin-top: -11px;
  margin-left: 0;
  background: #fff;
  padding: 0 12px;
}

.outscanSection:before {
  content: "Outscan Section";
  margin-top: -11px;
  margin-left: 0;
  background: #fff;
  padding: 0 12px;
}

.railwaySection:before {
  content: "Railway Information";
  margin-top: -11px;
  margin-left: 0;
  background: #fff;
  padding: 0 12px;
}

.flightSection:before {
  content: "Flight Information";
  margin-top: -11px;
  margin-left: 0;
  background: #fff;
  padding: 0 12px;
}

.ReceiverInfo:before {
  content: "Consignee Info";
  margin-top: -11px;
  margin-left: 0;
  background: #fff;
  padding: 0 12px;
}

.destinationInfo:before {
  content: "Destination Info";
  margin-top: -11px;
  margin-left: 0;
  background: #fff;
  padding: 0 12px;
}

.ShipmentInfo:before {
  content: "Shipment Info";
  margin-top: -11px;
  margin-left: 0;
  background: #fff;
  padding: 0 12px;
}
.InvoiceDetails:before {
  content: "Invoice Details";
  margin-top: -11px;
  margin-left: 0;
  background: #fff;
  padding: 0 12px;
}
.BuyrDetails:before {
  content: "Buyr's Details";
  margin-top: -11px;
  margin-left: 0;
  background: #fff;
  padding: 0 12px;
}
.CommercialDetails:before {
  content: "Commercial Details";
  margin-top: -11px;
  margin-left: 0;
  background: #fff;
  padding: 0 12px;
}

.TodayBookingCollapse {
  border-radius: 5px;

  .ant-collapse-item>.ant-collapse-header {
    padding: 7px 10px;
    border-radius: 5px;
    overflow: hidden;
  }

  .ant-collapse-content-box {
    padding: 7px 10px;
  }

  .ant-divider.ant-divider-horizontal {
    background-color: #d0d0d0;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .TodayBookingItem {
    display: flex;
    justify-content: space-between;

    h4 {
      margin-bottom: 2px;
      font-size: 13px;
    }
  }
}

.EWAYADD {
  margin-top: 9px;

  button.ant-btn.ant-btn-default {
    min-width: 47px;
    font-size: 11px;
    padding: 4px 12px;
  }
}

.forwardBookingForm {
  .EWAYADD {
    margin-top: 4px;
  }
}

.cashBookingBackToList span:after {
  content: "";
  border: 1px solid #727070;
  width: 10px;
  height: 1px;
  position: absolute;
  top: 20px;
  right: 20px;
}

.DeliveryRun {

  .ant-input,
  .ant-select .ant-select-selection-search-input,
  .ant-select-selector,
  .ant-picker,
  .ant-input-number,
  .ant-input-number-input {
    font-size: 11px;
    height: 30px;
    &[disabled] {
      color: rgba(0, 0, 0, 0.8);
    }
  }

  .ant-form-item {
    margin-bottom: 3px;

    .ant-form-item-label {
      padding: 0;
      z-index: 9;
      margin-bottom: -9px;
      margin-left: 5px;

      label {
        background-color: #fff;
        padding: 3px;
        font-size: 11px;
      }
    }

    .ant-input,
    .ant-select .ant-select-selection-search-input,
    .ant-select-selector,
    .ant-picker,
    .ant-input-number,
    .ant-input-number-input {
      font-size: 11px;
      height: 30px;
    }

    .ant-input {
      &:focus {
        background-color: $primary_color_10;
      }
    }

    .ant-select-focused {
      .ant-select-selector {
        background-color: $primary_color_10 !important;
      }
    }

    .ant-select-selection-item,
    .ant-select:not(.ant-select-multiple) .ant-select-selector .ant-select-selection-placeholder,
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-placeholder {
      line-height: 30px;
    }

    .fontBig {
      font-size: 14px;
      font-weight: 800;
    }

    .ant-form-item-explain {
      min-height: inherit;
      line-height: 14px;
    }
  }

  .ant-form>.TopForm>.ant-row>.ant-col {
    margin-bottom: 0px;
    flex: 0 0 20%;
    max-width: 20%;
  }

  .ant-divider-horizontal {
    margin: 8px 0 2px;
  }

  .ConsignmentAddBtn {
    height: 30px;
    padding: 0 24px;
    border-radius: 5px;
    margin-top: 10px;
    min-width: inherit;
    margin-right: 15px;
  }

  .packetentry {
    tbody {
      tr {
        td {
          padding: 5px;
          font-size: 11px;
        }
      }
    }

    thead {
      tr {
        th {
          padding: 5px;
          font-size: 11px;
        }
      }
    }
  }
}

.custom_table_btn {
  .ant-space {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .ant-space-item {
      button {
        height: 22px;
      }

      button.ant-btn.ant-btn-primary {
        span {
          font-size: 12px;
          margin-left: 2px;
        }

        svg {
          fill: #fff;
        }

        border-radius: 3px;
      }

      .resetBtn {
        span {
          font-size: 12px;
          display: block;
        }

        padding: 0 7px;
        border-color: rgba(var(--main-color), 1);
        color: rgba(var(--main-color), 1);
        background-color: rgba(var(--main-color), 0.1);
        border-radius: 3px;
      }
    }
  }
}

.row_btn_form {
  margin-top: 8px;

  .ant-btn-primary {
    border-radius: 5px;
    padding: 7px 20px;
    margin-right: 15px;
  }

  .cancelBtn {
    border-color: $primary_color;
    color: $primary_color;
    @include themeColorOpacity($primary, 0.1);
    border-radius: 5px;
  }
}

.ant-picker-ranges {
  .ant-picker-ok {
    .ant-btn-primary {
      border-radius: 5px;
      padding: 8px 9px;
      font-size: 12px;
      height: auto;
      line-height: 12px;
      min-width: 71px;
    }
  }
}

// .AccountBookingForm.cashBookingForm {
// .CashBookingBtnCenter{
//   margin-top: -130px;
// }
// .rightForm_bottom{
//   margin-top: 94px;
// }
// }
.custom_checkbox_design_1 {
  .ant-checkbox-wrapper {
    align-items: center;
  }
}

.view_text_textbox_hf {
  display: inline-flex;
  justify-content: space-between;
}

.details_table {
  .ant-row.ant-row-no-wrap {
    .ViewConsignmentAddBtn {
      // height: 30px;
      padding: 0px 24px 0px;
      border-radius: 5px;
      line-height: 34px;
      margin-right: 15px;

      &:disabled {
        span {
          color: rgba(0, 0, 0, 0.25);
        }

        &:hover {
          border-color: #d9d9d9;
          background: #f5f5f5;

          span {
            color: rgba(0, 0, 0, 0.25);
          }
        }
      }

      span {
        color: $white_color;
      }

      &:hover {
        border-color: $primary_color;
        @include themeColorOpacity($primary, 0.1);

        span {
          color: $primary_color;
        }
      }
    }

    .rtoIcon {
      fill: #9003fc;
      font-size: 14px;
      padding: 0 !important;
      width: 17px;
      height: 20px;
    }

    .viewIconAction {
      color: #2d38df;
      font-size: 14px;
      padding: 0 !important;
      width: 25px;
      height: 27px;
      line-height: 0;
      display: inline-block;
      vertical-align: bottom;
    }
  }
}

.ant-row.ant-row-no-wrap {
  .ViewDRSentry {
    label {
      display: inline-flex;
    }
  }
}

.consignment_detail_con {
  .kycFileUpload {
    padding: 5px;
  }
}

.Sticker_Preference_Form {
  label.ant-radio-wrapper.ant-radio-wrapper-in-form-item {
    margin-bottom: 5px;

    .ant-radio {
      top: 0.3em;
    }
  }

  .custom_checkbox_design {
    .ant-checkbox-wrapper {
      .ant-checkbox {
        top: 5px;
      }
    }

    .ant-checkbox-inner {
      width: 20px;
      height: 20px;
      border-radius: 4px;

      &:after {
        width: 6px;
        height: 13px;
        left: 16%;
        top: 44%;
      }
    }

    .ant-checkbox-wrapper span {
      font-size: 14px;
      color: #616161;
    }
  }
}

.StickerPreferenceModal {
  .ant-modal-close {
    display: none;
  }
}

#addStockTransactionForm .ant-row.ant-form-item,
.service_area .ant-form-item {
  margin-bottom: 0;
}

.ant-btn.details_table_custom_link {
  color: $primary_color;
  width: auto;
  svg{
    fill: $primary_color;
  }
}

.custom_checkbox_inline {
  display: flex !important;
  align-items: center !important;

  .ant-form-item-has-success,
  .ant-form-item {
    margin: 0 !important;
  }
}

.user-image-full-width .ant-upload-list-text,
.user-image-full-width .ant-upload-list-picture {
  width: 100%;
}

.header-dropdown-input {
  .ant-input-wrapper.ant-input-group {
    border: 1px solid $white_color;
    border-radius: 5px;
    overflow: hidden;

    .ant-input-affix-wrapper {
      background-color: rgba($black, 0.2);
      border: 0;
      color: #fff;
      padding: 0 0 0 10px;
      height: 30px;
      min-width: 195px;

      input {
        background-color: transparent;
        height: 30px;
        // line-height: 30px;
        color: #fff;
        border-radius: 0;
        font-size: 12px;

        &::placeholder {
          /* Chrome, Firefox, Opera, Safari 10.1+ */
          color: #fff;
          opacity: 1;
          /* Firefox */
        }

        &:-ms-input-placeholder {
          /* Internet Explorer 10-11 */
          color: #fff;
        }

        &::-ms-input-placeholder {
          /* Microsoft Edge */
          color: #fff;
        }
      }

      .ant-input-clear-icon {
        svg {
          fill: #fff;
        }
      }
    }

    .ant-input-group-addon {
      background-color: rgba($black, 0.2);

      button {
        background-color: transparent;
        border: 0;
        height: 30px;

        svg {
          fill: #fff;
        }
      }
    }
  }
}

.details_table_timeline {
  .ant-timeline-item {
    padding-bottom: 30px;

    &:last-child {
      padding-bottom: 0;
    }
  }

  .ant-timeline-item-head-blue {
    border-color: $primary_color;
  }

  .ant-timeline.ant-timeline-label {
    .ant-timeline-item-label {
      width: calc(14% - 22px);
      top: -5.001px;
    }

    .ant-timeline-item-content {
      top: -5.001px;
      left: calc(16% - 4px);
      width: calc(80% - 14px);
    }

    .ant-timeline-item-head {
      margin-left: -6px;
      width: 13px;
      height: 13px;
    }

    .ant-timeline-item-tail,
    .ant-timeline-item-head {
      left: 15%;
    }
  }
}

.details_table_timeline {
  margin-top: 20px;
}

.text-link-look {
  color: $primary_color !important;
  cursor: pointer;
}

.ant-input-search>.ant-input-group>.ant-input-group-addon:last-child {
  left: 0px;
}

.collapse_new_design {
  border: 0;
  margin: 0 2px;
  background-color: transparent;

  .ant-collapse-content {
    border: 2px solid $primary_color_4;
    border-top: 0;
  }

  .ant-collapse-item {
    border: 0;
    margin-bottom: 10px;

    &.ant-collapse-item-active {
      .ant-collapse-header {
        background-color: $primary_color;
        color: $white_color;

        .ant-collapse-arrow {
          svg {
            fill: $white_color;
            font-size: 18px;
          }
        }
      }
    }
  }

  .ant-collapse-header {
    // background-color: $primary_color_2;
    border: 2px solid $primary_color_4;
    font-size: 16px;
    background-color: transparent;
    border-radius: 0px 30px 0px 0px !important;

    .ant-collapse-arrow {
      svg {
        fill: rgba(0, 0, 0, 0.85);
        font-size: 18px;
      }
    }
  }

  .details_table {
    .deliveryDetailsTableWeb {
      margin-top: 20px;

      // &:nth-child(2) {
      //   margin-top: 0;

      //   .ant-row.ant-row-no-wrap {
      //     &:nth-child(1) {
      //       border-top: 0;
      //     }
      //   }
      // }
    }
  }
}

.remove_inner_box {
  .ErrorDivWrapper {
    background-color: transparent;
    box-shadow: none;

    .ErrorImage {
      padding-right: 0px;
    }
  }
}

@include min-tablet {
  
  .site__layout {
    a[href*="tel:"] {
      cursor:default;
    }
    .mobileView_resp {
      flex: 0 0 100%;
      max-width: 100%;
      padding: 10px;
      @include mobile {
        flex: 0 0 100%;
        max-width: 100%;
        margin: 0 0px;
        padding: 10px;
      }
    }
    .mobileView {
      padding: 0;
      .alertMassage {
        margin-top: -17px;
        padding: 20px;
        background-color: #fff;
        border: 2px solid rgba(var(--main-color), 0.4);
        text-align: center;
        border-top: transparent;
      }

      // .emptyTraveling {
      //   border: 2px solid #c5c5c5;
      //   border-top: 0;
      // }

      .collapse_new_design {
        .ant-collapse-content {
          .ant-collapse-content-box {
            padding: 5px;

            .details_table {
              .deliveryDetailsTableMobile:not(:last-child) {
                border-bottom: 1px solid #bdbdbd;
                margin-bottom: 10px;
              }
            }
          }
        }

        .ant-collapse-header {
          padding: 5px 5px;
        }

        .ant-collapse-header-text {
          font-size: 14px;
        }
      }

      .mobileViewHeading {
        h1 {
          font-weight: 900;
          margin-bottom: 0;
        }

        h5 {
          margin: 10px 0px;
          font-weight: 900;
        }
      }

      .details_table_timeline {
        .timelineHeader {
          background-color: #c5c5c5;
          color: black;
          padding: 5px;

          span {
            font-size: 14px;
            padding: 5px 5px;
          }
        }

        .ant-timeline-label {
          // border: 2px solid #c5c5c5;
          border-top: 0;
          padding: 15px;
          background: #fff;

          .ant-timeline-item:last-child {
            padding-bottom: 0px;
          }

          .ant-timeline-item {
            font-size: 12px;
            padding-bottom: 30px;

            .ant-timeline-item-label {
              width: 100%;
              top: -2.001px;
              text-align: left;
              left: 15px;
            }

            .ant-timeline-item-tail {
              left: 0;
              border-left: 1px solid #ada7a7;
            }

            .ant-timeline-item-head {
              left: 0;
            }

            .ant-timeline-item-content {
              top: 20px;
              left: 20px;
              width: 100%;
              margin: 0;
              padding-right: 8px;

              @include mini-mobile {
                top: 15px;
                left: 15px;
              }
            }
          }
        }
      }

      .details_table {
        background: #ffffff;
        backdrop-filter: blur(5px);

        .multiCompborder:first-child {
          border-top: 0;
        }

        .multiCompborder {
          border-top: 1px solid #bdbdbd;
        }

        .ant-row-no-wrap {
          border: 0;
        }

        .form__input__textbox:nth-child(2) {
          text-align: right;
        }

        .ant-row-no-wrap {
          padding: 7px 6px;

          label {
            margin-right: 0px;
            font-size: 13px;
          }
        }

        .form__input__textbox {
          line-height: initial;

          .ant-image {
            margin: 0 5px;

            .kycFileUpload {
              padding: 5px;
            }
          }

          label,
          span,
          a {
            font-size: 13px;
            line-height: 19px;
          }
        }
      }
    }
  }

  .mobileprimaryModal {
    .ant-modal-content {
      .ant-modal-body {
        padding: 15px 15px 10px;

        .multiCompborder:first-child {
          margin-top: 0;
        }

        .multiCompborder {
          margin-top: 10px;
        }
      }
    }
  }
}


/*report css*/
.custom_page_btn {
  button {
    padding: 10px 20px;
    height: auto;
    border-radius: 5px;
    font-size: 16px;
    line-height: normal;
    min-width: 100px;
    margin-left: 0;
  }

  .cancelBtn {
    border-color: $primary_color;
    color: $primary_color;
    @include themeColorOpacity($primary, 0.1);
  }
}

.filterAreaSec {
  .ant-page-header-content {
    height: auto;
    padding: 40px 15px;
  }
}

.table-sec-sub-had {
  width: auto;
  text-align: left;
}

.listCountNew.listCountNewTwo {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.details_table {
  .ant-row.ant-row-no-wrap {
    .form__input__textbox_view_custom {
      label {
        width: auto;
        display: inline-block;
      }

      .ant-form.ant-form-horizontal {
        width: 300px;
        display: inline-block;
        margin-left: 10px;

        .ant-col {
          margin-bottom: 0;
        }
      }
    }
  }
}

tr.is_missing_booking.is_missing_booking {
  td {
    background-color: $red_100;
  }

  &:hover {
    td {
      background-color: $red_200;
    }
  }
}

.company-view-table {
  tr.is_missing_booking.is_missing_booking td {
    background-color: $red_100;
    font-size: 11px;

    span {
      color: $black;
    }
  }
}

/*report css*/
.custom_page_btn {
  button {
    padding: 10px 20px;
    height: auto;
    border-radius: 5px;
    font-size: 16px;
    line-height: normal;
    min-width: 100px;
    margin-left: 0;
  }

  .cancelBtn {
    border-color: $primary_color;
    color: $primary_color;
    @include themeColorOpacity($primary, 0.1);
  }
}

.filterAreaSec {
  .ant-page-header-content {
    height: auto;
    padding: 40px 15px;
  }
}

.table-sec-sub-had {
  width: auto;
  text-align: left;
  .infoWrap{
    display: inline-block;
    margin-right: 5px;
    svg {
      width: 22px;
      height: 22px;
      background-color: #c2c2c2;
      border-radius: 50%;
      padding: 2px;
      display: inline-flex;
      position: relative;
      top: 5px;
      margin-right: 6px;
    }
    strong {
      display: inline-block;
      margin-top: 5px;
    }
  }
}

.listCountNew.listCountNewTwo {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.details_table {
  .ant-row.ant-row-no-wrap {
    .form__input__textbox_view_custom {
      label {
        width: auto;
        display: inline-block;
      }

      .ant-form.ant-form-horizontal {
        width: 300px;
        display: inline-block;
        margin-left: 10px;

        .ant-col {
          margin-bottom: 0;
        }
      }
    }
  }
}

// tr.is_missing_booking.is_missing_booking td {
//   color: $blue_600;
// }

.addModal {
  .multi_select_height {
    .ant-select-selector {
      height: auto;
      padding: 5px;
      max-height: 250px;
      overflow-x: auto;
    }
  }
}

.container {
  max-width: 1240px;
  margin: 0 auto;

  .webTrackingTitle {
    text-align: center;
    border-radius: 0px 30px 0px 0px;
    background-color: $primary_color;
    padding: 15px;
    margin-bottom: 15px;

    h3 {
      color: $white_color;
      margin: 0;
      font-weight: 600;
    }
  }

  .mobileView {
    .alertMassage {
      margin-top: -17px;
      padding: 20px;
      background-color: #fff;
      border: 2px solid rgba(var(--main-color), 0.4);
      text-align: center;
      border-top: transparent;
    }
    .collapse_new_design {
      .details_table {
        .deliveryDetailsTableMobile {
          margin-top: 10px;

          &:nth-child(4) {
            margin-top: 0;

            .ant-row.ant-row-no-wrap {
              &:nth-child(1) {
                border-top: 0;
              }
            }
          }
        }
      }
    }
  }

  .details_table_timeline {
    .timelineHeader {
      background-color: #c5c5c5;
      color: black;
      padding: 5px;

      span {
        padding: 5px 5px;
      }
    }

    .ant-timeline-label {
      // border: 2px solid #c5c5c5;
      border-top: 0;
      padding: 15px;
      background: #fff;

      .ant-timeline-item-label {
        width: calc(14% - 18px);
      }

      .ant-timeline-item:last-child {
        padding-bottom: 0px;
      }

      .ant-timeline-item {
        padding-bottom: 30px;
      }
    }
  }
}

/* New Css added for DRS view*/
.custom-flex-input {
  display: flex;
  align-items: flex-start;
  margin-bottom: 0 !important;

  .ant-form-item {
    margin-right: 15px;
    min-width: 260px;
    margin-bottom: 0;
    flex-direction: column;

    .ant-form-item-label {
      text-align: left;
      margin-bottom: 3px;
    }
  }

  .ViewConsignmentAddBtn {
    margin-top: 19px;
  }
}

.drsSave {
  .ant-row {
    .ant-col {
      margin-bottom: 0 !important;

      label {
        padding-bottom: 5px;
      }

      .ant-form-item {
        .ant-row {
          display: block;
        }
      }
    }
  }
}

.details_table {
  .drsSave {
    .ant-row {
      &:first-child {
        border-top: 0;
      }

      .ant-col {
        margin-bottom: 0 !important;

        label {
          padding-bottom: 5px;
        }

        .ant-form-item {
          .ant-row {
            display: block;
          }
        }
      }
    }
  }
}

//Align item center sticker loader
.ant-spin-spinning[align="center"] {
  width: 100%;
}

/*New box added for draft*/
.box-inner-input-design {
  align-items: center;

  .ant-form-item {
    margin: 0;

    .ant-form-item-explain-error {
      font-size: 12px;
      color: #cb0002;
    }
  }

  .ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number {
    background: rgba(255, 255, 255, 0.2);
  }

  .ant-input-number-in-form-item {
    background: rgba(255, 255, 255, 0.2);
    border: 1px solid #ef9c2d;
    border-radius: 6px;

    .ant-input-number-input-wrap {
      .ant-input-number-input {
        height: 35px;
        color: #fff;

        &::placeholder {
          /* Chrome, Firefox, Opera, Safari 10.1+ */
          color: #fff;
          opacity: 1;
          /* Firefox */
        }

        &:-ms-input-placeholder {
          /* Internet Explorer 10-11 */
          color: #fff;
        }

        &::-ms-input-placeholder {
          /* Microsoft Edge */
          color: #fff;
        }
      }
    }
  }

  button.ant-btn.ant-btn-default {
    height: 35px;
    background: rgb(244, 113, 125);
    border: 1px solid rgb(201, 82, 92);
    border-radius: 6px;
    margin-left: 10px;
    color: #fff;
    transition: 0.5s;

    &:hover {
      background: rgb(201, 82, 92);
    }
  }
}

.ag-only-text {
  span {
    font-size: 13px;
    color: #616161;
  }

  &:hover {
    transform: scale(1);
  }
}

.TableTransfer_custom_design {
  .ant-transfer-list {
    padding: 10px 0px 0 10px;
    width: calc(50% - 23px);

    .ant-transfer-list-body-search-wrapper {
      padding: 12px 12px 12px 0px;
    }

    .ant-transfer-list-header {
      padding: 8px 12px 9px 0
    }
  }

  .ant-transfer-operation {
    .ant-btn {
      min-width: inherit;

      span.anticon {
        svg {
          fill: $white_color;
        }
      }

      &[disabled] {
        span.anticon {
          svg {
            fill: #a2a2a2;
            ;
          }
        }
      }
    }
  }

  .ant-table-wrapper {
    .ant-table-small {
      .ant-table-selection-column {
        width: 100px;
        min-width: 100px;
        padding: 10px 0 4px;

        .ant-checkbox {
          input {
            width: 25px;
            height: 25px;
          }
        }

        .ant-checkbox-inner {
          width: 25px;
          height: 25px;
        }
      }
    }
  }
}

.copyaccountpartyQuotat {
  .ant-page-header-content {
    height: auto;
    padding: 15px 15px;
    position: relative;
    min-height: calc(100% - 80px);
  }
}

.account_bk_nots {
  margin-top: -15px;
  margin-bottom: 15px;

  strong {
    color: $danger_color;
  }
}

.downloadHelpVideos {
  table {
    border: 1px solid $black_300;
    width: 100%;

    tr {
      border-bottom: 1px solid $black_300;
      line-height: 2.5;

      &:last-child {
        border-bottom: transparent;
      }

      td {
        // width: 50%;
        text-align: center;

        &:first-child {
          border-right: 1px solid $black_300;
          text-align: left;
          padding-left: 15px;
        }

        &:last-child {
          width: 150px;
        }
      }
    }
  }
}

.form-item-remove-margin {
  .ant-form-item {
    margin-bottom: 0;
  }
}

.disabled-field-text-bold {
  .ant-input[disabled] {
    color: $input_label_color;
  }

  .ant-select-disabled {
    .ant-select-selection-item {
      color: $input_label_color;
    }
  }

  .ant-picker-disabled {
    .ant-picker-input {
      input {
        color: $input_label_color;
      }
    }
  }
}

//consignmentTracking heading design change
.consignmentTracking {
  h3 {
    position: relative;
    margin-bottom: 20px;
    padding: 3px 0;
    font-weight: 700;
    color: $primary_color;

    &::before {
      content: "";
      position: absolute;
      left: 50px;
      bottom: 0;
      width: 70px;
      height: 100%;
      border-bottom: 2px solid $black_700;
    }
  }
}

.statusModal.ant-drawer {

  .ant-drawer-footer {
    border-top: 0px solid $black_300;
    padding: 20px;
    text-align: center;
    background-color: $popup_footer_bg;

    button {
      padding: 10px 20px;
      height: auto;
      border-radius: 5px;
      font-size: 16px;
      line-height: normal;
      min-width: 100px;
      margin-left: 0;
    }

    .cancelBtn {
      border-color: $yellow_500;
      color: $yellow_500;
      @include themeColorOpacity($yellow_500, 0.1);
    }

    .ant-btn-primary {
      padding: 10px 14px;
      height: auto;
      line-height: 16px;
      border-radius: 5px;
      font-size: 14px;
      border-color: $yellow_500;
      margin-left: 15px;
      background-color: $yellow_500;

      &[disabled] {
        color: rgba(0, 0, 0, 0.25);
        border-color: #d9d9d9;
        background: #f5f5f5;

        &:hover {
          color: rgba(0, 0, 0, 0.25);
          border-color: #d9d9d9;
          background: #f5f5f5;
        }
      }

      svg {
        margin-right: 10px;
      }

      &:hover {
        background-color: $yellow_500;
        color: $white_color;
      }
    }

    .statusModal_danger_bg {
      background-color: $danger_color;
      border-color: $danger_color;

      &:hover {
        background-color: $danger_color;
        border-color: $danger_color;
      }
    }
  }

  .ant-drawer-body {
    h3 {
      color: $input_label_color;

      span {
        color: $black_color;

        svg {
          color: $yellow_500;
          margin-right: 10px;
          font-size: 22px;
        }
      }
    }
  }
}

#drsDeliveryForm {
  .insu_table {
    width: 100%;
  }
}

.forwardInnerForm {
  .ant-divider-horizontal {
    margin: 15px 0 5px;
  }
}

.btn-with-text-drs {
  align-items: center;

  p {
    color: $primary_color;
    margin: 0;
  }
}

.defaultdatefliter {
  max-width: 450px;
  display: inline-flex;
  align-items: center;

  .ant-form-item {
    margin-bottom: 0;
  }

  .ant-picker {
    height: 32px;
  }
}

.customCheckBoxGridDesignform {
  height: 100%;

  .customgriddesign {
    position: relative;
    height: 100%;

    .ant-row {
      height: 100%;
    }

    .customCheckBoxGridDesign {
      position: absolute;
      top: -50px;
    }
  }
}

.Export_drpdwn {
  height: 32px;
  border-radius: 5px !important;
  text-align: left;
  font-size: 12px;
  background-color: $white_color;
  border: 1px solid #d0d0d0;
  padding: 0 20px;
  display: inline-block;
  line-height: 32px;
}

.custom_listCountNew {
  .listCountNew {
    padding: 0 17px 0 0;

    .ant-row.ant-form-item {
      align-items: center;
      margin: 0;
    }

    .rc-virtual-list-holder-inner {
      text-align: left;
    }

    .header-input-live {
      display: inline-flex;
      align-items: center;

      .ant-select-selector {
        min-width: 300px;
      }

      .ant-btn {
        margin-left: 40px;
      }
    }
  }
}

.cashBookingForm.AccountBookingForm {
  .ConsignmentAddBtn {
    height: 30px;
    line-height: 30px;
    padding: 0 0;
    font-size: 1.1vw;
    margin-top: 4px;
    min-width: inherit;
    width: 100%;
  }
}
.view_priv_icon{
    width: 40px;
    height: 40px;
    display: inline-block;
}

.lockDrs {
  .ant-page-header-content {
    height: auto;

    .ant-form-item {
      margin-bottom: 0;
    }
  }

  .insurance_table {
    overflow-y: auto;
    height: calc(100dvh - 300px);
  }
}
#relaxationForm{
  .ant-row{
    align-items: center;
  }
}
.popup-msg-custom{
  table{
    width: 100% !important;
  }
  div{
    word-break: break-word !important;
    white-space: break-spaces;
  }
}


// set eway table view
.ewayTranshit{
	.ant-table-tbody{
	  td{
		padding: 5px;
	  }
	  span.ant-checkbox {
		top: 4px;
		position: relative;
		.ant-checkbox-inner{
		  width: 20px;
		  height: 20px;
		  &:after{
			width: 7px;
			height: 15px;
			left: 15%;
			top: 45%;
		  }
		}
	 }
	}
	.ant-table-thead{
	  th{
		padding: 5px;
	  }
	  span.ant-checkbox {
		top: 4px;
		position: relative;
		.ant-checkbox-inner{
		  width: 20px;
		  height: 20px;
		  &:after{
			left: 15%;
		  }
		}
	  }
	  span.ant-checkbox-indeterminate{
		.ant-checkbox-inner{
		  &:after{
			left: 50%;
		  }
		}
	  }
	}
  }
  .ewayTranshitForm{
	.cancelBtn{
	  border-radius: 5px;
	  padding: 10px 20px;
	  font-size: 16px;
	  height: auto;
	  line-height: 16px;
	  min-width: 100px;
	}
	.ant-row{
	  align-items: center;
	}
  }

.child_booking_table{
  .ant-input, .ant-select .ant-select-selector, .ant-select-single:not(.ant-select-customize-input) .ant-select-selector, .ant-input-number, .ant-select:not(.ant-select-customize-input) .ant-select-selector, .ant-picker{
    padding-left: 5px;
    padding-right: 5px;
  }
  .ant-form-item{
    margin-bottom: 0;
  }
  thead{
    th{
      padding: 5px;
      div{
        font-size: 12px;
      }
    }
  }
}
.cashBookingForm{
  .customgriddesignrowProduct{
    .ant-col{
      width: auto !important;
      flex-basis: inherit !important;
      background-color: $primary_color;
      color: $white_color;
      font-size: 12px;
      border-radius: 5px;
      margin: 5px 2px;
      border: 1px solid transparent;
      // animation: blinker 4s linear infinite;
    }
    // @keyframes blinker {
    //   70% {
    //     border: 1px solid $primary_color;
    //     background-color: $white_color;
    //     color: $black_800;
    //   }
    // }
  }
}
.DataSyncDesign{
  .billingSearch {
    .DownloadCardBox{
      .ant-card-body{
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .searchCardBox {
      .ant-card-body{
        .ant-form-item{
          margin-bottom: 0;
        }
      }
    }
  }
  .import_table {
    width: 100%;
  }
  .import_total_sec{
    margin: 0;
  }
}
.download_sample_sec {
	text-align: center;
	&.ant-btn {
		padding: 0;
		height: 40px;
		border-color: $primary_color;
		color: $primary_color;
		display: flex;
		align-items: center;
		justify-content: center;
		max-width: 180px;
		margin: 0 auto;
		border-radius: 32px;
    background-color: transparent;
    min-width: 144px;
		span.anticon {
			background-color: $primary_color;
			border-radius: 50%;
			color: $white_color;
			font-size: 20px;
			padding: 0;
			height: 32px;
			width: 32px;
			display: flex;
			align-items: center;
			justify-content: center;
		}
		span:nth-child(2) {
			margin-left: 10px;
      margin-right: 10px;
		}
	}  
  &.ant-btn-loading{
    .anticon-download{
      display: none !important;
    }
  }
}

.InternationalBooking{
  .twoform{
    .loader-center{
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 180px;
    }
    .customgriddesign{      
      border-right: 1px solid rgba(0, 0, 0, 0.06);
      flex: 0 0 70%;
      width: 100%;
      padding: 0px 10px 0 0px;
      .insurance_table{
        margin-top: 0;
        width: 100%;
        padding: 0 10px;
      }
    }
    .rightForm{
      border: 0;
      width: 100%;
      flex: 0 0 30%;
      padding: 0px 10px 0 0px;
      .card.card-transaction{
        border: 1px solid $primary_color_4;
        border-radius: 6px;
        margin-bottom: 10px;
        .ant-card-body{
          padding: 5px 15px;
        }
      }
      &.card-transaction-edit{
        background: #ededed;
        border-color: #c2c2c2;
      }
    }
  }
  .intBookingform{
    .customgriddesignrow{
      &>.ant-col {
        flex-basis: 20%;
        width: 20%;
      }
      &>.customgriddesigncolBig.col-lg {
        flex-basis: 30%;
        width: 30%;
      }
      &>.customgriddesigncolBig {
        flex-basis: 40%;
        width: 40%;
      }
    }
    .ant-divider-horizontal {
      margin: 20px 0 1px;
    }
  }
  .col_wrap_text{
      border: 2px solid $primary_color_4;
      margin: 0 !important;
      align-items: center;
    .col_wrap {
      padding: 1em;
      width: 100%;
      border-bottom: 0;
      .number {
        text-align: center;
        color: rgba($black, 0.9);
      }
      .sub_text{
        text-align: center;
        color: $primary_color;
        p{
          margin-bottom: 0;
        }
      }
  }
  }
  .custom-tabs{
    padding: 15px;
    border: 2px solid $primary_color_4;
    margin: 0 !important;
    &>.ant-space{
      width: 100%;
      border: 1px solid rgba($black, 0.4);
      margin-bottom: 16px;
      padding: 5px;
      border-radius: 0;
      display: inline-block;
      &>.ant-space-item{
      width: 100%;
      &>.ant-space{
        padding-left: 30px;
        margin-bottom: 10px;
        display: flex;
        align-items: stretch;
        padding-right: 30px;
        .ant-space-item{
          width: 290px;
          .ant-form-item-explain-error{
            font-size: 11px;
          }
          &:nth-child(2){
            flex-shrink: 0;
          }
          &:nth-child(3){
            width: 0px;
          }
          
          .ant-input, .ant-select .ant-select-selector, .ant-select-single:not(.ant-select-customize-input) .ant-select-selector, .ant-input-number, .ant-select:not(.ant-select-customize-input) .ant-select-selector, .ant-picker{
            padding: 0 5px;
            .ant-select-selection-search{
              left: 5px;
            }
          }
        }
        &.hidden-ant-space-item{
          .ant-space-item{
            &:nth-child(6),&:nth-child(7){
              width: 0px;
            }
          }
        }
      }
    }
    }
    >.BoxItem{
      &>.ant-space-item:nth-child(1), >.ant-space-item:nth-child(2){
        width: 50%;
        display: inline-block;
        padding: 0 10px;
        margin-bottom: 7px;
      }
      &.hidden-ant-space-item{
        .ant-space-item{
          &:nth-child(6), &:nth-child(7){
            width: 0px;
          }
        }
      }
    }
  }
  .CashBookingBtnCenter{
    margin-top: 20px !important;
  }
  .ant-form-item.international-image-upload {
    border: 1px solid #c2c2c2;
    padding: 5px 5px 1px 5px;
    border-radius: 5px;
    margin-top: 8px;
    >.ant-row {
      justify-content: space-between;
      align-items: center;  
      .ant-form-item-label {
        padding: 0;
        margin-bottom: 0px;
        margin-left: 0;
        max-width: inherit;
        flex: auto;
        width: 110px;
      }
      .ant-form-item-control{
        position: inherit;
        .ant-form-item-explain{
          position: absolute;
          left: 17px;
          top: 87%;
        }
      }
      .upload_file {
        span {
          margin-left: 5px;
        }
      }
      .ant-upload-list{
        .ant-upload-list-picture-container{
          margin-top: 10px;
          .ant-upload-list-item {
            width: 100%;
            margin-top: 0;
            padding: 6px;
            height: 56px;
          }
        }
      }
    }
  }
  
  .ant-input-number-handler-wrap {
    background-color: transparent;
    display: none;
}
.resetBtn{
    background-color: transparent;
    border-color: $primary_color;
    transition: all 0.5s ease;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 16px;
    height: auto;
    line-height: 16px;
    min-width: 100px;
    color:  $primary_color;
    &:hover,
    &:focus {
      background-color: $primary_color;
      border-color: $primary_color;
      transform: scale(0.94);
      color: $white_color;
    }
    &[disabled] {
      &:hover,
      &:focus {
        transform: scale(1);
      }
    }
    &.borderBtn {
      background-color: transparent;
      color: $primary_color;
    }
}
.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector{
  color: $black
}
&.ant-modal{
  .custom-tabs{
    &>.ant-space{
      &>.ant-space-item{
      &>.ant-space{
        .ant-space-item{         
          &:last-child{
            width: 100px;
          }
        }
      }
    }
    }
  }
}
}
.card-transaction{
  .transaction-item{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 10px;
    .transaction-item-img{
      max-width: 100px;
      max-height: 35px;
      min-width: 100px;
      padding: 0 10px;
    }
    .transaction-percentage{
      padding-left: 10px;
      margin-top: 6px;
      h6{
        font-size: 16px;
        font-weight: 600 ;
        margin-bottom: 0;
        line-height: 1;
      }
    }
    .text-primary{
      display: flex;
      align-items: center;
      &>div{
        width: 120px;
      }
      span{
        width: 100%;
        display: inline-block;
        color: $primary_color;
      }
      small{
        background-color: rgba(130, 134, 139, 0.12);
        color: #82868b !important;
        padding: 0.25em 0.4em;
        border-radius: 0.25rem;
      }
      .anticon.anticon-info-circle {
        flex: 1;
      }
    }    
  }

}
.RevalidateIcon{
  svg{
    fill: $pink_800;
    font-size: 14px;
    padding: 3px 0 0 0!important;
    width: 17px;
    height: 17px;
  }
  &.mailBoxIcon{
    svg{
      fill:  #d72ddf;
      width: 20px;
      height: 20px;
    }
  }
}
.drawerInList{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 30px;
  margin-bottom: 10px;
}
.accountPartySearch.ant-page-header-ghost{ 
  height: auto;
  .ant-tabs-content-holder{ 
    .ant-tabs-tabpane{ 
      padding: 0px;
    }
  }
  .ant-tabs-nav{
    max-height: 550px;
    overflow-x:hidden;
    width: 400px;
  } 
  .ant-tabs-tab-btn{
    word-break: break-all;
    white-space: normal;
    text-align: left;
  }  
  .ant-tabs-tab{    
    border: 2px solid $primary_color;
    padding: 8px;
    border-top-right-radius : 5px;
    &:first-child{
      margin: 0;
    }
  }
  .ant-tabs-nav-list{
    padding: 12px 5px;
  
  }
  .collapse_new_design{
    padding: 5px;
  }
  .ant-row.ant-row-no-wrap{
    label{
      display: initial;
    }
    span {
      word-break: break-all;
    }
  }
  .custom-tabs{
    .ant-row{
      border-top: 1px solid rgba(0, 0, 0, 0.2);
      padding-top: 5px;
      &:first-child{
        border-top: 0;
      }
    }
  }
  form{
    .ant-col{
      margin: 0px 0;}
  }
  .ant-col{
    margin: 10px 0;
    .ant-form-item {
      margin-bottom: 0px;
   }
   .custom_page_btn {
    margin-top: 15px;
  }
  }
}
.full-width-icon{
  flex-direction:column;
  .ant-btn.ant-btn-text{
    width: auto;
    text-align: left;
    margin: 0;
    span{
      padding-left: 5px;
    }
  }
}
.printImgCol{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 22px;
  .printBtn {
    background-color: initial;
    border: 1px solid rgb(241,106,36);
    margin-left: 10px;
    padding: 5px 8px 0px 8px;
    transition: all .5s linear;
    height: auto;
    svg {
      height: 25px;
      width: 25px;
      color: #f16a24;
  }
  }
}
.full-width-icon{
  flex-direction:column;
  .ant-btn.ant-btn-text{
    width: auto;
    text-align: left;
    margin: 0;
    svg {
      width: 1.2em;
    }
    span{
      padding-left: 5px;
    }
  }
}
.InternationalBookingsMain, .EditBookingHoApprove{
  .ag-cell{    
    .ag-react-container{
      span{
        svg{
          top: 6px;
          position: relative;
        }
        &.hoapproveIcon{
          svg{
            top: 2px;
            position: relative;
          }
        }
      }     
    }
  }  
}
.BookingMessageFilterForm{
  text-align: left;
  .ant-form-item-control-input{
    min-width: 200px;
  }
  .ant-form-item {
    margin-bottom: 0;
    margin-right: 30px;
  }
  >.ant-row{
    margin-right: 10px !important;
  }
  .sendButton {
    .ant-btn {
      margin-left: 0 !important;
    }
  }

}

.IntTodayBookingBox{
  display: flex;
  background: #ededed;
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 20px;
  .TodayBookingItem{
    display: flex;
    flex: 1 1;
    align-items: center;
    justify-content: center;
    h4{
      margin: 0 10px 0 0;
    }
  }
}



.unregpacketfilterinput{
  .ant-input, .ant-select .ant-select-selector, .ant-select-single:not(.ant-select-customize-input) .ant-select-selector, .ant-input-number, .ant-select:not(.ant-select-customize-input) .ant-select-selector, .ant-picker{
    height: 40px;
  }
  .ant-input .ant-select-selection-search-input, .ant-select:not(.ant-select-multiple) .ant-select-selector .ant-select-selection-search-input, .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input, .ant-input-number .ant-select-selection-search-input{
    height: 40px;
  }
  .ant-select-single .ant-select-selector .ant-select-selection-item, .ant-select-single .ant-select-selector .ant-select-selection-placeholder{
    line-height: 40px;
  }
    .multi_select_height {
      .ant-select-selector {
        height: auto !important;
        padding: 5px;
        max-height: 150px;
        overflow-x: auto;
      }
    }
  }
.int_transportation_table{
  .ant-form-item{
    .ant-form-item-label{
      padding: 0 0 2px;
      label {
        font-size: 11px;
      }
    }
    .ant-input, .ant-select .ant-select-selector, .ant-select-single:not(.ant-select-customize-input) .ant-select-selector, .ant-input-number, .ant-select:not(.ant-select-customize-input) .ant-select-selector, .ant-picker{
      padding: 0 5px;
    }
  }
}

.customgriddesignInvoices{
  margin-top: -4px;
  margin-right: 20px;
}
.tabletwolistview{
  .ant-page-header-content {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .listCountNew{
      width: 50%;
      order: 1;
      &:nth-child(1){
        order: 2;
      }
      .ant-form-item{
        .ant-select-selector{
          min-width: 180px
        }
      }
    }
    .ag-theme-alpine.grid_wrapper.undefined {
      width: 100%;
      order: 3;
    }
  }
}
.addDrawerItemMarginRemove{
  .ant-drawer-content {
    .ant-form-item {
      margin-bottom: 0;
    }
  }
}
.addVirtualStickerDrawer{
  .grid_wrapper {
    height: calc(100% - 155px);
  }
}
.details_table_timeline_complaints{
  .ant-timeline-item-content{
    span{
      width: 100%;
      display: inline-block;
    }
  }
}
.UpdateBookingView{
  strong.UpdateBookingHad {
    background: #efefef;
    width: 100%;
    display: inline-block;
    font-size: 16px;
    text-align: left;
    padding: 10px;
    border-left: 1px solid #bdbdbd;
    border-right: 1px solid #bdbdbd;
}
  .ant-col {    
    border-top: 1px solid $black_400;
  }
  .form__input__textbox {
    border: 1px solid $black_400;
    border-top: 0;
    padding: 10px 10px;
  }
  .form__input__selectBox_three{
    display: flex;
    gap: 15px;
  }
}
.DisturbPincodeRow{
  h4{
    color: $danger_color;
    margin-left: 10px;
    padding: 5px;
    font-weight: 700;
    position: relative;
    overflow: hidden;
    font-size: 16px;
    span {
      position: absolute;
      display: block;
    }
    span:nth-child(1) {
      top: 0;
      left: -100%;
      width: 100%;
      height: 2px;
      background: linear-gradient(90deg, transparent, $danger_color);
      animation: btn-anim1 1s linear infinite;
    }
    @keyframes btn-anim1 {
      0% {
        left: -100%;
      }
      50%,
      100% {
        left: 100%;
      }
    }
    span:nth-child(2) {
      top: -100%;
      right: 0;
      width: 2px;
      height: 100%;
      background: linear-gradient(180deg, transparent, $danger_color);
      animation: btn-anim2 1s linear infinite;
      animation-delay: 0.25s;
    }
    @keyframes btn-anim2 {
      0% {
        top: -100%;
      }
      50%,
      100% {
        top: 100%;
      }
    }
    span:nth-child(3) {
      bottom: 0;
      right: -100%;
      width: 100%;
      height: 2px;
      background: linear-gradient(270deg, transparent, $danger_color);
      animation: btn-anim3 1s linear infinite;
      animation-delay: 0.5s;
    }
    @keyframes btn-anim3 {
      0% {
        right: -100%;
    }
      50%,
      100% {
        right: 100%;
      }
    }
    span:nth-child(4) {
      bottom: -100%;
      left: 0;
      width: 2px;
      height: 100%;
      background: linear-gradient(360deg, transparent, $danger_color);
      animation: btn-anim4 1s linear infinite;
      animation-delay: 0.75s;
    }
    @keyframes btn-anim4 {
      0% {
        bottom: -100%;
      }
      50%,
      100% {
        bottom: 100%;
      }
    }
  }
}
.incomingSearchPOD{
  margin-top: 10px !important;
  margin-left: -3px !important;
  margin-right: -3px !important;
  .form__input__textbox{
    label{
      width: 100%;
      display: inline-block;
      padding-bottom: 3px;
    }
  }
}

.ant-btn.AddManualCredit,
.AddManualCredit {
  color: $edit_icon_color;
  font-size: 14px;
  padding: 0 !important;
  width: 22px;
  height: 22px;
  // top: 7px;
  svg, path{
    fill: $edit_icon_color;
  }
}

.staff_attendance_form{
  .ant-btn-primary{
    height: 35px;
    margin-top: 23px;
  }
  .insurance_table{
    .insu_table{
      table{
        margin-top: 0 !important;
        tr{
          th{
            border-bottom: 1px solid $input_border;
            border-left: 1px solid $input_border;
            padding: 6px 0 0;        
          }
          td{
            padding: 4px 4px;
            text-align: center;
            vertical-align: bottom;
            >label{
              height: 20px;
              padding-top: 8px;          
            }
            .ant-form-item-control-input-content{
              text-align: center;
              align-items: center;
              justify-content: center;
              display: flex;
              > label{
                height: 20px;
              }
            }
            .ant-form-item-control-input{
              min-height: 20px;
            }
            .ant-checkbox-inner {
              width: 20px;
              height: 20px;
            &:after {
                width: 7px;
                height: 14px;
                left: 16%;
                top: 40%;
            }
            }
          }
        }
      }
     
    }
  }
  .bottom-submit-btn{
    .ant-btn-primary{
      height: inherit;
      margin-top: 0;
    }
  }
}
.highlight-row-Inscan{
  background: green;
  .ant-table-cell, .ant-table-cell.ant-table-cell-row-hover{
    background: green !important;
    color: #fff;
    span{
      color: #fff;
    } 
  }
}
.salary_processing_form{
  .insurance_table{
    overflow: auto;
    .insu_table{
      min-width: 1800px;
      table{
        tr{
          td{
            .ant-form-item{
                max-width: 100px;
                margin: 0 auto;
            }
          }
        }
      }
    }
  }
}
.custom-tabs-custom-rate-card{
  .ant-tabs-tabpane{
    overflow: auto
  }
  .rate__types__label {    
    flex-wrap: nowrap;
    .ant-col {
      width: 200px;
      min-width: 200px;
      display: flex;
      align-items: center;
    }
  }
  .custom__tabs__item__col {    
    flex-wrap: nowrap;
    .ant-space-item {
      width: 200px;
      min-width: 200px;
      &:last-child{
        min-width: auto;
      }
    }
  }
}
.wallet-bal-show{
  margin-bottom: 5px;
  h3{
    background: $ant_switch_color;
    margin: 0 4px 0 0px;
    color: $white_color;
    padding: 3px 10px 0;
    border-radius: 5px;
  }}
  
.client-message-list-Tooltip{
  max-width: 800px;
  .ant-tooltip-inner{
    overflow: auto;
    max-height: 250px;
  }
}

.Birthday_Wishes_Card{
	position: relative;
	.ant-modal-content{
		background-color:transparent;
		box-shadow: none;
		border-radius: 0;
		max-height: inherit;
    overflow: visible;
    height: 100%;
		.ant-modal-body{
			width: 100%;
			height: 100%;
			position: relative;
			max-height: 100%;
      .navLogo{
        padding: 10px 20px;
        border-radius: 8px;
        width: auto;
        margin: 0 auto;
        max-width: max-content;
        height: auto;
        img {
          max-height: 80px;
        }
      }
		}
		.ant-modal-close{
			background-color: $white_color;
			width: 30px;
			height: 30px;
			border-radius: 50%;
      right: -15px;
      top: -15px;
      .ant-modal-close-x{
        line-height: normal;
        height: auto;
        position: static;
      }
		}
	}
}
.Birthday_Wishes_Card .mobile {
	position: fixed;
	text-align: center;
	width: 100%;
	top: 50px;
	font-size: 90px;
	display: block;
  }
  
  .Birthday_Wishes_Card h1, .Birthday_Wishes_Card h2, .Birthday_Wishes_Card span {
	display: none;
  }
  
  @media screen and (min-width: 670px) {
	
	.Birthday_Wishes_Card .mobile {
	  display: none;
	}
	
	.Birthday_Wishes_Card h1, .Birthday_Wishes_Card h2, .Birthday_Wishes_Card span {
	display: block;
  }
   $colors: #ef8d22, #feff28, #2bd8ff, #fc85e1;
  $particles: 50;
  $width: 500;
  $height: 500;
  
  // Create the explosion...
  $box-shadow: ();
  $box-shadow2: ();
  @for $i from 0 through $particles {
	$box-shadow: $box-shadow,
				 random($width)-$width / 2 + px
				 random($height)-$height / 1.2 + px
				 hsl(random(360), 100, 50);
	$box-shadow2: $box-shadow2, 0 0 #fff
  }
  @mixin keyframes ($animationName) {
	  @-webkit-keyframes #{$animationName} {
		  @content;
	  }
  
	  @-moz-keyframes #{$animationName} {
		  @content;
	  }
  
	  @-o-keyframes #{$animationName} {
		  @content;
	  }
  
	  @-ms-keyframes #{$animationName} {
		  @content;
	  }
  
	  @keyframes #{$animationName} {
		  @content;
	  }
  }
  
  @mixin animation-delay ($settings) {
	  -moz-animation-delay: $settings;
	  -webkit-animation-delay: $settings;
	  -o-animation-delay: $settings;
	  -ms-animation-delay: $settings;
	  animation-delay: $settings;
  }
  
  @mixin animation-duration ($settings) {
	  -moz-animation-duration: $settings;
	  -webkit-animation-duration: $settings;
	  -o-animation-duration: $settings;
	  -ms-animation-duration: $settings;
	  animation-duration: $settings;
  }
  
  @mixin animation ($settings) {
	  -moz-animation: $settings;
	  -webkit-animation: $settings;
	  -o-animation: $settings;
	  -ms-animation: $settings;
	  animation: $settings;
  }
  
  @mixin transform ($settings) {
	  transform: $settings;
	  -moz-transform: $settings;
	  -webkit-transform: $settings;
	  -o-transform: $settings;
	  -ms-transform: $settings;
  }
  
  .Birthday_Wishes_Card {
   background: linear-gradient(to right, #e2b8f7, #d4bafa, #c7bcfb, #b9befb, #acbff9);
   cursor: crosshair;
   perspective: 1000px;
   transform-style: preserve-3d;
   font-family: "Pacifico",cursive;
   border-radius: 15px;
   height: 80%;
   min-height: 740px;
   max-height: 740px;
  }
  
  .Birthday_Wishes_Card h1 {
	position: fixed;
	text-align: center;
	width: 100%;
	top: 120px;
	font-size: 90px;
	background: -webkit-linear-gradient(0deg, rgba(206,173,252,1) 0%, rgba(163,187,251,1) 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	font-family: 'Concert One', cursive;
	font-weight: 400;
	z-index: -1;
	letter-spacing: 6px;
  }
  
  .Birthday_Wishes_Card span {
    position: absolute;
    text-align: center;
    width: auto;
    top: 138px;
    font-size: 70px;
    right: 35%;
  }
  
  .Birthday_Wishes_Card h2 {  
	text-align: center;
	width: 100%;
	font-size: 90px;
	background: -webkit-linear-gradient(90deg, rgba(233,230,255,1) 0%, rgba(255,255,255,1) 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
  margin: 0;
  line-height: 160px;
  padding-top: 60px;
  
  }
  
.Birthday_Wishes_Card h3 {  
  text-align: right;
  width: 100%;
  font-size: 40px;
  background: -webkit-linear-gradient(90deg, #e9e6ff 0%, white 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 0;
  line-height: 80px;
  padding-right: 80px;
  padding-top: 0;
}
  bokeh {
	position: absolute;
	width: 2vmin;
	height: 2vmin;
	border-radius: 50%;
	animation-name: explosion;
	animation-iteration-count: infinite;
	animation-direction: reverse;
	animation-timing-function: cubic-bezier(0.84, 0.02, 1, 1);
	@for $i from 1 through 100 {
	  &:nth-child(#{$i}) {
		$color: ceil(random() * 4);
		background-color: nth($colors, $color);
		$x: random() * 700px;
		$y: random() * 600px;
		transform: translate($x, $y);
		animation-duration: random() * 3s + 2s;
		animation-delay: random() * -5s;
	  }
	}
  }
  @keyframes explosion {
	0% {
	  opacity: 0;
	}
	70% {
	  opacity: 1;
	}
	100% {
	  transform: translate(50%, 700px);
	}
  }
  
  
  
  .cake {
	position: relative;
  bottom: -1%;
	margin: auto;
	width: 200px;
	height: 60px;
	background: #f9fdff;
	border-radius: 100%;
	transform: translateZ(100px);
	z-index: 1;
	
  
	box-shadow: 
	  0px 4px 0px #f4f9fd, 
	  0px 8px 0px #dba9ff, 
	  0px 12px 0px #fec3b3, 
	  
	  0px 16px 0px #f7f6fb, 
	  0px 20px 0px #f7f6fb, 
	  0px 24px 0px #f7f6fb,
	  0px 28px 0px #f7f6fb,
	  0px 32px 0px #fea0bb,
	  0px 36px 0px #fea0bb,
	  0px 40px 0px #9cef9d,
	  0px 44px 0px #9cef9d, 
	  
	  0px 48px 0px #f7f6fb, 
	  0px 52px 0px #f7f6fb, 
	  0px 56px 0px #f7f6fb, 
	  0px 60px 0px #f7f6fb, 
	  0px 64px 0px #f7f6fb, 
	  0px 68px 0px #dfa5fc, 
	  0px 72px 0px #dfa5fc, 
	  0px 76px 0px #fafffe, 
	  0px 80px 0px #fafffe;
  }
  
  .plate {
    position: relative;
    height: 90px;
    width: 300px;
    bottom: -12px;
    left: 50%;
    margin-left: -150px;
    border-radius: 100%;
	background: radial-gradient(ellipse closest-side at center, rgba(8,199,254,1) 0%, rgba(4,215,242,1) 71%, rgba(2,255,208,1) 100%);
	box-shadow: 
	  0px 3px 0px #00e2e1,
	  0px 6px 0px #00d3fb;
	transform: translateZ(80px);
  }
  
  .candle {
    position: relative;
    height: 50px;
    width: 12px;
    bottom:-6.1%;
    margin: auto;
    background: linear-gradient(0deg, #b7f4a7 0%, white 100%);
    border-radius: 4px;
    transform: translateZ(120px);
    z-index: 9;
  }
  
  #flame {
	 position: absolute;
	 z-index: 10; 
  }
  
  .lit {
	 background: linear-gradient(to bottom, #FFF6D9, #FBC36C);
	width: 15px;
	height: 35px;
	
	
  /*  Info on border radius. http://www.css3.info/preview/rounded-border/ */
	  
	border-top-left-radius: 10px 35px;    
	border-top-right-radius:  10px 35px;
	border-bottom-right-radius: 10px 10px;
	border-bottom-left-radius: 10px 10px;
	top: -34px;
	margin: auto;
	
  /*   http://www.css3.info/preview/box-shadow/ */
	box-shadow: 
	  0 0 17px 7px rgba(251, 246, 190, 0.71); 
	transform-origin: bottom;
	animation: flicker 1s ease-in-out alternate infinite;
  }
  
  @keyframes flicker {
	0% {
	  transform: skewX(5deg);
	  box-shadow: 
	  0 0 17px 10px rgba(251, 246, 190, 0.71);
	}
	
	25% {
	  transform: skewX(-5deg);
	  box-shadow: 
	  0 0 17px 5px rgba(251, 246, 190, 0.71);
	}
	
	50% {
	  transform: skewX(10deg);
	  box-shadow: 
	  0 0 17px 7px rgba(251, 246, 190, 0.71);
	}
	
	75% {
	  transform: skewX(-10deg);
	  box-shadow: 
	  0 0 17px 5px rgba(251, 246, 190, 0.71);
	}
	
	100% {
	  transform: skewX(5deg);
	  box-shadow: 
	  0 0 17px 10px rgba(251, 246, 190, 0.71);
	}
  }
  
  .pyro > .before, .pyro > .after {
	position: fixed;
	width: 5px;
	height: 5px;
	border-radius: 50%;
	box-shadow: $box-shadow2;
	@include animation((1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards));
  }
	  
  .pyro > .after {
	@include animation-delay((1.25s, 1.25s, 1.25s));
	@include animation-duration((1.25s, 1.25s, 6.25s));
  }
		  
  @include keyframes(bang) {
	to {
	  box-shadow:$box-shadow;
	}
  }
	  
  @include keyframes(gravity)  {
	to {
	  @include transform(translateY(200px));
	  opacity: 0;
	}
  }
	  
  @include keyframes(position) {
	0%, 19.9% {
	  margin-top: 10%;
	  margin-left: 40%;
	}
	20%, 39.9% {
	  margin-top: 40%;
	  margin-left: 30%;
	}
	40%, 59.9% {  
	  margin-top: 20%;
	  margin-left: 70%
	}
	60%, 79.9% {  
	  margin-top: 30%;
	  margin-left: 20%;
	}
	80%, 99.9% {  
	  margin-top: 30%;
	  margin-left: 80%;
	}
  }
  
  }
  .ProgressoOverAll{
    .ant-progress-outer{
      width: 80%;
    }
  }
  .BadgeData{    
    .ant-badge-status-dot {
      width: 12px;
      height: 12px;
      margin: 0 0 0 7px;
    }
  }
  .TopFormNew{
    .ant-form-item{ 
      .ant-form-item-label{
        padding-right: 15px;
      }
    
    }
  }

  .disabled-checkbox {
    pointer-events: none;
    opacity: 0.5;
  }

  .loc_custom_input_invoice .ant-form-item:nth-child(2) {
    width: 100%;
    margin-left: 10px;
}

.radio_btn_option {
  .ant-form-item .ant-form-item-control-input {
    .ant-radio-button-wrapper {
      margin-top: 15px;
    }
    .ant-radio-button-wrapper:first-child {
      border-right-color: #d0d0d0;
    }
  }
}

.custom_account_table {
  width: 100%;
  thead {
    th{
      text-align: left;
      padding: 5px 10px;
      width: 100%;
      max-width: 33.33%;
      border: 1px solid #bdbdbd;
      background-color :#efefef
    }
  }
  tbody {
    td {
      padding: 5px 10px;
      width: 100%;
      max-width: 33.33%;
      border: 1px solid #bdbdbd;
    }
    tr.table_update_data{
      background-color: #83f28f;
    }
  }
}
.viewAppLogIcon svg{
  font-size: 7px !important;
  margin: -3px 0 0 0;
  padding: 0 !important;
}
.auto-settle-buttom-table {
  th {
    padding: 5px;
  }
  td {
    padding: 5px;
  }
}
