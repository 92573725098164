.site__sidebar {
	height: 100vh;
	position: fixed;
	z-index: 10;
	width: 280px !important;
	max-width: 280px !important;
	min-width: 1px !important;
	background-color: $white_color;
	box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.09);
	&.ant-layout-sider-collapsed {
		width: 80px !important;
		max-width: 80px !important;
		min-width: 80px !important;
	}
	ul.ant-menu-root {
		height: calc(100vh - 130px);
		overflow-x: hidden;
		overflow-y: auto;
		// margin: 10px;
		// width: auto;
		border-right: 0;
	}
	& + section.ant-layout.site__layout {
		padding-left: 280px;
		transition: all 0.3s;
	}
	.logo {
		max-width: 100%;
	}
	.logo_wrapper {
		display: flex;
		padding: 10px;
		align-items: center;
		justify-content: center;
		height: 65px;
		background-color: $white_color;
		img {
			max-height: 44px;
		}
	}
}

.user_profile_sb {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	// background-color: $main_bg_color;
	// padding: 10px 22px;
	cursor: pointer;
	transition: all 0.5s ease-in-out;
	// margin-left: 30px;
	.ant-avatar {
		width: 30px;
		height: 30px;
		border: 2px solid $white_color;
		transition: all 0.5s ease;
		background-color: $primary_color;
		box-shadow: 0px 0px 6px 2px rgba(0, 0, 0, 0.18);
	}
	.user_name_role {
		width: calc(100% - 20px);
		line-height: normal;
		// padding-left: 18px;
		span {
			width: 100%;
			display: block;
			&.side_user_name {
				font-size: 13px;
				text-overflow: ellipsis;
				overflow: hidden;
				white-space: nowrap;
			}
			&.side_user_desig {
				font-size: 11px;
				color: $input_label_color;
				margin-top: 2px;
				text-overflow: ellipsis;
				overflow: hidden;
			}
		}
	}
	&:hover {
		@include themeColorOpacity($primary, 0.2);
		.ant-avatar {
			transform: scale(1.06);
			box-shadow: 0px 0px 16px -6px rgba(0, 0, 0, 0.75);
		}
	}
	.profile_arrow {
		margin-left: 10px;
		height: 14px;
		svg {
			width: 14px;
			height: 14px;
			transition: all 0.5s ease;
			fill: $primary_color;
		}
	}
	&.ant-popover-open {
		.profile_arrow svg {
			transform: rotate(180deg);
		}
	}
	@include desktop {
		.user_name_role {
			padding-left: 2px;
		}
	}
	@include mini-tablet {
		margin-left: 0;
	}
}
.ant-layout-sider-collapsed {
	.user_profile_sb {
		padding: 10px;
		justify-content: center;
	}
	.user_name_role,
	.profile_arrow {
		display: none;
	}
}

.ant-layout-sider-collapsed {
	& + section.ant-layout.site__layout {
		padding-left: 80px;
	}
	.ant-menu-item > span,
	.ant-menu-submenu-title > span,
	.ant-menu-item .ant-menu-item-icon + span,
	.submenu_arrow {
		display: none;
	}
}
.site__sidebar {
	.ant-menu-root > li {
		font-size: 16px;
		color: $input_label_color;
		padding-left: 0px !important;
		padding-right: 0px !important;
		margin: 0 !important;
		height: auto;
		width: 100%;
		line-height: normal;
		padding-top: 0px;
		padding-bottom: 0px;
		border-bottom: 1px solid $black_300;
		transition: all 0.5s ease;
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		&.ant-menu-submenu-selected,
		&:hover {
			color: $primary_color;
			@include themeColorOpacity($primary, 0.1);
			.menu_icon svg,
			.submenu_arrow svg {
				fill: $primary_color !important;
			}
		}
		&.ant-menu-submenu-selected .ant-menu-submenu-title {
			@include themeColorOpacity($primary, 0.1);
			border-left: 5px solid $primary_color;
		}
		&:last-child {
			border-bottom: 0;
		}
		&.ant-menu-item-selected {
			color: $primary_color;
			.menu_icon svg,
			.submenu_arrow svg {
				fill: $primary_color;
			}
		}
		.menu_icon {
			margin: 0;
			svg {
				width: 30px;
				height: 30px;
				margin-right: 15px;
				fill: $input_label_color;
				transition: all 0.5s ease;
				display: block;
			}
		}
		.submenu_arrow {
			margin-left: auto;
			svg {
				width: 14px;
				height: 14px;
				margin: 0;
				display: block;
				fill: $input_label_color;
				transition: all 0.5s ease;
			}
		}
		&.ant-menu-submenu-open {
			.submenu_arrow svg {
				transform: rotate(90deg);
			}
		}
		&:not(.ant-menu-submenu) {
			padding-left: 20px !important;
			padding-right: 20px !important;
			padding-top: 15px;
			padding-bottom: 15px;
			&.ant-menu-item-selected {
				@include themeColorOpacity($primary, 0.2);
				border-left: 5px solid $primary_color;
			}
		}
	}
	.ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title,
	.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
		height: auto;
		width: 100%;
		line-height: normal;
		padding-left: 20px !important;
		padding-right: 20px;
		padding-top: 15px;
		padding-bottom: 15px;
		margin: 0;
		display: flex;
		align-items: center;
	}
	.ant-menu.ant-menu-inline-collapsed > li {
		// padding-left: 20px !important;
		// padding-right: 20px !important;
		justify-content: center;
		// padding-top: 15px;
		// padding-bottom: 15px;
		.menu_icon svg {
			margin: 0 auto;
		}
	}
	@include desktop {
		.ant-menu-root > li {
			padding-left: 0px !important;
			padding-right: 0px !important;
			padding-top: 0px;
			padding-bottom: 0px;
		}
	}
}

.site__sidebar {
	.ant-menu-inline .ant-menu-item::after {
		display: none !important;
	}
	.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
		background-color: transparent;
	}
	.ant-menu-item:active,
	.ant-menu-submenu-title:active {
		background: transparent;
	}
	.ant-menu-sub.ant-menu-inline {
		background-color: transparent;
	}
	.ant-menu-sub.ant-menu-inline > .ant-menu-item,
	.ant-menu-sub.ant-menu-inline
		> .ant-menu-submenu
		> .ant-menu-submenu-title {
		height: auto;
		line-height: 16px;
		font-size: 16px;
		padding-left: 0 !important;
		padding-right: 0;
		margin: 0;
	}
	.ant-menu-sub {
		padding-left: 60px;
		padding-top: 20px;
		padding-bottom: 20px;
		border-top: 1px solid $main_bg_color;
		li {
			padding: 8px 0;
			color: $input_label_color;
			transition: all 0.5s ease;
			&:before {
				content: "";
				width: 8px;
				height: 8px;
				display: inline-block;
				background-color: $primary_color;
				border-radius: 50%;
				position: absolute;
				top: 11px;
				left: -8px;
				transition: all 0.5s ease;
				opacity: 0;
			}
			&.ant-menu-item-selected,
			&:hover {
				color: $primary_color;
				&:before {
					left: 0;
					opacity: 1;
				}
			}
		}
	}
	.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
		padding: 15px 0;
		margin: 0;
		width: 100%;
	}
	.ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title {
		height: auto;
		line-height: normal;
	}
	.ant-menu-vertical .ant-menu-submenu-title {
		padding-right: 0;
	}
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
	background-color: transparent;
}

.ant-menu-item-selected a,
.ant-menu-item-selected a:hover,
.ant-menu-item-selected,
.site__sidebar .ant-menu-item:hover,
.ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open, .ant-menu-light.ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open, .ant-menu-light  .ant-menu-light .ant-menu-submenu-title:hover {
	color: $primary_color;
}

.site__sidebar {
	.ant-menu-item:hover,
	.ant-menu:not(.ant-menu-inline),.ant-menu-submenu-open,
	.ant-menu-submenu-title:hover {
		color: $primary_color;
	}
}

.profile_popup ul {
	padding: 0;
	list-style: none;
	margin: 0;
	li {
		padding: 8px 0;
		border-bottom: 1px solid $black_300;
		&:last-child {
			border-bottom: 0;
		}
		span{
			cursor: pointer;
			&:hover {
				color: $primary_color;
			}
		}
		a {
			color: $black_color;
			font-size: 14px;
			&:hover {
				color: $primary_color;
			}
		}
	}
}

.site__sidebar .ant-menu-sub.ant-menu-inline > .ant-menu-item-selected,
.site__sidebar .ant-menu-sub.ant-menu-inline > .ant-menu-item:hover {
	padding-left: 20px !important;
}

// Submenu popup 3 column css start
.ant-menu-submenu-popup {
	.ant-menu-sub {
		width: 500px;
		padding: 10px 0;
		li {
			width: 33.33%;
			display: inline-block;
			margin: 0 !important;
		}
	}
}
// Submenu popup 3 column css end

.navLogo {
    // width: 20%;
	max-width: 300px;
    height: 100%;
    background-color: #f0f2f5;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 0px;
	img {
		max-height: 60px;
	}
	@include mini-tablet {
		max-width: 220px;
	}
}
.right-main-header {
	width: calc(100% - 290px);
	overflow: hidden;
	padding-bottom: 10px;

	// .marquee-text-set {
	// 	margin-top: 8px;
	// 	font-size: $fifteen;
	// 	font-weight: 700;
	// 	white-space: nowrap;
	// 	animation: marquee-text-set 40s linear infinite;
	// 	width: 100%;
	// 	&:hover{
	// 		animation-play-state: paused;
	// 	}
	// 	span:not(:first-child):before {
	// 		content: "|";
	// 		margin: 0 10px;
	// 		color: $primary_color;
	// 	}
	// }

	.scrolling-message {
		overflow: hidden;
		position: relative;
		white-space: nowrap;
		.scrolling-content {
			display: inline-block;
			white-space: nowrap;
			animation: scroll 40s linear infinite;
			.scrolling-item {
				display: inline-block;
				font-size: $fifteen;
				font-weight: 700;
				margin-top: 8px;
				&:not(:first-child):before {
					content: "|";
					margin: 0 10px;
					color: $primary_color;
				}
			  }
		  }
		  @keyframes scroll {
			0% {
			  transform: translateX(100%);
			}
			100% {
			  transform: translateX(-100%);
			}
		  }
	  }
	  
}
.marquee-text-footer{
	width: 100%;
    overflow: hidden;
    padding-bottom: 10px;
    position: absolute;
    bottom: 0;
    background: #f0f2f5;
    z-index: 11;

	.marquee-text-set {
		margin-top: 8px;
		font-size: $fifteen;
		font-weight: 700;
		white-space: nowrap;
		animation: marquee-text-set 40s linear infinite;
		&:hover{
			animation-play-state: paused;
		}
		span:not(:first-child):before {
			content: "|";
			margin: 0 10px;
			color: $primary_color;
		}
	}

	@keyframes marquee-text-set {
		0% {
			transform: translateX(100%);
		}

		100% {
			transform: translateX(-100%);
		}
	}
}

@keyframes marquee-text-set {
	0% {
		transform: translateX(100%);
	}

	100% {
		transform: translateX(-100%);
	}
}
.rightHeader {
    display: flex;
    justify-content: space-between;
    padding: 6px 30px 7px;
    background: $primary_color;
    border-radius: 0 0 20px 20px;
	@include mini-tablet {
		width: calc(100% - 240px);
		padding: 5px 10px 5px 30px;
	}
}
.user_name_arrow {
    padding: 2px 20px 3px 28px;
    background-color: #fff;
    border-radius: 20px;
    display: flex;
    align-items: center;
    width: 200px;
    justify-content: space-between;
    margin-left: -25px;
}
.header_right {
    display: flex;
    align-items: center;
}
.mainMenuDrawer {
		.ant-drawer-content-wrapper{
			width: 290px!important;
		.ant-drawer-wrapper-body{
			.ant-drawer-body{
				padding: 0;
				margin-bottom: 48px;
				.menuWrapper{
					.ant-menu-inline .ant-menu-item::after {
						display: none;
					}
					.logo_corporate{
						text-align: center;
						padding: 20px 15px;
						img{
							max-height: 70px;
							@include ipad {
								max-height: 40px;
							}
						}
					}
					.ant-menu-submenu, .ant-menu-submenu-selected, .ant-menu-submenu-active{
						.ant-menu-submenu-title{
							background-color: #f4f7f8;
							font-size: $fifteen;
							padding-left: 15px !important;
						}
						.ant-menu-item{
							span{
								font-size: $twelve;
							}
						}
						i{
							color: $black_500;
						}
					}
					.ant-menu-submenu-open,.ant-menu-submenu-active, .ant-menu-submenu-selected, .ant-menu-submenu-open{
						.ant-menu-submenu-title, i{
							color: $white_color;
							background-color: $primary_color;
						}
					}
					.ant-menu-submenu-open{
						.ant-menu-sub{ 
							.ant-menu-item-active, .ant-menu-item-selected{
								color: $primary_color;
							}
						}
					}
					.ant-menu-item{
						height: 25PX;
						padding-left: 23px !important;
						&.logout-menu-item{
							position: absolute;
							bottom: 0;
							left: 0;
							padding: 0 !important;
							margin: 0;
							height: auto;
							.ant-btn-primary{
								width: 99.5%;
								margin: 0;
								padding: 6px 0 1px 15px;
								height: auto !important;
								border-radius: 0;
								line-height: inherit;
								background-color: $primary_color;
								border-color: $primary_color;
								gap: 10px;
								display: flex;
								align-items: center;
								&:hover, &:focus{
									transform:scale(1)
								}
							}
						}
					}
				}
				.social_icon{
					bottom: 0;
					position: fixed;
					display: flex;
					width: 290px;
					border-top: 1px solid $primary_color;
					@include ipad {
						width: 245px !important;
					}
					Button{
						height:35px;
						width: 120px;
						display: flex;
						align-items: center;
						justify-content: center;
						flex-wrap: wrap;
						background-color: $primary_color;
						border: none;
						border-radius: 0;
						&:nth-child(1){
							margin-left: 0px;
						}
						&:nth-child(2){
							margin-left: 5px;
						}
						&:nth-child(3){
							margin-left: 5px;
						}
						.nav_footer_icon{
							color: $white_color;
							font-size: $eighteen;
							transition: 0.3s all ease-in-out;
						}
						&:hover{
							border: none;
							transform: scale(0.94);
							border-radius: 2px;
						}
					}
				}
			}
		}
		@include ipad {
			width: 245px !important;
		}
	}
	.custom-menu-item{
		&:hover{
			color: $primary_color;
			.span{
				color: $primary_color;
			}
		}
	}
	.international-hide-menu:not(:first-child){
		display: none;
	}
}

