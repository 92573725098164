.react-grid-layout {
	position: relative;
	transition: height .2s ease;
}

.widget_wrapper {
	height: 100%;
	overflow: hidden;
	border: 0;

	.widget_row {
		transition: 0.4s all ease-in-out;

		// box-shadow: rgba(149, 157, 165, 0.2) 3px 6px 8px;
		&:hover {
			// box-shadow:none;
			transform: scale(0.95);
		}
	}

	.dashboard_widget_block_inner {
		overflow-y: auto;
		height: calc(100% - 60px);
		margin-top: 5px;
	}
}

.widget_wrapper .ant-card-body {
	padding: 0;
	height: 100%;
}

.counter__widget {
	display: flex;
	align-items: center;
}

.counter__widget__icon {
	margin-right: 15px;
	font-size: $counter_widget_icon_font;
}

.counter__widget__name {
	font-size: $counter_widget_name_font;
	// font-weight: 600;
	width: 80%;
}

.widget__title__design .counter__widget__name {
	margin-bottom: 10px;
	color: #242432;
	// background-color: #1b2f3e;
	padding: 2px 15px;
	border-radius: 10px;
	display: inline-block;
}

.counter__widget__counts {
	font-size: $counter_widget_count_font;
}

.counter__widget__buttons {
	display: flex;
	justify-content: flex-end;
	margin-left: -$widget_padding_horizontal/2;
	margin-right: -$widget_padding_horizontal/2;
	margin-top: 31px;
}

.counter__widget__button {
	font-size: $counter_widget_button_font+2;
	color: #999;

	&.cs_active,
	&.active,
	&:hover,
	&:focus {
		color: #222;
	}
}

.counter__widget__footer {
	position: absolute;
	bottom: 3px;
	right: 15px;
}

#content {
	width: 100%;
}

.react-grid-layout {
	// background: #eee;
	margin-top: 10px;
}

.layoutJSON {
	background: #ddd;
	border: 1px solid black;
	margin-top: 10px;
	padding: 10px;
}

.columns {
	-moz-columns: 120px;
	-webkit-columns: 120px;
	columns: 120px;
}

.react-grid-item {
	box-sizing: border-box;
	border: 1px dotted gray;
	border-radius: 12px;
	background-color: transparent !important;
	overflow: overlay;
	z-index: 1;
}

.react-grid-item:not(.react-grid-placeholder) {
	background-color: #fff !important;
	border: 0px;
	z-index: 11;
	box-shadow: 0 0 11px -5px rgba(0, 0, 0, 0.75);
}

.react-grid-item.resizing {
	opacity: 0.9;
}

.react-grid-item.static {
	background: #cce;
}

.react-grid-item .text {
	font-size: 24px;
	text-align: center;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
	height: 24px;
}

.react-grid-item .minMax {
	font-size: 12px;
}

.react-grid-item .add {
	cursor: pointer;
}

.react-grid-dragHandleExample {
	cursor: move;
	/* fallback if grab cursor is unsupported */
	cursor: grab;
	cursor: -moz-grab;
	cursor: -webkit-grab;
}

.toolbox {
	background-color: #dfd;
	width: 100%;
	height: 120px;
	overflow: scroll;
}

.hide-button {
	cursor: pointer;
	position: absolute;
	font-size: 20px;
	top: 0px;
	right: 5px;
}

.toolbox__title {
	font-size: 24px;
	margin-bottom: 5px;
}

.toolbox__items {
	display: block;
}

.toolbox__items__item {
	display: inline-block;
	text-align: center;
	line-height: 40px;
	cursor: pointer;
	width: 40px;
	height: 40px;
	padding: 10px;
	margin: 5px;
	border: 1px solid black;
	background-color: #ddd;
}

.droppable-element {
	width: 150px;
	text-align: center;
	background: #fdd;
	border: 1px solid black;
	margin: 10px 0;
	padding: 10px;
}

.react-grid-item {
	.remove {
		opacity: 0;
	}

	&:hover>.remove {
		opacity: 1;
	}
}

.graph_widget {
	.counter__widget {
		display: block;
		width: 100%;
		height: calc(100% - 55px);

		.counter__widget__description {
			height: 100%;

			.counter__widget__chart {
				height: 100%;
			}
		}
	}

	.counter__widget__buttons {
		margin-top: 20px;
	}
}

.report_widget_grid {
	height: calc(100% - 40px);
	margin-top: 10px;
}

button.box-design-btn {
	border-radius: 50%;
	box-shadow: none;
	width: 30px;
	height: 30px;
	padding: 0;
}

button.box-design-btn svg {
	fill: #141414;
	cursor: pointer;
	width: 15px;
}

.ant-btn>.anticon {
	line-height: 0;
}

// .react-resizable {
//     position: relative;
//   }
//   .react-resizable-handle {
//     position: absolute;
//     width: 20px;
//     height: 20px;
//     background-repeat: no-repeat;
//     background-origin: content-box;
//     box-sizing: border-box;
//     background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4Ij48ZyBvcGFjaXR5PSIwLjMwMiI+PHBhdGggZD0iTSA2IDYgTCAwIDYgTCAwIDQuMiBMIDQgNC4yIEwgNC4yIDQuMiBMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+');
//     background-position: bottom right;
//     padding: 0 3px 3px 0;
//   }
//   .react-resizable-handle-sw {
//     bottom: 0;
//     left: 0;
//     cursor: sw-resize;
//     transform: rotate(90deg);
//   }
//   .react-resizable-handle-se {
//     bottom: 0;
//     right: 0;
//     cursor: se-resize;
//   }
//   .react-resizable-handle-nw {
//     top: 0;
//     left: 0;
//     cursor: nw-resize;
//     transform: rotate(180deg);
//   }
//   .react-resizable-handle-ne {
//     top: 0;
//     right: 0;
//     cursor: ne-resize;
//     transform: rotate(270deg);
//   }
//   .react-resizable-handle-w,
//   .react-resizable-handle-e {
//     top: 50%;
//     margin-top: -10px;
//     cursor: ew-resize;
//   }
//   .react-resizable-handle-w {
//     left: 0;
//     transform: rotate(135deg);
//   }
//   .react-resizable-handle-e {
//     right: 0;
//     transform: rotate(315deg);
//   }
//   .react-resizable-handle-n,
//   .react-resizable-handle-s {
//     left: 50%;
//     margin-left: -10px;
//     cursor: ns-resize;
//   }
//   .react-resizable-handle-n {
//     top: 0;
//     transform: rotate(225deg);
//   }
//   .react-resizable-handle-s {
//     bottom: 0;
//     transform: rotate(45deg);
//   }

.unregistered_Widget {
	.counter__widget {
		justify-content: flex-start;
		display: flex;
		align-items: flex-start;
		flex-direction: column;
	}
}

.unregistered_Widget {
	.counter__widget__name {
		color: #e0f2fe;
	}

	.counter__widget__description {
		padding-top: 15px;
		padding-left: 65px;
		width: 100%;
	}

	.counter__widget__counts {
		color: #fff;
		font-weight: 500;
		font-size: 2rem;
		line-height: 2rem;
		width: 73%;
	}

	.counter__widget__footer {
		color: #fff;
	}

	.ant-btn.box-design-btn {
		background-color: transparent;
		border-color: #fff;

		svg {
			fill: #fff;

			path {
				fill: #fff;
			}
		}
	}

	.mask {
		svg {
			width: 4rem;
			height: 4rem;
			opacity: 0.2;
			margin: -0.75rem;
			-webkit-mask-position: center;
			mask-position: center;
			-webkit-mask-repeat: no-repeat;
			mask-repeat: no-repeat;
			-webkit-mask-size: contain;
			mask-size: contain;
			display: inline-block;
		}

		&.absolute {
			position: absolute;
			left: 0;
		}
	}
}

.unregistered_bag_Widget {
	background-image: linear-gradient(to bottom right, #0ea5e9, #0284c7);

	// .mask.is-reuleaux-triangle {
	// 	-webkit-mask-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='207' height='207' fill='none'%3E%3Cpath fill='%23000' d='M138.648 181.408C47.268 232.569 1.327 206.607.824 103.52.324.432 46.014-25.148 137.896 26.777c91.882 51.925 92.133 103.469.753 154.631h-.001z'/%3E%3C/svg%3E");
	// 	mask-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='207' height='207' fill='none'%3E%3Cpath fill='%23000' d='M138.648 181.408C47.268 232.569 1.327 206.607.824 103.52.324.432 46.014-25.148 137.896 26.777c91.882 51.925 92.133 103.469.753 154.631h-.001z'/%3E%3C/svg%3E")
	// }
}

.unregistered_packets_Widget {
	background-image: linear-gradient(to bottom right, #fbbf24, #ea580c);

	// .mask.is-diamond {
	// 	-webkit-mask-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='227' height='227' fill='none'%3E%3Cpath fill='%23000' d='M42.71 42.71c-56.568 56.57-56.568 84.853 0 141.422 56.57 56.569 84.853 56.569 141.422 0s56.569-84.853 0-141.421c-56.569-56.569-84.853-56.569-141.421 0z'/%3E%3C/svg%3E");
	// 	mask-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='227' height='227' fill='none'%3E%3Cpath fill='%23000' d='M42.71 42.71c-56.568 56.57-56.568 84.853 0 141.422 56.57 56.569 84.853 56.569 141.422 0s56.569-84.853 0-141.421c-56.569-56.569-84.853-56.569-141.421 0z'/%3E%3C/svg%3E")
	// }
}

.unregistered_booking_Widget {
	background-image: linear-gradient(to bottom right, #ec4899, #f43f5e);

	// .mask.is-hexagon {
	// 	-webkit-mask-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='182' height='201' fill='none'%3E%3Cpath fill='%23000' d='M.3 65.486c0-9.196 6.687-20.063 14.211-25.078l61.86-35.946c8.36-5.016 20.899-5.016 29.258 0l61.86 35.946c8.36 5.015 14.211 15.882 14.211 25.078v71.055c0 9.196-6.687 20.063-14.211 25.079l-61.86 35.945c-8.36 4.18-20.899 4.18-29.258 0l-61.86-35.945C6.151 157.44.3 145.737.3 136.54V65.486z'/%3E%3C/svg%3E");
	// 	mask-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='182' height='201' fill='none'%3E%3Cpath fill='%23000' d='M.3 65.486c0-9.196 6.687-20.063 14.211-25.078l61.86-35.946c8.36-5.016 20.899-5.016 29.258 0l61.86 35.946c8.36 5.015 14.211 15.882 14.211 25.078v71.055c0 9.196-6.687 20.063-14.211 25.079l-61.86 35.945c-8.36 4.18-20.899 4.18-29.258 0l-61.86-35.945C6.151 157.44.3 145.737.3 136.54V65.486z'/%3E%3C/svg%3E")
	// }

}

.api_reload_btn {
	position: absolute;
	background: rgba(255, 255, 255, 0.9);
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;

	.counter__widget__name-api {
		font-size: 16px;
		margin-bottom: 5px;
	}

	.box-design-btn-api {
		background-color: transparent;
		border-color: #000000;
		border-radius: 50%;
		box-shadow: none;
		width: 35px;
		height: 35px;
		padding: 0;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}

.dashboard_widget_block {


	.dashboard_widget_block_inner {
		.counter__widget__name {
			padding-bottom: 15px;
		}
	}

	.widget_wrapper {
		transition: all .5s ease;

		.ant-empty {
			padding: 15px 0;
		}

		.top_img img {
			max-height: 100px;
			max-width: 150px;
			margin: 0 auto 10px;
			display: block;

			@include desktop {
				max-height: 100px;
				max-width: 55%;
			}
		}
	}

	.widget_title {
		padding: 10px 22px 10px 22px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-top: 2px;

		h3 {
			margin: 0;
			font-size: 17px;
			color: $black_color;
			line-height: normal;

			@include ipad {
				font-size: 14px;
			}
		}

		.refresh_toggle_icon {
			display: flex;
			align-items: center;

			svg {
				color: $black_color;
				cursor: pointer;
				width: 18px;
			}
		}
	}

	.widget_data {
		padding: 10px;
		list-style: none;
		margin: 0;

		.widget_row {
			cursor: pointer;
			display: flex;
			// justify-content: space-between;
			align-items: center;
			padding: 2px 12px;

			// &:nth-child(odd) {
			// 	border-left: 2px solid $icon_gray_color;
			// 	background-color: $black_200;
			// }
			.widget_name {
				font-size: 14px;

				p {
					margin: 0;
					font-size: 16px;
					color: $input_label_color;
					line-height: normal;

					span {
						display: block;
						font-size: 12px;
						color: $black_500;
						line-height: normal;
						margin-top: 5px;
					}
				}

				@include desktop {
					p {
						font-size: 14px;
					}
				}

				@include ipad {
					p {
						font-size: 12px;
					}
				}

			}

			.widget_date {
				border-right: 1px solid $black_200;
				padding-right: 12px;
				margin-right: 25px;
				min-width: 60px;

				p {
					margin: 0;
					font-size: 18px;
					color: $primary_color;
					text-align: center;
					line-height: normal;

					span {
						font-size: 24px;
						display: block;
						line-height: normal;
					}
				}

				@include desktop {
					p {
						font-size: 16px;

						span {
							font-size: 22px;
						}
					}
				}

				@include ipad {
					min-width: 50px;

					p {
						font-size: 14px;

						span {
							font-size: 20px;
						}
					}
				}
			}

			// &:last-child {
			// 	border-bottom: 0;
			// }
		}
	}

	.ant-skeleton.ant-skeleton-active {
		padding: 0 15px;
	}

	.notification_widget {
		.widget_row {
			flex-wrap: wrap;
			background-color: #e9e9e9;
			border-radius: 10px;

			.widget_top {
				display: flex;
				justify-content: space-between;
				align-items: center;
				width: 100%;

				.title {
					margin: 0;
					line-height: normal;
					width: 70%;
					text-overflow: ellipsis;
					overflow: hidden;
				}

				.date {
					margin: 0;
					line-height: normal;
					width: 30%;
					text-align: right;
				}
			}

			.widget_bottom p {
				line-height: 18px;
				font-size: 12px;
				margin-top: 8px;
				margin-bottom: 0;
				color: #858181;
			}
		}
	}

	.anniversary_widget {
		.widget_row {
			justify-content: space-between;
			border-bottom: 1px solid $black_200;

			.widget_date {
				margin: 0;
				border: 0;
				padding: 7px;
				background-color: $black_200;
				border-radius: 8px 8px 0 8px;
			}
		}

		@include desktop {
			.widget_row {
				.widget_name {
					p {
						span {
							font-size: 10px;
						}
					}
				}

				.widget_date {
					padding: 5px;
				}
			}
		}
	}

	.zform_widget {
		.widget_row {
			.drsLoadwidget {
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				width: 100%;
				margin-bottom: 5px;

				.widget_left {
					width: 55px;
					height: 55px;
					background: #E35260;
					padding: 5px;
					border-bottom-left-radius: 30px;
					border-top-left-radius: 30px;
					display: flex;
					align-items: center;

					svg {
						// fill: $white_color;
						margin: 0 auto;
					}

				}

				.widget_right {
					width: calc(100% - 60px);
					text-align: end;
					background: #E35260;
					padding: 0px 15px;
					display: flex;
					flex-direction: column;
					justify-content: center;
					border-bottom-right-radius: 20px;

					h3 {
						margin: 0;
						font-size: 12px;
						font-weight: 600;
						// color: $white_color;
					}

					p {
						font-size: 18px;
						margin: 0;
						line-height: normal;
					}
				}
			}

			&:nth-child(1) {
				.widget_left {
					background: #E35260;


					svg {
						width: 30px;
					}
				}

				.widget_right {
					background: #E35260;
				}

			}

			&:nth-child(2) {
				.widget_left {
					background: #F5C750;

					svg {
						width: 50px;
					}
				}

				.widget_right {
					background: #F5C750;
				}

			}

			&:nth-child(3) {
				.widget_left {
					background: #9ACC62;

					svg {
						width: 30px;
					}
				}

				.widget_right {
					background: #9ACC62;
				}

			}

			&:nth-child(4) {
				.widget_left {
					background: #45C7A7;

					svg {
						width: 40px;
					}
				}

				.widget_right {
					background: #45C7A7;
				}

			}

			&:nth-child(5) {
				.widget_left {
					background: #5A95E2;

					svg {
						width: 40px;
					}
				}

				.widget_right {
					background: #5A95E2;
				}

			}

			&:nth-child(6) {
				.widget_left {
					background: #BA90C6;

					svg {
						width: 30px;
					}
				}

				.widget_right {
					background: #BA90C6;
				}

			}

			&:nth-child(7) {
				.widget_left {
					background: #F4B183;

					svg {
						width: 50px;
					}
				}

				.widget_right {
					background: #F4B183;
				}

			}

			&:nth-child(8) {
				.widget_left {
					background: #AACB73;

					svg {
						width: 30px;
					}
				}

				.widget_right {
					background: #AACB73;
				}

			}

			&:nth-child(9) {
				.widget_left {
					background: #579BB1;

					svg {
						width: 40px;
					}
				}

				.widget_right {
					background: #579BB1;
				}

			}

			&:nth-child(10) {
				.widget_left {
					background: #8EC3B0;

					svg {
						width: 40px;
					}
				}

				.widget_right {
					background: #8EC3B0;
				}

			}

		}


		@include desktop {
			.widget_row {
				height: 44px;

				.widget_left {
					p {
						font-size: 14px;
					}
				}

				.widget_right {
					min-width: 65px;

					p {
						font-size: 22px;
					}
				}
			}
		}

		@include ipad {
			.widget_row {
				margin-bottom: 10px;

				.widget_left {
					padding-left: 20px;

					p {
						line-height: normal;
					}
				}
			}
		}
	}

	.inquiryRating_widget {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		padding: 0 15px;

		.widget_row {
			width: 46%;
			border: 0;
			margin-bottom: 20px;
			box-shadow: 0px 0px 16px -8px rgba(0, 0, 0, 0.75);
			display: flex;
			justify-content: space-between;
			align-items: flex-start;
			height: 100px;

			.widget_name {
				p {
					text-transform: capitalize;
					cursor: pointer;
				}
			}

			.widget_date {
				margin: 0;
				padding: 0;
				min-width: 1px;
				border-right: 0;

				p {
					font-size: 24px;
				}
			}

			&.hot_rating {
				//background-image: url(../../images/hot-rating.png);

				.widget_date {
					p {
						color: #f91717;
					}
				}
			}

			&.warm_rating {
				//background-image: url(../../images/Warm-rating.png);

				.widget_date {
					p {
						color: #fa8c00;
					}
				}
			}

			&.cool_rating {
				//background-image: url(../../images/Cool-rating.png);

				.widget_date {
					p {
						color: #21bdfa;
					}
				}
			}

			&.all_rating {
				//background-image: url(../../images/All-rating.png);

				.widget_date {
					p {
						color: #8afff7;
					}
				}
			}
		}

		@include desktop {
			.widget_row {
				height: 80px;
				background-size: cover;

				.widget_name {
					font-size: 14px;
				}

				.widget_date {
					p {
						font-size: 22px;
					}
				}
			}
		}

		@include ipad {
			.widget_row {
				height: 100px;
				width: 100%;
				background-size: contain;
				background-repeat: no-repeat;
				background-position: bottom;
			}
		}
	}

	.zform_widget_detail {
		padding: 5px 15px;
		cursor: pointer;

		.widget_row {
			height: 65px;
			padding: 0;
			margin-bottom: 15px;
			box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.14);
			border-radius: 10px;
			overflow: hidden;

			&:nth-child(1) {
				.widget_left {
					background-color: #ffb534;
				}
			}

			&:nth-child(2) {
				.widget_left {
					background-color: #ef7c3e;
				}
			}

			&:nth-child(3) {
				.widget_left {
					background-color: #7cb364;
				}
			}

			&:nth-child(4) {
				.widget_left {
					background-color: #4ecea8;
				}
			}

			&:nth-child(5) {
				.widget_left {
					background-color: #68a1d6;
				}
			}

			&:nth-child(6) {
				.widget_left {
					background-color: #ffb534;
				}
			}

			&:nth-child(7) {
				.widget_left {
					background-color: #ef7c3e;
				}
			}

			&:nth-child(8) {
				.widget_left {
					background-color: #7cb364;
				}
			}

			&:nth-child(9) {
				.widget_left {
					background-color: #4ecea8;
				}
			}

			&:nth-child(10) {
				.widget_left {
					background-color: #68a1d6;
				}
			}

			.widget_left {
				height: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				width: 20%;
				padding: 10px;
				border-radius: 10px;

				h3 {
					font-size: 26px;
					color: $white_color;
					margin: 0;
				}

				svg {
					fill: $white_color;
				}
			}

			.widget_right {
				height: 100%;
				display: flex;
				width: 80%;
				padding: 10px 20px;
				flex-direction: column;
				justify-content: center;
				text-align: end;

				h3 {
					font-size: 12px;
					margin-bottom: 0;
					line-height: normal;
					cursor: pointer;
					font-weight: 600;
				}

				p {
					margin: 0;
					font-size: 18px;
				}

				span {
					font-size: 26px;
					line-height: normal;
					margin-top: 4px;
				}
			}
		}

		@include desktop {
			.widget_row {
				height: 60px;

				.widget_left {
					img {
						max-width: 25px;
					}
				}

				.widget_right {
					padding: 5px 12px;

					p {
						font-size: 14px;
						line-height: 14px;
					}

					span {
						font-size: 22px;
						line-height: 22px;
					}
				}
			}
		}
	}

	.post_zform_widget {
		padding: 0 15px;

		.widget_row_color {
			margin-bottom: 30px;
			box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.14);
			border-radius: 10px;
			overflow: hidden;
			display: flex;
			justify-content: flex-end;

			.widget_row {
				background-color: $white_color;
				width: calc(100% - 10px);
				border-radius: 10px 0 0 10px;
				justify-content: space-between;

				.widget_right {
					p {
						font-size: 16px;
						color: #7c7c7c;
						margin-bottom: 0;
					}

					span {
						font-size: 28px;
						line-height: normal;
						margin-top: 4px;
					}
				}

				.widget_left {
					img {
						max-width: 60px;
					}
				}
			}
		}
	}

	.ZformApprovalLine {
		margin-bottom: 30px;

		h3 {
			font-size: 16px;
			color: #7c7c7c;
			margin-bottom: 0;
		}

		.ant-progress-show-info {
			display: flex;
			justify-content: space-between;

			.ant-progress-outer {
				width: calc(100% - 60px);
			}

			.ant-progress-text {
				font-size: 28px;
				line-height: normal;
				width: 60px;
			}
		}

		&.greenProgress {
			.ant-progress-bg {
				background-color: #80c564;
				opacity: 0.8;
			}

			.ant-progress-text {
				color: #80c564;
			}
		}

		&.orangeProgress {
			.ant-progress-bg {
				background-color: #ed7b0c;
				opacity: 0.8;
			}

			.ant-progress-text {
				color: #ed7b0c;
			}
		}

		&.blueProgress {
			.ant-progress-bg {
				background-color: #269ae9;
				opacity: 0.8;
			}

			.ant-progress-text {
				color: #269ae9;
			}
		}

		&.purpleProgress {
			.ant-progress-bg {
				background-color: #742fff;
				opacity: 0.8;
			}

			.ant-progress-text {
				color: #742fff;
			}
		}
	}

	.inquiry_status_widget {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		padding: 0 15px;

		.widget_row {
			width: 48%;
			justify-content: space-between;
			border-radius: 50px;
			margin-bottom: 15px;
			padding: 10px 18px;

			.widget_left p {
				margin: 0;
				font-size: 16px;
				line-height: normal;
				color: $white_color;
			}

			.widget_right p {
				margin: 0;
				line-height: normal;
				color: $white_color;
				font-size: 26px;
			}
		}

		@include desktop {
			.widget_row {
				width: 100%;
				padding: 8px 15px;

				.widget_left {
					p {
						font-size: 14px;
					}
				}

				.widget_right {
					p {
						font-size: 22px;
					}
				}
			}
		}

		@include ipad {
			.widget_row {
				padding: 6px 15px;
				margin-bottom: 10px;
			}
		}
	}

	.fund_tran_widget {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;

		.widget_row {
			justify-content: space-between;
			border-radius: 50px;
			margin-bottom: 15px;
			padding: 5px 15px 5px 5px;
			width: 48%;

			.widget_left {
				display: flex;
				align-items: center;
				flex-wrap: wrap;

				.fund_icon {
					width: 40px;
					height: 40px;
					border-radius: 50%;
					margin-right: 8px;
					background-repeat: no-repeat;
					background-size: 25px;
					background-position: center;
				}

				p {
					margin: 0;
					line-height: normal;
					width: calc(100% - 50px);
				}
			}

			.widget_right {
				p {
					margin: 0;
					line-height: normal;
					font-size: 26px;
				}
			}
		}

		.green_row {
			background-color: rgba(150, 194, 131, 0.2);
			border: 1px solid #96c283;

			.fund_icon {
				background-color: #96c283;
				//background-image: url(../../images/icons/widgetIcon/fund_Received.png);
			}
		}

		.blue_row {
			background-color: rgba(98, 166, 235, 0.2);
			border: 1px solid #62a6eb;

			.fund_icon {
				background-color: #62a6eb;
				//background-image: url(../../images/icons/widgetIcon/fund_Deposited.png);
			}
		}

		.orange_row {
			background-color: rgba(255, 181, 52, 0.2);
			border: 1px solid #ffb534;

			.fund_icon {
				background-color: #ffb534;
				//background-image: url(../../images/icons/widgetIcon/fund_Refunds.png);
			}
		}

		.red_row {
			background-color: rgba(249, 9, 10, 0.2);
			border: 1px solid #f9090a;

			.fund_icon {
				background-color: #f9090a;
				//background-image: url(../../images/icons/widgetIcon/fund_Open_Cancellations.png);
			}
		}

		@include desktop {
			.widget_row {
				width: 100%;
				padding: 2px 15px 2px 2px;

				.widget_left {
					.fund_icon {
						width: 32px;
						height: 32px;
						background-size: 20px;
					}

					p {
						width: auto;
						font-size: 12px;
					}
				}

				.widget_right {
					p {
						font-size: 22px;
					}
				}
			}
		}

		@include ipad {
			.widget_row {
				margin-bottom: 10px;
			}
		}
	}

	.active_insurance {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;

		.widget_row {
			position: relative;
			width: 50%;
			border: 0;
			margin-bottom: 15px;

			.widget_left {
				position: absolute;
				top: -4px;
				left: 50%;
				transform: translateX(-50%);
				background-color: #7cb363;
				color: #fff;
				padding: 6px 2px;
				min-width: 158px;
				text-align: center;
				border-radius: 50px;

				p {
					margin: 0;
					line-height: normal;
				}
			}

			.widget_right {
				width: 100%;
				border: 1px solid #ddd;
				border-radius: 10px;
				text-align: center;
				padding: 40px 10px 15px;

				p {
					margin: 0;
					line-height: normal;
					font-size: 26px;
				}
			}
		}

		@include desktop {
			.widget_row {
				padding: 10px 5px;

				.widget_left {
					min-width: 110px;

					p {
						font-size: 12px;
					}
				}

				.widget_right {
					padding: 25px 10px 6px;

					p {
						font-size: 22px;
					}
				}
			}
		}

		@include ipad {
			.widget_row {
				width: 100%;
				margin-bottom: 8px;

				.widget_left {
					min-width: 130px;
				}
			}
		}
	}

	.insurance_quotation {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;

		.widget_row {
			width: 48%;
			border: 0;
			// background-color: #ddd;
			margin-bottom: 15px;
			flex-wrap: wrap;
			flex-direction: column;
			height: 96px;
			justify-content: space-between;
			border-radius: 8px;
			color: $white_color;

			.widget_right {
				align-self: flex-start;

				p {
					line-height: normal;
					font-size: 26px;
					margin-bottom: 0;
				}
			}

			.widget_left {
				align-self: flex-end;
				width: 100%;
				text-align: right;

				p {
					font-size: 16px;
					line-height: normal;
					margin-bottom: 0;
				}
			}

			&.pinkGradient {
				background: -moz-linear-gradient(-58deg, #11998e 0%, #38ef7d 100%);
				background: -webkit-linear-gradient(-58deg, #11998e 0%, #38ef7d 100%);
				background: -ms-linear-gradient(-58deg, #11998e 0%, #38ef7d 100%);
				background: linear-gradient(-58deg, #11998e 0%, #38ef7d 100%);
			}

			&.purpleGradient {
				background: -moz-linear-gradient(-58deg, rgb(178, 163, 229) 0%, rgb(255, 106, 128) 99%);
				background: -webkit-linear-gradient(-58deg, rgb(178, 163, 229) 0%, rgb(255, 106, 128) 99%);
				background: -ms-linear-gradient(-58deg, rgb(178, 163, 229) 0%, rgb(255, 106, 128) 99%);
				background: linear-gradient(-58deg, rgb(178, 163, 229) 0%, rgb(255, 106, 128) 99%);
			}

			&.blueGradient {
				background: -moz-linear-gradient(-58deg, rgb(108, 234, 242) 0%, rgb(102, 166, 255) 99%);
				background: -webkit-linear-gradient(-58deg, rgb(108, 234, 242) 0%, rgb(102, 166, 255) 99%);
				background: -ms-linear-gradient(-58deg, rgb(108, 234, 242) 0%, rgb(102, 166, 255) 99%);
				background: linear-gradient(-58deg, rgb(108, 234, 242) 0%, rgb(102, 166, 255) 99%);
			}

			&.orangeGradient {
				background: -moz-linear-gradient(-58deg, rgb(255, 222, 101) 0%, rgb(255, 69, 69) 100%);
				background: -webkit-linear-gradient(-58deg, rgb(255, 222, 101) 0%, rgb(255, 69, 69) 100%);
				background: -ms-linear-gradient(-58deg, rgb(255, 222, 101) 0%, rgb(255, 69, 69) 100%);
				background: linear-gradient(-58deg, rgb(255, 222, 101) 0%, rgb(255, 69, 69) 100%);
			}
		}

		@include desktop {
			.widget_row {
				padding: 10px 8px;
				height: 86px;

				.widget_right {
					p {
						font-size: 22px;
					}
				}

				.widget_left {
					p {
						font-size: 14px;
					}
				}
			}
		}

		@include ipad {
			.widget_row {
				width: 100%;
				height: 76px;
				margin-bottom: 12px;
			}
		}
	}

	.active_insurance {
		.green_text {
			.widget_left {
				background-color: #7cb363;
			}
		}

		.blue_text {
			.widget_left {
				background-color: #68a1d7;
			}
		}

		.orange_text {
			.widget_left {
				background-color: #ef7c3d;
			}
		}

		.yellow_text {
			.widget_left {
				background-color: #ffb534;
			}
		}

		.red_text {
			.widget_left {
				background-color: #f9090a;
			}
		}
	}

	.insurance_fund_widget {
		display: flex;
		justify-content: space-between;

		.widget_row {
			flex-direction: column;
			width: 50%;

			.widget_top {
				width: 140px;
				height: 140px;
				border: 8px solid;
				border-radius: 50%;
				display: flex;
				justify-content: center;
				align-items: center;

				p {
					margin: 0;
					line-height: normal;
					font-size: 26px;
				}
			}

			&.green_row .widget_top {
				background-color: rgba(124, 179, 99, 0.2);
				border-color: #7cb363;
			}

			&.blue_row .widget_top {
				background-color: rgba(104, 161, 215, 0.2);
				border-color: #68a1d7;
			}
		}

		@include desktop {
			.widget_row {
				.widget_top {
					width: 100px;
					height: 100px;
					border: 6px solid;

					p {
						font-size: 22px;
					}
				}

				.widget_bottom {
					p {
						font-size: 12px;
					}
				}
			}
		}

		@include ipad {
			.widget_row {
				.widget_top {
					width: 80px;
					height: 80px;
					border: 4px solid;
				}
			}
		}
	}

	.active_finance_widget {
		.widget_row {
			justify-content: space-between;
			border-left: 5px solid;
			margin-bottom: 10px;
			// padding: 10px 10px 10px 0px;

			.widget_left {
				text-align: right;

				h3 {
					font-weight: 600;
					margin: 0;
					font-size: 12px;
				}

				p {
					font-size: 18px;
					margin-bottom: 0;
					line-height: normal;
				}
			}

			.widget_right {
				display: flex;
				align-items: center;

				p {
					margin: 0;
					font-size: 26px;
					line-height: normal;
				}

				svg {
					display: inline-block;
				}
			}

			&:nth-child(1) {
				border-color: #3b90f8;
				@include themeColorOpacity(#3b90f8, 0.2);

				.widget_right p {
					color: #3b90f8;
				}

				svg {
					width: 35px;
				}
			}

			&:nth-child(2) {
				border-color: #80c564;
				@include themeColorOpacity(#80c564, 0.2);

				.widget_right {
					margin-left: -10px;

					p {

						color: #80c564;
					}

					svg {
						width: 50px;
					}

				}


			}

			&:nth-child(3) {
				border-color: #f3b1d5;
				@include themeColorOpacity(#f3b1d5, 0.2);

				.widget_right {
					p {
						color: #f3b1d5;

					}

					svg {
						width: 35px;
					}
				}

			}

			&:nth-child(4) {
				border-color: #88dced;
				@include themeColorOpacity(#88dced, 0.2);

				.widget_right {
					margin-left: -5px;

					p {

						color: #88dced;
					}

					svg {
						width: 45px;
					}
				}


			}

			&:nth-child(5) {
				border-color: #ffb534;
				@include themeColorOpacity(#ffb534, 0.2);

				.widget_right {
					margin-left: -5px;

					p {
						color: #ffb534;
					}

					svg {
						width: 45px;
					}
				}
			}

			&:nth-child(6) {
				border-color: #3b90f8;
				@include themeColorOpacity(#3b90f8, 0.2);

				.widget_right {
					margin-left: -5px;

					p {

						color: #3b90f8;
					}

					svg {
						width: 40px;
					}
				}


			}

			&:nth-child(7) {
				border-color: #80c564;
				@include themeColorOpacity(#80c564, 0.2);

				.widget_right {
					margin-left: -5px;

					p {
						color: #80c564;

					}

					svg {
						width: 40px;
					}
				}


			}


			&:nth-child(8) {
				border-color: #3b90f8;
				@include themeColorOpacity(#3b90f8, 0.2);

				.widget_right p {
					color: #3b90f8;
				}

				svg {
					width: 35px;
				}
			}

			&:nth-child(9) {
				border-color: #80c564;
				@include themeColorOpacity(#80c564, 0.2);

				.widget_right p {
					color: #80c564;
				}

				svg {
					width: 50px;
				}
			}

			&:nth-child(10) {
				border-color: #f3b1d5;
				@include themeColorOpacity(#f3b1d5, 0.2);

				.widget_right p {
					color: #f3b1d5;
				}

				svg {
					width: 35px;
				}
			}

			&:nth-child(11) {
				border-color: #88dced;
				@include themeColorOpacity(#88dced, 0.2);

				.widget_right p {
					color: #88dced;
				}

				svg {
					width: 45px;
				}
			}

			&:nth-child(12) {
				border-color: #ffb534;
				@include themeColorOpacity(#ffb534, 0.2);

				.widget_right p {
					color: #ffb534;
				}

				svg {
					width: 45px;
				}
			}

			&:nth-child(13) {
				border-color: #3b90f8;
				@include themeColorOpacity(#3b90f8, 0.2);

				.widget_right p {
					color: #3b90f8;
				}

				svg {
					width: 40px;
				}
			}

			&:nth-child(14) {
				border-color: #80c564;
				@include themeColorOpacity(#80c564, 0.2);

				.widget_right p {
					color: #80c564;
				}

				svg {
					width: 40px;
				}
			}
		}

		@include desktop {
			.widget_row {
				.widget_left p {
					font-size: 14px;
				}

				.widget_right p {
					font-size: 22px;
				}
			}
		}
	}

	.incoming_Load_widget {
		padding: 5px 15px;
		cursor: pointer;
		.widget_row {
			cursor: pointer;
			display: block;
			padding: 8px 5px;
			margin-top: 0;


			.incomimgLoad {
				display: flex;
				align-items: center;
				// justify-content: space-between;

				.left-icones {
					border-radius: 50%;
					height: 40px;
					min-width: 40px;
					display: flex;
					align-items: center;
					justify-content: center;
					fill: $black;
					border-radius: 5px;
					border: 2px solid;
				}

				.content-information {
					padding: 8.2px;
					margin: 0 3px;
					min-width: 178px;
					border-radius: 5px;
					border: 2px solid ;

					h3 {
						margin: 0;
						font-size: 12px;
						font-weight: 600;
					}

				}

				.incomingLoadCount {
					border: 2px solid;
					width: -webkit-fill-available;
					padding: 9px;
					border-radius: 5px;

					p {
						margin: 0;
						line-height: normal;
						font-size: 18px;
					}
				}
			}

			&:nth-child(1) {
				padding-top: 0;
				.content-information {
					border-color: #BA90C6;
					background-color: rgba(186, 144, 198, 0.5);
				}

				.left-icones {
					border-color: #BA90C6;
					background-color: rgba(186, 144, 198, 0.5);

					svg {
						width: 27px;
					}
				}

				.incomingLoadCount {
					border-color: #BA90C6;
					background-color: rgba(186, 144, 198, 0.5);

				}
			}

			&:nth-child(2) {
				.content-information {
					background-color: rgba(234, 115, 141, 0.5);
					border-color: #EA738D;
				}

				.left-icones {
					border-color: #EA738D;
					background-color: rgba(234, 115, 141, 0.5);

					svg {
						width: 30px;
					}
				}

				.incomingLoadCount {
					background-color: rgba(234, 115, 141, 0.5);
					border-color: #EA738D;

				}
			}

			&:nth-child(3) {
				.content-information {
					background-color: rgba(121, 167, 211, 0.5);
					border-color: #79A7D3;
				}


				.left-icones {
					background-color: rgba(121, 167, 211, 0.5);
					border-color: #79A7D3;

					svg {
						width: 27px;
					}

				}

				.incomingLoadCount {
					background-color: rgba(121, 167, 211, 0.5);
					border-color: #79A7D3;

				}
			}

			&:nth-child(4) {
				.content-information {
					background-color: rgba(121, 180, 183, 0.5);
					border-color: #79B4B7;
				}


				.left-icones {

					background-color: rgba(121, 180, 183, 0.5);
					border-color: #79B4B7;

					svg {
						width: 35px;
					}
				}

				.incomingLoadCount {
					background-color: rgba(121, 180, 183, 0.5);
					border-color: #79B4B7;

				}
			}

			&:nth-child(5) {
				.content-information {
					background-color: rgba(186, 144, 198, 0.5);
					border-color: #BA90C6;
				}


				.left-icones {
					background-color: rgba(186, 144, 198, 0.5);
					border-color: #BA90C6;

					svg {
						width: 34px;
					}
				}

				.incomingLoadCount {
					background-color: rgba(186, 144, 198, 0.5);
					border-color: #BA90C6;

				}
			}

			&:nth-child(6) {
				.content-information {
					background-color: rgba(234, 115, 141, 0.5);
					border-color: #EA738D;

				}

				.left-icones {
					background-color: rgba(234, 115, 141, 0.5);
					border-color: #EA738D;


					svg {
						width: 29px;
					}
				}

				.incomingLoadCount {
					background-color: rgba(234, 115, 141, 0.5);
					border-color: #EA738D;

				}
			}

			&:nth-child(7) {
				.content-information {
					background-color: rgba(121, 167, 211, 0.5);
					border-color: #79A7D3;

				}


				.left-icones {

					background-color: rgba(121, 167, 211, 0.5);
					border-color: #79A7D3;

					svg {
						width: 33px;
					}
				}

				.incomingLoadCount {
					background-color: rgba(121, 167, 211, 0.5);
					border-color: #79A7D3;

				}
			}

			&:nth-child(8) {
				.content-information {
					border-color: #BA90C6;
					background-color: rgba(186, 144, 198, 0.5);
				}

				.left-icones {
					border-color: #BA90C6;
					background-color: rgba(186, 144, 198, 0.5);

					svg {
						width: 27px;
					}
				}

				.incomingLoadCount {
					border-color: #BA90C6;
					background-color: rgba(186, 144, 198, 0.5);

				}
			}

			&:nth-child(9) {
				.content-information {
					background-color: rgba(234, 115, 141, 0.5);
					border-color: #EA738D;
				}

				.left-icones {
					border-color: #EA738D;
					background-color: rgba(234, 115, 141, 0.5);

					svg {
						width: 30px;
					}
				}

				.incomingLoadCount {
					background-color: rgba(234, 115, 141, 0.5);
					border-color: #EA738D;

				}
			}

			&:nth-child(10) {
				.content-information {
					background-color: rgba(121, 167, 211, 0.5);
					border-color: #79A7D3;
				}


				.left-icones {
					background-color: rgba(121, 167, 211, 0.5);
					border-color: #79A7D3;

					svg {
						width: 27px;
					}

					background-color: #79A7D3;
				}

				.incomingLoadCount {
					background-color: rgba(121, 167, 211, 0.5);
					border-color: #79A7D3;

				}
			}

			&:nth-child(11) {
				.content-information {
					background-color: rgba(121, 180, 183, 0.5);
					border-color: #79B4B7;
				}


				.left-icones {

					background-color: rgba(121, 180, 183, 0.5);
					border-color: #79B4B7;

					svg {
						width: 35px;
					}
				}

				.incomingLoadCount {
					background-color: rgba(121, 180, 183, 0.5);
					border-color: #79B4B7;

				}
			}

			&:nth-child(12) {
				.content-information {
					background-color: rgba(186, 144, 198, 0.5);
					border-color: #BA90C6;
				}


				.left-icones {
					background-color: rgba(186, 144, 198, 0.5);
					border-color: #BA90C6;

					svg {
						width: 34px;
					}
				}

				.incomingLoadCount {
					background-color: rgba(186, 144, 198, 0.5);
					border-color: #BA90C6;

				}
			}

			&:nth-child(13) {
				.content-information {
					background-color: rgba(234, 115, 141, 0.5);
					border-color: #EA738D;

				}

				.left-icones {
					background-color: rgba(234, 115, 141, 0.5);
					border-color: #EA738D;


					svg {
						width: 29px;
					}
				}

				.incomingLoadCount {
					background-color: rgba(234, 115, 141, 0.5);
					border-color: #EA738D;

				}
			}

			&:nth-child(14) {
				.content-information {
					background-color: rgba(121, 167, 211, 0.5);
					border-color: #79A7D3;

				}


				.left-icones {

					background-color: rgba(121, 167, 211, 0.5);
					border-color: #79A7D3;

					svg {
						width: 33px;
					}
				}

				.incomingLoadCount {
					background-color: rgba(121, 167, 211, 0.5);
					border-color: #79A7D3;

				}
			}


		}

		@include desktop {
			.widget_row {
				height: 44px;

				.widget_left {
					p {
						font-size: 14px;
					}
				}

				.widget_right {
					min-width: 65px;

					p {
						font-size: 22px;
					}
				}
			}
		}

		@include ipad {
			.widget_row {
				margin-bottom: 10px;

				.widget_left {
					padding-left: 20px;

					p {
						line-height: normal;
					}
				}
			}
		}
	}
}