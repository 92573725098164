$primary_color: rgba(var(--main-color), 1); // RGBA color
$primary: var(--main-color); // RGB color

// $bannerImg: var(--heroImage);
$primary_color_1: rgba(var(--main-color), 0.1);
$primary_color_2: rgba(var(--main-color), 0.2);
$primary_color_3: rgba(var(--main-color), 0.3);
$primary_color_4: rgba(var(--main-color), 0.4);
$primary_color_5: rgba(var(--main-color), 0.5);
$primary_color_6: rgba(var(--main-color), 0.6);
$primary_color_7: rgba(var(--main-color), 0.7);
$primary_color_8: rgba(var(--main-color), 0.8);
$primary_color_9: rgba(var(--main-color), 0.9);
$primary_color_10: rgba(var(--main-color), 0.08);

//font size 
$twelve:12px;
$fourteen:14px;
$thirteen:13px;
$fifteen:15px;
$sixteen:16px;
$seventeen:17px;
$eighteen:18px;
$ningteen:19px;
$twenty:20px;

// $danger_color: $red;
$danger_color: #ff3131;
$success_color: $green;
$success_dark: darken($success_color, 10%);
$danger_dark: darken($danger_color, 10%);
$scroll_light_color: #e9e9e9;
$scroll_dark_color: #b5b8bb;
$scroll_hover_color: #93999e;
$ag_alter_color: #f8f8f8;
$loader_bg: white;
$white_color: #ffffff;
$input_border: #d0d0d0;
$table_border: #e3e3e3;
$icon_gray_color: #a2a2a2;
$black_color: #141414;
$input_label_color: #444444;
$input_label_disabled_color: #cdcdcd;
$main_bg_color: #f4f4f7;
$edit_icon_color: $primary_color;
$ant_switch_color: #2ec158;
$popup_footer_bg: rgb(243, 244, 255);
$bodycolor:#f0f2f5;
$gutter: 24px;

$sidebar_width: 336px;
// $sidebar_height: calc(100vh - 175px);
// $logo_height: 95px;

// @include desktop {
//     $sidebar_height: calc(100vh - 150px);
// }


$widget_padding_horizontal: 15px;
$widget_padding_vertical: 10px;
$counter_widget_icon_font: 45px;
$counter_widget_name_font: 17px;
$counter_widget_count_font: 14px;
$counter_widget_button_font: 12px;
